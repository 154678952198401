import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useClient(clientId: string) {
  return useQuery({
    queryKey: ["client", clientId],
    queryFn: async () => {
      return apiProvider.services.ClientesService.getClienteClientesIdGet({
        id: +clientId,
      });
    },
    enabled: !!clientId,
  });
}
