import { Button, ButtonProps, Spinner } from ".";

export interface DownloadFileProps extends ButtonProps {
  onPress: (() => void) | undefined;
  isPending: boolean;
}

export function DownloadFile({
  onPress,
  isPending,
  children,
  ...props
}: DownloadFileProps) {
  return (
    <Button {...props} onPress={onPress}>
      {isPending ? <Spinner /> : children}
    </Button>
  );
}
