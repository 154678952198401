import { useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormFileInput,
  FormSelectContactTypeInput,
  FormTextInput,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";
import { freePhonemask, phoneMask } from "@/shared/utils/masks";

import { ContactGeneralData } from "../schemas";

export function ContactGeneralDataForm() {
  const { control } = useFormContext<ContactGeneralData>();

  return (
    <>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Contatos</FieldSetTitle>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <FormTextInput control={control} label="Nome" name="nome" />
          <FormTextInput
            control={control}
            label="Telefone"
            name="telefone"
            mask={(value) =>
              value && value.startsWith("0800")
                ? freePhonemask(value)
                : phoneMask(value)
            }
          />
          <FormTextInput
            className="col-span-2"
            control={control}
            label="Email"
            name="email"
          />
        </div>
      </Card>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Dados gerais</FieldSetTitle>
        <div className="flex gap-5">
          <FormFileInput
            control={control}
            label="Logo da empresa"
            name="logo"
            acceptedFileTypes={["image/png", "image/jpeg"]}
            maxFileSize={20 * megabyte}
          />
          <div className="flex flex-1 flex-col gap-3.5">
            <FormTextInput
              control={control}
              label="Descrição"
              name="descricao"
            />
            <FormSelectContactTypeInput control={control} name="tipo" />
            <FormTextInput
              className="col-span-2"
              control={control}
              label="Site"
              name="site"
            />
          </div>
        </div>
      </Card>
    </>
  );
}
