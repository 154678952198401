import { Outlet, useLocation } from "react-router-dom";

import { DrawerlessLayout, TabPanel, Tabs } from "@/shared/components";
import { useAuthorization } from "@/shared/contexts";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function ProfileLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("profile");
  const { authorized } = useAuthorization();

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}dados-do-usuario/`,
      href: `${baseUrl}dados-do-usuario/`,
      label: "Dados do usuário",
      action: "update.profile",
    },
    {
      id: `${baseUrl}senha/`,
      href: `${baseUrl}senha/`,
      label: "Senha",
      action: "update.profile",
    },
  ]);

  return (
    <DrawerlessLayout title="Meu Perfil">
      <Tabs
        className="rounded-t-none shadow-none"
        aria-label="Atualizações do usuário"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          {authorized ?? true ? <Outlet /> : <p>Não autorizado</p>}
        </TabPanel>
      </Tabs>
    </DrawerlessLayout>
  );
}
