import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useFile(filename?: string | null) {
  const query = useQuery({
    queryKey: ["image", filename],
    queryFn: ({ queryKey }) => {
      const filename = queryKey[1];
      if (!filename) {
        return;
      }

      return apiProvider.services.FilesService.getPresignedDownloadUrlFilesDownloadGet(
        { filename },
      );
    },
    enabled: !!filename,
    staleTime: 3500 * 1000,
  });

  return query;
}
