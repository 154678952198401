import { useContext } from "react";
import { FormValidationContext } from "react-aria-components";

import { cn } from "@/shared/utils";

interface FormRootErrorProps {
  className?: string;
}

export function FormRootError({ className }: FormRootErrorProps) {
  const errors = useContext(FormValidationContext);
  const error = errors?.[""];

  return (
    <small className={cn("text-xs font-semibold text-error", className)}>
      {error}
    </small>
  );
}
