import {
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useSubsystem } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { SubsystemEditForm } from "../components";

export function SubsystemEdit() {
  const { subsystemId } = useRequiredParams();

  const subsystemQuery = useSubsystem(subsystemId);

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar subsistema</PageTitle>
      </div>

      <QueryHandler
        {...subsystemQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Subsistema não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <SubsystemEditForm
            redirectTo="../"
            useFormProps={{
              defaultValues: data,
            }}
          />
        )}
      </QueryHandler>
    </div>
  );
}
