import { phone } from "b2utils";

import { allowUndefined } from "./allow-undefined";

export const phoneMask = allowUndefined(phone.mask);

export const freePhonemask = allowUndefined((value: string) => {
  let valor = value.replace(/\D/g, "");
  valor = valor.substring(0, 11); // Limita a entrada a 11 caracteres numéricos

  if (valor.length <= 4) {
    valor = valor.replace(/(\d{0,4})/, "$1");
  } else if (valor.length <= 7) {
    valor = valor.replace(/(\d{0,4})(\d{0,3})/, "$1-$2");
  } else {
    valor = valor.replace(/(\d{0,4})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
  }
  return valor;
});
