import { useCallback } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

import { NumberInput, NumberInputProps } from "..";

export interface FormNumberInputProps<T extends FieldValues>
  extends NumberInputProps {
  label?: string;
  control: Control<T>;
  name: Path<T>;
  value?: PathValue<T, Path<T>>;
}

export function FormNumberInput<T extends FieldValues>({
  control,
  name,
  onChange,
  isDisabled,
  ...props
}: FormNumberInputProps<T>) {
  const {
    field: {
      ref,
      value: controlValue,
      onChange: controlOnChange,
      disabled,
      ...fieldProps
    },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: isDisabled,
  });

  const handleChange = useCallback(
    (v: number) => {
      if (isNaN(v)) {
        v = 0;
      }
      controlOnChange(v);
      onChange?.(v);
    },
    [controlOnChange, onChange],
  );

  return (
    <NumberInput
      {...props}
      {...fieldProps}
      onChange={handleChange}
      value={controlValue || 0}
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
      errorMessage={error?.message}
      inputRef={ref}
    />
  );
}
