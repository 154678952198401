import { InfoCircle } from "iconsax-react";

import { Manual } from "@/shared/services";

import { ManualCard } from "./manual-card";

interface DevelopmentManualsProps {
  manuals: Manual[];
  developmentId?: string;
  from: "list" | "details";
}
export function DevelopmentManuals({ manuals, from }: DevelopmentManualsProps) {
  if (manuals.length === 0) {
    return (
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center gap-5">
          <InfoCircle className="text-primary" size="5rem" />
          <div className="flex flex-col items-center">
            <p className="font-bold text-gray-10">
              Ainda não há manuais cadastrados
            </p>
            <p className="text-sm text-gray-6">
              Lorem ipsum dolor sit amet, consectetur
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fill,16rem)] gap-4">
      {manuals.map((manual) => (
        <ManualCard key={manual.id} manual={manual} from={from} />
      ))}
    </div>
  );
}
