import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "..";
import { cn } from "../../utils";

interface FiltersAccordionProps extends React.PropsWithChildren {
  className?: string;
}

export function FiltersAccordion({
  className,
  children,
}: FiltersAccordionProps) {
  return (
    <Accordion
      className={cn("rounded-lg bg-white-0 px-4 shadow-header", className)}
      type="single"
      collapsible
    >
      <AccordionItem value="item-1">
        <AccordionTrigger>Filtros</AccordionTrigger>
        <AccordionContent className="grid grid-cols-2 gap-2">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
