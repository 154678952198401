import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { BlobWriter, ZipWriter } from "@zip.js/zip.js";
import axios from "axios";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";

export interface UseMutationFilesZipOptions
  extends Omit<UseMutationOptions<Blob, Error, string[]>, "mutationFn"> {
  zipName?: string;
}

export function useMutationFilesZip(options?: UseMutationFilesZipOptions) {
  return useMutation({
    mutationFn: async (files: string[]) => {
      const results = await Promise.all(
        files.map(async (file) => {
          const response =
            await apiProvider.services.FilesService.getPresignedDownloadUrlFilesDownloadGet(
              { filename: file },
            );

          return {
            file,
            blob: (await axios.get(response.url, { responseType: "blob" }))
              .data as Blob,
          };
        }),
      );

      const zipWriter = new ZipWriter(new BlobWriter("application/zip"));
      await Promise.all(
        results.map(({ file, blob }) => zipWriter.add(file, blob.stream())),
      );
      const zip = await zipWriter.close();

      return zip;
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao baixar arquivos do servidor: ${e.message}`,
      }),
    ...options,
  });
}
