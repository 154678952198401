import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface AppearanceErrorProps<TError extends Error = Error> {
  error?: TError | null;
}

export function AppearanceError({ error }: AppearanceErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Empreendimento não encontrado..."
          : undefined
      }
    />
  );
}
