import { allowUndefined } from "./allow-undefined";

export const dateTimeMask = allowUndefined((value: string): string => {
  const parsedDate = new Date(value);
  const hour = parsedDate.toLocaleTimeString("pt-br", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const date = parsedDate.toLocaleDateString("pt-br");
  return `${date} às ${hour}`;
});
