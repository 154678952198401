import { Handle, RouteObject } from "react-router-dom";

import { developmentRouter } from "../companies/modules/developments/router";
import { ListDevelopments } from "./modules/list/containers";

export const workerDevelopmentRouter: RouteObject = {
  path: "empreendimentos",
  handle: {
    crumb: "Lista de empreendimentos",
    action: "list.development",
  } as Handle,
  children: [
    {
      index: true,
      element: <ListDevelopments />,
    },
    ...(developmentRouter.children ?? []),
  ],
};
