import { cn } from "@/shared/utils";

export function DialogButtons({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "mx-6 flex justify-end gap-4 border-t border-white-3 py-6",
        className,
      )}
      data-cy="modal-buttons"
      {...props}
    />
  );
}
