import { parseDate } from "@internationalized/date";
import { useState } from "react";
import { DateValue } from "react-aria-components";

import { DatePickerInput, DatePickerInputProps } from "..";

export interface CreatedAtFilterProps<T extends DateValue = DateValue>
  extends Omit<
    DatePickerInputProps<T>,
    "label" | "onChange" | "onReset" | "value"
  > {
  onDateChange?: (value?: string) => void;
}

export function CreatedAtFilter({
  onDateChange,
  ...props
}: CreatedAtFilterProps) {
  const [date, setDate] = useState<string>();

  return (
    <DatePickerInput
      label="Data de cadastro"
      onChange={(d) => {
        onDateChange?.(d);
        setDate(d);
      }}
      onReset={() => {
        onDateChange?.();
        setDate(undefined);
      }}
      value={date ? parseDate(date) : null}
      {...props}
    />
  );
}
