import { tv } from "tailwind-variants";

export const focusRing = tv({
  base: "outline outline-0 outline-primary-light-2 group-rac-focus:outline-[3px] forced-colors:outline-[Highlight]",
  variants: {
    isFocusVisible: {
      false: "outline-0",
      true: "outline-[3px]",
    },
  },
});
