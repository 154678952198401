import { usePrevious } from "@uidotdev/usehooks";

import {
  CollectionEmpty,
  CollectionEmptyProps,
  PaginationButtons,
  PaginationButtonsProps,
} from ".";

export interface PaginatedListProps<T>
  extends React.HTMLAttributes<HTMLDivElement>,
    PaginationButtonsProps {
  data?: T[];
  renderElement: (d: T, index: number) => React.ReactElement;
  renderLoading: (previousElementCount: number) => React.ReactElement;
  emptyProps: CollectionEmptyProps;
  isLoading?: boolean;
}

export function PaginatedList<T>({
  data,
  renderElement,
  renderLoading,
  emptyProps,
  isLoading,
  currentPage,
  pageCount,
  onPageChange,
  ...props
}: PaginatedListProps<T>) {
  const previousElementCount = usePrevious(data?.length);

  return (
    <>
      <div {...props}>
        {isLoading ? (
          renderLoading(previousElementCount || 1)
        ) : data && data.length > 0 ? (
          data.map(renderElement)
        ) : (
          <CollectionEmpty {...emptyProps} />
        )}
      </div>
      <PaginationButtons
        className="mt-4 rounded-b-lg bg-white-0 shadow-header"
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </>
  );
}
