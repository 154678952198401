import { forwardRef, useState } from "react";
import { Input, InputProps, Label, TextField } from "react-aria-components";
import { TextFieldProps } from "react-aria-components";

import { cn } from "@/shared/utils";

import { FieldError, SecretToggle } from "..";
import {
  InputStyleProps,
  container,
  input,
  label as labelStyle,
} from "./style/input";

export interface TextInputProps extends TextFieldProps, InputStyleProps {
  label?: string;
  errorMessage?: string;
  mask?: (value?: string) => string | undefined;
  isSecret?: boolean;
  inputProps?: InputProps;
}

function TextInput(
  {
    label,
    className,
    onChange,
    mask,
    errorMessage,
    isSecret,
    type,
    inputProps,
    ...props
  }: TextInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const [showSecret, setShowSecret] = useState(!isSecret);

  return (
    <TextField
      {...props}
      type={isSecret && !showSecret ? "password" : type}
      onChange={(v) => {
        onChange?.(mask ? (mask(v) as string) : v);
      }}
      className={cn(container(props), className)}
    >
      <Label className={labelStyle(props)}>{label}</Label>
      <div className="relative">
        <Input
          className={cn(input(props), "h-full w-full")}
          ref={ref}
          placeholder="Insira aqui"
          {...inputProps}
        />
        {isSecret && (
          <SecretToggle
            className="absolute right-3 top-1/2 -translate-y-1/2"
            showSecret={showSecret}
            onToggle={setShowSecret}
          />
        )}
      </div>
      <FieldError>{errorMessage}</FieldError>
    </TextField>
  );
}

const ForwardedRefTextInput = forwardRef(TextInput);

export { ForwardedRefTextInput as TextInput };
