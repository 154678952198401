import { Breadcrumbs, PageTitle } from "@/shared/components";

import { FaqCreateForm } from "../components";

export function FaqCreate() {
  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar FAQ</PageTitle>
      </div>
      <FaqCreateForm redirectTo="../" />
    </div>
  );
}
