import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function usePost(postId: string) {
  return useQuery({
    queryKey: ["post", postId],
    queryFn: () =>
      apiProvider.services.PostsService.getOneObjPostIdGet({ id: +postId }),
    enabled: !!postId,
  });
}
