import { z } from "@/shared/utils";

export const PostCreateSchema = z.object({
  titulo: z.string().trim().min(1, "Obrigatório"),
  categoria: z.string().trim().min(1, "Obrigatório"),
  nbrId: z.number().int().nullish(),
  capa: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
  texto: z.string().trim().min(1, "Obrigatório"),
  manualId: z.number().int().nullish(),
});

export type PostCreate = z.infer<typeof PostCreateSchema>;
