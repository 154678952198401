import { CloseCircle } from "iconsax-react";
import { Button as AriaButton, Heading } from "react-aria-components";

export interface DialogHeaderProps extends React.PropsWithChildren {
  onClose?: () => void;
}

export function DialogHeader({ onClose, children }: DialogHeaderProps) {
  return (
    <div className="flex justify-between border-b border-white-3 p-6 pb-5 outline-none">
      <Heading
        className="flex items-center text-sm font-bold text-primary"
        slot="title"
      >
        {children}
      </Heading>
      <AriaButton onPress={onClose} data-cy="close-modal">
        <CloseCircle className="text-gray-3" />
      </AriaButton>
    </div>
  );
}
