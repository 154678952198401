import { useToastQueue } from "@react-stately/toast";
import { createPortal } from "react-dom";

import { ariaToastQueue } from "./toast-queue";
import { ToastRegion, ToastRegionProps } from "./toast-region";

interface GlobalToastRegionProps extends Omit<ToastRegionProps, "state"> {}

export function GlobalToastRegion(props: GlobalToastRegionProps) {
  const state = useToastQueue(ariaToastQueue);

  return state.visibleToasts.length > 0
    ? createPortal(<ToastRegion {...props} state={state} />, document.body)
    : null;
}
