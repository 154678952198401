import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface MaintenanceDetailErrorProps {
  error: Error;
}

export function MaintenanceDetailError({ error }: MaintenanceDetailErrorProps) {
  return (
    <div className="space-y-4">
      <ErrorContainer
        message={
          error instanceof ApiError && error.status === 404
            ? "Preventivo não encontrado..."
            : undefined
        }
      />
    </div>
  );
}
