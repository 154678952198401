import { Handle, RouteObject } from "react-router-dom";

import { createClientAction } from "./actions";
import { CreateClient, ListClients } from "./containers";
import { ClientDetail } from "./modules/detail/containers";
import { clientEditAction } from "./modules/edit/actions";
import { ClientEdit } from "./modules/edit/containers";

export const clientsRouter: RouteObject = {
  path: "cliente",
  handle: {
    crumb: "Lista de clientes",
    action: "list.client",
  } as Handle,
  children: [
    {
      index: true,
      element: <ListClients />,
    },
    {
      path: "detalhes/:clientId",
      handle: {
        crumb: "Detalhes do cliente",
        action: "get.client",
      } as Handle,
      children: [
        {
          index: true,
          element: <ClientDetail />,
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar cliente",
            action: "update.client",
          } as Handle,
          action: clientEditAction,
          element: <ClientEdit />,
        },
      ],
    },
    {
      path: "cadastrar",
      handle: {
        crumb: "Cadastrar cliente",
        action: "create.client",
      } as Handle,
      action: createClientAction,
      element: <CreateClient />,
    },
  ],
};
