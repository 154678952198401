import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function ProductDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("product");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do produto",
      action: "get.product",
    },
    {
      id: `${baseUrl}garantia/`,
      href: `${baseUrl}garantia/`,
      label: "Garantias",
      action: "list.warranty",
    },
    {
      id: `${baseUrl}preventivo/`,
      href: `${baseUrl}preventivo/`,
      label: "Preventivos",
      action: "list.maintenance",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do Produto</PageTitle>
      </div>
      <Tabs aria-label="Modulos do produto" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
