import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsManualGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseManualsFilters = GetObjsManualGetData;

export function useManuals(initial: UseManualsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseManualsFilters>(initial);
  const query = useQuery({
    queryKey: ["manuals", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ManuaisService.getObjsManualGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
