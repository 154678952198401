import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { CompanyCreate } from "../schemas";

export async function createCompanyAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<CompanyCreate>(
    request,
    async ({ responsavel, ...construtora }) => {
      await apiProvider.services.AcopladosService.postConstrutoraWithOwnerAcopladosConstrutoraResponsavelPost(
        {
          requestBody: {
            construtora: {
              ...construtora,
              foto: construtora.foto?.[0].key,
            },
            responsavel,
          },
        },
      );

      toastQueue.add(
        { type: "success", message: "Construtora criada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect("/app/construtoras/");
    },
  );
}
