import {
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
  OverlayArrow,
  composeRenderProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

export interface PopoverProps extends Omit<AriaPopoverProps, "children"> {
  showArrow?: boolean;
  children: React.ReactNode;
}

const styles = tv({
  base: "rounded-md border border-black-0/10 bg-white-0 bg-clip-padding shadow-2xl forced-colors:bg-[Canvas]",
  variants: {
    isEntering: {
      true: "duration-200 ease-out animate-in fade-in rac-placement-left:slide-in-from-right-1 rac-placement-right:slide-in-from-left-1 rac-placement-top:slide-in-from-bottom-1 rac-placement-bottom:slide-in-from-top-1",
    },
    isExiting: {
      true: "duration-150 ease-in animate-out fade-out rac-placement-left:slide-out-to-right-1 rac-placement-right:slide-out-to-left-1 rac-placement-top:slide-out-to-bottom-1 rac-placement-bottom:slide-out-to-top-1",
    },
  },
});

export function Popover({
  children,
  showArrow,
  className,
  ...props
}: PopoverProps) {
  return (
    <AriaPopover
      offset={showArrow ? 12 : 8}
      {...props}
      className={composeRenderProps(className, (className, renderProps) =>
        styles({ ...renderProps, className }),
      )}
    >
      {showArrow && (
        <OverlayArrow className="group">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="block fill-white-0 stroke-black-0/10 stroke-1 group-rac-placement-left:-rotate-90 group-rac-placement-right:rotate-90 group-rac-placement-bottom:rotate-180 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </AriaPopover>
  );
}
