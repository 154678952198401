import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useWarranty(warrantyId: string) {
  return useQuery({
    queryKey: ["warranty", warrantyId],
    queryFn: () =>
      apiProvider.services.GarantiasService.getOneObjGarantiaIdGet({
        id: +warrantyId,
      }),
    enabled: !!warrantyId,
  });
}
