import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useCompanyCategories() {
  return useQuery({
    queryKey: ["companyCategory"],
    queryFn:
      apiProvider.services.CategoriasConstrutoraService
        .getObjsCategoriasConstrutoraGet,
  });
}
