import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function SubsystemLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("subsystem");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do subsistema",
      action: "get.subsystem",
    },
    {
      id: `${baseUrl}produto/`,
      href: `${baseUrl}produto/`,
      label: "Produtos",
      action: "list.product",
    },
    {
      id: `${baseUrl}garantia/`,
      href: `${baseUrl}garantia/`,
      label: "Garantias",
      action: "list.warranty",
    },
    {
      id: `${baseUrl}preventivo/`,
      href: `${baseUrl}preventivo/`,
      label: "Preventivos",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}faq/`,
      href: `${baseUrl}faq/`,
      label: "Faqs",
      action: "list.faq",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do Subsistema</PageTitle>
      </div>
      <Tabs
        aria-label="Modulos do subsistema"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
