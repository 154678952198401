import { useQuery } from "@tanstack/react-query";
import { Key } from "react-aria-components";
import { FieldValues } from "react-hook-form";

import {
  FormSelectInput,
  FormSelectInputProps,
  InputItem,
} from "@/shared/components";

export interface FormSelectApiResourceInputProps<
  T extends object,
  TFieldValues extends FieldValues,
  TContent,
> extends FormSelectInputProps<T, TFieldValues> {
  queryKey: string[];
  service: () => Promise<TContent[]>;
  mapElements: (element: TContent) => { id: Key; text: string };
}
export function FormSelectApiResourceInput<
  T extends object,
  TFieldValues extends FieldValues,
  TContent,
>({
  isDisabled,
  queryKey,
  service,
  mapElements,
  ...props
}: FormSelectApiResourceInputProps<T, TFieldValues, TContent>) {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKey,
    queryFn: service,
  });

  return (
    <FormSelectInput {...props} isDisabled={isDisabled || isLoading || isError}>
      {data?.map(mapElements).map((element) => (
        <InputItem key={element.id} id={element.id}>
          {element.text}
        </InputItem>
      ))}
    </FormSelectInput>
  );
}
