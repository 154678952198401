import { Handle, RouteObject } from "react-router-dom";

import { faqCreateAction, faqEditAction } from "./actions";
import { FaqCreate, FaqDetail, FaqEdit, FaqList } from "./containers";

export const faqRouter: RouteObject = {
  path: "faq",
  handle: {
    crumb: "Lista de FAQs",
    action: "list.faq",
  } as Handle,
  children: [
    {
      index: true,
      element: <FaqList />,
    },
    {
      path: "detalhes/:faqId",
      handle: {
        crumb: "Detalhes do FAQ",
        action: "get.faq",
      } as Handle,
      children: [
        {
          index: true,
          element: <FaqDetail />,
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar FAQ",
            action: "update.faq",
          } as Handle,
          action: faqEditAction,
          element: <FaqEdit />,
        },
      ],
    },
    {
      path: "cadastrar",
      handle: {
        crumb: "Cadastrar FAQ",
        action: "create.faq",
      } as Handle,
      action: faqCreateAction,
      element: <FaqCreate />,
    },
  ],
};
