import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Manual, ManualCreate, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationCreateManualConfig
  extends Omit<UseMutationOptions<Manual, Error, ManualCreate>, "mutationFn"> {
  companyId?: number;
}

export function useMutationManualCreate(
  options?: UseMutationCreateManualConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ManualCreate) =>
      apiProvider.services.ManuaisService.createObjManualPost({
        requestBody,
      }),
    onSettled: async (_, __, { empreendimentoId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["development", empreendimentoId.toString()],
      });

      if (options?.companyId) {
        await queryClient.invalidateQueries({
          queryKey: ["developments", { construtoraId: options.companyId }],
        });
      }
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar manual: ${e.message}`,
      }),
    ...options,
  });
}
