import { zodResolver } from "@hookform/resolvers/zod";
import { FormProps as AriaFormProps } from "react-aria-components";
import { UseFormProps, UseFormReturn, useForm } from "react-hook-form";
import { useSubmit } from "react-router-dom";
import { ZodTypeAny, z } from "zod";

import { formDataToObject } from "@/shared/utils";

import { FormCore } from "./form-core";

export interface FormProps<TSchema extends ZodTypeAny>
  extends Omit<AriaFormProps, "children"> {
  schema: TSchema;
  children:
    | ((
        form: Omit<
          UseFormReturn<z.TypeOf<TSchema>, undefined, undefined>,
          "handleSubmit"
        >,
      ) => React.ReactNode | undefined)
    | React.ReactNode;
  useFormProps?: UseFormProps<z.infer<TSchema>>;
}

export function Form<TSchema extends ZodTypeAny>({
  onSubmit: _,
  schema,
  useFormProps,
  ...props
}: FormProps<TSchema>) {
  const submit = useSubmit();
  const { handleSubmit, ...form } = useForm({
    resolver: zodResolver(schema),
    ...useFormProps,
  });

  const formHandleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.currentTarget;
    await handleSubmit((values) => {
      const formData = new FormData(target);
      const formValues = formDataToObject(formData);
      submit(
        { ...formValues, ...values },
        {
          method: "post",
          encType: "application/json",
          action: location.pathname,
        },
      );
    })(e);
  };

  return (
    <FormCore
      {...props}
      form={{ ...form, handleSubmit }}
      onSubmit={formHandleSubmit}
    >
      {props.children instanceof Function
        ? props.children(form)
        : props.children}
    </FormCore>
  );
}
