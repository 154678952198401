import { useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormComboBoxDesignerInput,
  FormComboBoxSupplierInput,
  FormFileInput,
  FormTextInput,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";

import { ProductGeneralData } from "../schemas";

export function ProductGeneralDataForm() {
  const { control } = useFormContext<ProductGeneralData>();

  return (
    <Card className="mb-4 p-6">
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormTextInput
          control={control}
          label="Marca ou Fabricante"
          name="fabricante"
        />
        <FormTextInput control={control} label="Nome" name="nome" />
        <FormTextInput
          control={control}
          className="col-span-2"
          label="Modelo"
          name="modelo"
        />
        <FormTextInput
          control={control}
          className="col-span-2"
          label="Link externo para manual"
          name="linkManual"
        />
        <FormTextInput
          control={control}
          className="col-span-2"
          label="Descrição"
          name="descricao"
        />
        <FormComboBoxSupplierInput name="fornecedorFk" control={control} />
        <FormComboBoxDesignerInput name="projetistaFk" control={control} />
        <FormFileInput
          control={control}
          name="notaFiscal"
          label="Nota fiscal"
          acceptedFileTypes={["application/pdf"]}
          maxFileSize={50 * megabyte}
        />
      </div>
    </Card>
  );
}
