import { ActionFunctionArgs } from "react-router-dom";

import { type RedefinirSenhaInfo, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function recoverPasswordAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<RedefinirSenhaInfo>(request, async (payload) => {
    await apiProvider.services.RedefinirSenhaService.mudarSenhaRedefinirSenhaPut(
      {
        requestBody: payload,
      },
    );

    return null;
  });
}
