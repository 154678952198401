import { useState } from "react";

export function useSwitch(initialState: boolean | (() => boolean)) {
  const [switchState, setSwitchState] = useState(initialState);

  return {
    switchState,
    isOn: switchState,
    isOff: !switchState,
    turnOn: () => setSwitchState(true),
    turnOff: () => setSwitchState(false),
    setOpen: setSwitchState,
  };
}
