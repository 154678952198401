import { Handle, RouteObject } from "react-router-dom";

import {
  maintenanceCreateAction,
  maintenanceEditAction,
} from "@/shared/modules/maintenance/actions";
import {
  MaintenanceCreate,
  MaintenanceDetail,
  MaintenanceEdit,
  MaintenanceList,
} from "@/shared/modules/maintenance/containers";
import { productCreateAction } from "@/shared/modules/product/actions";
import {
  ProductCreate,
  ProductList,
} from "@/shared/modules/product/containers";
import { ProductDetail } from "@/shared/modules/product/modules/detail/containers";
import { ProductDetailLayout } from "@/shared/modules/product/modules/detail/layout";
import { productEditAction } from "@/shared/modules/product/modules/edit/actions";
import { ProductEdit } from "@/shared/modules/product/modules/edit/containers";
import {
  failureCreateAction,
  failureEditAction,
  warrantyCreateAction,
  warrantyEditAction,
} from "@/shared/modules/warranty/actions";
import {
  FailureCreate,
  FailureDetail,
  FailureEdit,
  FailureList,
  WarrantyCreate,
  WarrantyDetail,
  WarrantyEdit,
  WarrantyList,
} from "@/shared/modules/warranty/containers";
import { WarrantyDetailLayout } from "@/shared/modules/warranty/modules/detail/layout";

import { subsystemEditAction } from "../edit/actions";
import { SubsystemEdit } from "../edit/containers";
import { subsystemFaqCreateAction, subsystemFaqEditAction } from "./actions";
import {
  FaqList,
  SubsystemData,
  SubsystemFaqCreate,
  SubsystemFaqDetail,
  SubsystemFaqEdit,
} from "./containers";
import { SubsystemLayout } from "./layout";

export const subsystemDetailRouter: RouteObject = {
  path: "detalhes/:subsystemId",
  handle: {
    crumb: "Detalhes do subsistema",
    tabBaseUrl: "subsystem",
    action: "get.subsystem",
  } as Handle,
  children: [
    {
      element: <SubsystemLayout />,
      children: [
        {
          index: true,
          element: <SubsystemData />,
        },
        {
          path: "produto",
          handle: {
            crumb: "Lista de produtos",
            action: "list.product",
          } as Handle,
          element: <ProductList />,
        },
        {
          path: "garantia",
          handle: {
            crumb: "Lista de garantias",
            action: "list.warranty",
          } as Handle,
          element: <WarrantyList />,
        },
        {
          path: "preventivo",
          handle: {
            crumb: "Lista de preventivos",
            action: "list.maintenance",
          } as Handle,
          element: <MaintenanceList />,
        },
        {
          path: "faq",
          handle: {
            crumb: "Lista de FAQs",
            action: "list.faq",
          } as Handle,
          element: <FaqList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar subsistema",
        action: "update.subsystem",
      } as Handle,
      action: subsystemEditAction,
      element: <SubsystemEdit />,
    },
    {
      path: "produto",
      handle: {
        crumb: "Lista de produtos",
        action: "list.product",
      } as Handle,
      children: [
        {
          path: "detalhes/:productId",
          handle: {
            crumb: "Detalhes do produto",
            tabBaseUrl: "product",
            action: "get.product",
          } as Handle,
          children: [
            {
              element: <ProductDetailLayout />,
              children: [
                {
                  index: true,
                  element: <ProductDetail />,
                },
                {
                  path: "garantia",
                  handle: {
                    crumb: "Lista de garantias",
                    action: "list.warranty",
                  } as Handle,
                  element: <WarrantyList />,
                },
                {
                  path: "preventivo",
                  handle: {
                    crumb: "Lista de preventivos",
                    action: "list.maintenance",
                  } as Handle,
                  element: <MaintenanceList />,
                },
              ],
            },

            {
              path: "garantia",
              handle: {
                crumb: "Lista de garantias",
                action: "list.warranty",
              } as Handle,
              children: [
                {
                  path: "detalhes/:warrantyId",
                  handle: {
                    crumb: "Detalhes da garantia",
                    tabBaseUrl: "warranty",
                    action: "get.warranty",
                  } as Handle,
                  children: [
                    {
                      element: <WarrantyDetailLayout />,
                      children: [
                        {
                          index: true,
                          element: <WarrantyDetail />,
                        },
                        {
                          path: "falhas",
                          handle: {
                            crumb: "Lista de falhas",
                            action: "list.failure",
                          } as Handle,
                          element: <FailureList />,
                        },
                      ],
                    },
                    {
                      path: "falhas",
                      handle: {
                        crumb: "Lista de falhas",
                        action: "list.failure",
                      } as Handle,
                      children: [
                        {
                          path: "detalhes/:failureId",
                          handle: {
                            crumb: "Detalhes da falha",
                            action: "get.failure",
                          } as Handle,
                          children: [
                            {
                              index: true,
                              element: <FailureDetail />,
                            },
                            {
                              path: "editar",
                              handle: {
                                crumb: "Editar falha",
                                action: "update.failure",
                              } as Handle,
                              action: failureEditAction,
                              element: <FailureEdit />,
                            },
                          ],
                        },
                        {
                          path: "cadastrar",
                          handle: {
                            crumb: "Cadastrar falha",
                            action: "create.failure",
                          } as Handle,
                          action: failureCreateAction,
                          element: <FailureCreate />,
                        },
                      ],
                    },
                    {
                      path: "editar",
                      handle: {
                        crumb: "Editar garantia",
                        action: "update.warranty",
                      } as Handle,
                      action: warrantyEditAction,
                      element: <WarrantyEdit />,
                    },
                  ],
                },
                {
                  path: "cadastrar",
                  handle: {
                    crumb: "Cadastrar garantia",
                    action: "create.warranty",
                  } as Handle,
                  action: warrantyCreateAction,
                  element: <WarrantyCreate />,
                },
              ],
            },
            {
              path: "preventivo",
              handle: {
                crumb: "Lista de preventivos",
                action: "list.maintenance",
              } as Handle,
              children: [
                {
                  path: "detalhes/:maintenanceId",
                  handle: {
                    crumb: "Detalhes do preventivo",
                    action: "get.maintenance",
                  } as Handle,
                  children: [
                    {
                      index: true,
                      element: <MaintenanceDetail />,
                    },
                    {
                      path: "editar",
                      handle: {
                        crumb: "Editar preventivo",
                        action: "update.maintenance",
                      } as Handle,
                      action: maintenanceEditAction,
                      element: <MaintenanceEdit />,
                    },
                  ],
                },
                {
                  path: "cadastrar",
                  handle: {
                    crumb: "Cadastrar preventivo",
                    action: "create.maintenance",
                  } as Handle,
                  action: maintenanceCreateAction,
                  element: <MaintenanceCreate />,
                },
              ],
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar produto",
                action: "update.product",
              } as Handle,
              action: productEditAction,
              element: <ProductEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar produto",
            action: "create.product",
          } as Handle,
          action: productCreateAction,
          element: <ProductCreate />,
        },
      ],
    },
    {
      path: "garantia",
      handle: {
        crumb: "Lista de garantias",
        action: "list.warranty",
      } as Handle,
      children: [
        {
          path: "detalhes/:warrantyId",
          handle: {
            crumb: "Detalhes da garantia",
            tabBaseUrl: "warranty",
            action: "get.warranty",
          } as Handle,
          children: [
            {
              element: <WarrantyDetailLayout />,
              children: [
                {
                  index: true,
                  element: <WarrantyDetail />,
                },
                {
                  path: "falhas",
                  handle: {
                    crumb: "Lista de falhas",
                    action: "list.failure",
                  } as Handle,
                  element: <FailureList />,
                },
              ],
            },
            {
              path: "falhas",
              handle: {
                crumb: "Lista de falhas",
                action: "list.failure",
              } as Handle,
              children: [
                {
                  path: "detalhes/:failureId",
                  handle: {
                    crumb: "Detalhes da falha",
                    action: "get.failure",
                  } as Handle,
                  children: [
                    {
                      index: true,
                      element: <FailureDetail />,
                    },
                    {
                      path: "editar",
                      handle: {
                        crumb: "Editar falha",
                        action: "update.failure",
                      } as Handle,
                      action: failureEditAction,
                      element: <FailureEdit />,
                    },
                  ],
                },
                {
                  path: "cadastrar",
                  handle: {
                    crumb: "Cadastrar falha",
                    action: "create.failure",
                  } as Handle,
                  action: failureCreateAction,
                  element: <FailureCreate />,
                },
              ],
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar garantia",
                action: "update.warranty",
              } as Handle,
              action: warrantyEditAction,
              element: <WarrantyEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar garantia",
            action: "create.warranty",
          } as Handle,
          action: warrantyCreateAction,
          element: <WarrantyCreate />,
        },
      ],
    },
    {
      path: "preventivo",
      handle: {
        crumb: "Lista de preventivos",
        action: "list.maintenance",
      } as Handle,
      children: [
        {
          path: "detalhes/:maintenanceId",
          handle: {
            crumb: "Detalhes do preventivo",
            action: "get.maintenance",
          } as Handle,
          children: [
            {
              index: true,
              element: <MaintenanceDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar preventivo",
                action: "update.maintenance",
              } as Handle,
              action: maintenanceEditAction,
              element: <MaintenanceEdit />,
            },
          ],
        },
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar preventivo",
            action: "create.maintenance",
          } as Handle,
          action: maintenanceCreateAction,
          element: <MaintenanceCreate />,
        },
      ],
    },
    {
      path: "faq",
      handle: {
        crumb: "Lista de FAQs",
        action: "list.faq",
      } as Handle,
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar FAQ",
            action: "create.faq",
          } as Handle,
          action: subsystemFaqCreateAction,
          element: <SubsystemFaqCreate />,
        },
        {
          path: "detalhes/:faqId",
          handle: {
            crumb: "Detalhes do FAQ",
            action: "get.faq",
          } as Handle,
          children: [
            {
              index: true,
              element: <SubsystemFaqDetail />,
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar FAQ",
                action: "update.faq",
              } as Handle,
              action: subsystemFaqEditAction,
              element: <SubsystemFaqEdit />,
            },
          ],
        },
      ],
    },
  ],
};
