import { useParams } from "react-router-dom";

import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { WarrantyCreateForm } from "../components";

export function WarrantyCreate() {
  const { productId, subsystemId } = useParams();

  const defaultValues = productId
    ? { produtoId: +productId, falhas: [] }
    : { subsistemaId: subsystemId ? +subsystemId : undefined, falhas: [] };

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar garantia</PageTitle>
        <WarrantyCreateForm
          redirectTo="../"
          useFormProps={{
            defaultValues,
          }}
        />
      </div>
    </AnimatedContainer>
  );
}
