import { isMobilePhone, isPostalCode } from "validator";

import { getDigits, z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";
import { is0800, is3Digits } from "@/shared/utils/validators";

export const ContactGeneralDataSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  telefone: z
    .string()
    .trim()
    .min(1, "Obrigatório")
    .refine(
      (v) => is3Digits(v) || isMobilePhone(v, "pt-BR") || is0800(v),
      "Número inválido",
    ),
  email: z.string().email().nullish(),
  logo: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
  descricao: z.string().trim().nullish(),
  tipo: z.number().nullish(),
  site: z
    .string()
    .startsWith("https://", "Precisa começar com https://")
    .url()
    .nullish(),
});

export const ContactAddressSchema = z.object({
  cep: z
    .string()
    .refine((v) => !v || isPostalCode(v, "BR"), "Cep inválido")
    .transform(getDigits)
    .nullish(),
  estadoUf: z.string().trim().nullish(),
  cidade: z.string().trim().nullish(),
  bairro: z.string().trim().nullish(),
  rua: z.string().trim().nullish(),
  numero: z.string().trim().nullish(),
  complemento: z.string().trim().nullish(),
});

export const ContactCreateSchema = z.object({
  ...ContactGeneralDataSchema.shape,
  ...ContactAddressSchema.shape,
});

const ContactGeneralDataStepSchema = generateStepSchema(
  ContactGeneralDataSchema,
  0,
);

const ContactAddressStepSchema = generateStepSchema(ContactAddressSchema, 1);

export const ContactCreateFormSchema = generateMultiStepSchema([
  ContactGeneralDataStepSchema,
  ContactAddressStepSchema,
]);

export type ContactGeneralData = z.infer<typeof ContactGeneralDataSchema>;
export type ContactAddress = z.infer<typeof ContactAddressSchema>;
export type ContactCreate = z.infer<typeof ContactCreateSchema>;

export type ContactCreateForm = z.infer<typeof ContactCreateFormSchema>;
