import { CloseCircle } from "iconsax-react";
import { useContext } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import { Button } from "./button";

export function ClearComboBoxButton() {
  const state = useContext(ComboBoxStateContext);

  if (!state?.selectedKey) {
    return null;
  }

  return (
    <Button
      slot={null}
      onPress={() => state?.setSelectedKey(null)}
      className="bg-transparent text-gray-9"
    >
      <CloseCircle size={20} variant="Bold" />
    </Button>
  );
}
