import { FieldValues } from "react-hook-form";

import { useStateCities } from "@/shared/hooks/api";

import { FormComboBoxInput, FormComboBoxInputProps } from ".";
import { InputItem } from "..";

export interface FormBrCityInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends FormComboBoxInputProps<T, TFieldValues> {
  state?: string;
}

export function FormBrCityInput<
  T extends object,
  TFieldValues extends FieldValues,
>({ state, ...props }: FormBrCityInputProps<T, TFieldValues>) {
  const { data: cities } = useStateCities(state);

  return (
    <FormComboBoxInput {...props} allowsCustomValue>
      {cities?.map((city) => (
        <InputItem key={city.id} id={city.nome}>
          {city.nome}
        </InputItem>
      ))}
    </FormComboBoxInput>
  );
}
