import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface WorkerDetailErrorProps {
  error: Error;
}
export function WorkerDetailError({ error }: WorkerDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Usuário não encontrado..."
          : undefined
      }
    />
  );
}
