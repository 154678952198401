import {
  Tooltip as AriaTooltip,
  TooltipProps as AriaTooltipProps,
  OverlayArrow,
  composeRenderProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

export interface TooltipProps extends Omit<AriaTooltipProps, "children"> {
  children: React.ReactNode;
}

const styles = tv({
  base: "group rounded-lg border border-gray-9 bg-white-0 px-3 py-1 text-xs drop-shadow-lg will-change-transform",
  variants: {
    isEntering: {
      true: "duration-200 ease-out animate-in fade-in rac-placement-left:slide-in-from-right-0.5 rac-placement-right:slide-in-from-left-0.5 rac-placement-top:slide-in-from-bottom-0.5 rac-placement-bottom:slide-in-from-top-0.5",
    },
    isExiting: {
      true: "duration-150 ease-in animate-out fade-out rac-placement-left:slide-out-to-right-0.5 rac-placement-right:slide-out-to-left-0.5 rac-placement-top:slide-out-to-bottom-0.5 rac-placement-bottom:slide-out-to-top-0.5",
    },
  },
});

export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <AriaTooltip
      {...props}
      offset={10}
      className={composeRenderProps(props.className, (className, renderProps) =>
        styles({ ...renderProps, className }),
      )}
    >
      <OverlayArrow>
        <svg
          width={8}
          height={8}
          viewBox="0 0 8 8"
          className="fill-white-0 stroke-gray-9 group-rac-placement-left:-rotate-90 group-rac-placement-right:rotate-90 group-rac-placement-bottom:rotate-180 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]"
        >
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {children}
    </AriaTooltip>
  );
}
