import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { type RedefinirSenhaEmail, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function forgotPasswordAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<RedefinirSenhaEmail>(request, async (payload) => {
    // TODO: Waiting for backend to return message
    // const { message } =
    const message = "Email enviado, cheque seu email!";
    await apiProvider.services.RedefinirSenhaService.enviarEmailRedefinirSenhaPost(
      {
        requestBody: payload,
      },
    );

    toastQueue.add({
      type: "success",
      message,
    });
    return redirect("/login");
  });
}
