import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsBlocosGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseBlocksFilters = GetObjsBlocosGetData;

export function useBlocks(initial: UseBlocksFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseBlocksFilters>(initial);

  const query = useQuery({
    queryKey: ["blocks", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.BlocosService.getObjsBlocosGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
