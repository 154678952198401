import {
  AnimatedContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useProfile } from "@/shared/hooks/api";

import { CompanyDetailData } from "../../companies/modules/detail/components/company-detail-data";

export function CompanyDetail() {
  const profileQuery = useProfile();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <PageTitle primary>Detalhes da construtora</PageTitle>
      </div>
      <QueryHandler {...profileQuery} renderLoading={() => <Spinner />}>
        {(data) =>
          !data?.construtoraId ? (
            <p>Não está ligado a nenhuma construtora</p>
          ) : (
            <CompanyDetailData id={data.construtoraId.toString()} />
          )
        }
      </QueryHandler>
    </AnimatedContainer>
  );
}
