import { ToastOptions, ToastQueue } from "@react-stately/toast";

export interface ToastPayload {
  type: "success" | "error";
  message: string;
}

export const ariaToastQueue = new ToastQueue<ToastPayload>({
  maxVisibleToasts: 5,
});

export const toastQueue = {
  ...ariaToastQueue,
  add(content: ToastPayload, options?: ToastOptions) {
    if (content.type === "error" && options?.timeout === undefined) {
      options = { timeout: 5000, ...options };
    }

    return ariaToastQueue.add(content, options);
  },
};
