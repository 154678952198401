import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormFileInput,
  FormRootError,
  FormTextInput,
  Modal,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useMutationDocumentEdit } from "@/shared/hooks/api";
import { Arquivo } from "@/shared/services";
import { megabyte } from "@/shared/utils";

import { DocumentCreate, DocumentCreateSchema } from "../../create/schemas";

export interface DocumentEditModalProps
  extends Omit<ModalOverlayProps, "children"> {
  file: Arquivo;
}

export function DocumentEditModal({ file, ...props }: DocumentEditModalProps) {
  const { handleSubmit, control, reset, watch, unregister } =
    useForm<DocumentCreate>({
      resolver: zodResolver(DocumentCreateSchema),
    });

  const documentEditMutation = useMutationDocumentEdit({
    manualId: file.manualId ?? undefined,
  });

  const watchDocumentType = watch("documentCreationType");

  // reset values when file change
  useEffect(() => {
    reset(
      file.link
        ? {
            ...file,
            manualId: file.manualId ?? undefined,
            documentCreationType: "file",
            link: [
              {
                fileName: file.link,
                key: file.link,
              },
            ],
          }
        : {
            ...file,
            manualId: file.manualId ?? undefined,
            documentCreationType: "directory",
          },
    );
  }, [file, reset]);

  // delete link field when form is in directory mode
  useEffect(() => {
    if (watchDocumentType === "directory") {
      unregister("link");
    }
  }, [unregister, watchDocumentType]);

  const onSubmit = (values: DocumentCreate, close: () => void) => {
    if (values.documentCreationType === "file") {
      return documentEditMutation.mutateAsync(
        {
          id: file.id,
          requestBody: {
            ...values,
            link: values.link[0].key,
          },
        },
        {
          onSuccess: () => {
            toastQueue.add(
              {
                type: "success",
                message: "Sucesso ao editar documento",
              },
              { timeout: 2000 },
            );
            close();
          },
        },
      );
    }

    documentEditMutation.mutateAsync(
      {
        id: file.id,
        requestBody: values,
      },
      {
        onSuccess: () => {
          toastQueue.add(
            {
              type: "success",
              message: "Sucesso ao editar pasta",
            },
            { timeout: 2000 },
          );
          close();
        },
      },
    );
  };

  return (
    <Modal {...props}>
      <Dialog
        role="alertdialog"
        title="Adicionar novo arquivo/pasta"
        renderButtons={({ close }) => (
          <div className="flex w-full justify-between">
            <FormRootError />
            <div className="flex gap-2">
              <Button className="px-7 py-3" styleType="default" onPress={close}>
                Cancelar
              </Button>
              <Button
                className="px-7 py-3"
                onPress={() =>
                  handleSubmit((values) => onSubmit(values, close))()
                }
                showSpinner={documentEditMutation.isPending}
              >
                Salvar
              </Button>
            </div>
          </div>
        )}
      >
        <form className="flex min-w-96 flex-col gap-4">
          <FormTextInput control={control} label="Título" name="nome" />
          {watchDocumentType === "file" && (
            <FormFileInput
              control={control}
              label="Arquivo"
              name="link"
              maxFileSize={50 * megabyte}
            />
          )}
        </form>
      </Dialog>
    </Modal>
  );
}
