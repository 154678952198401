import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormNumberInput,
  FormProps,
  FormRootError,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import { BlockBulkCreateSchema } from "../schemas";

interface BlockBulkCreateFormProps
  extends Omit<
    FormProps<typeof BlockBulkCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function BlockBulkCreateForm({
  redirectTo,
  ...props
}: BlockBulkCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={BlockBulkCreateSchema}
        validationErrors={formErrors}
        {...props}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-4">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormNumberInput
                className="col-span-2"
                control={control}
                label="Quantidade de blocos"
                name="qtdBlocos"
                minValue={1}
              />
              <FormNumberInput
                control={control}
                label="Quantidade de andares"
                name="qtdAndares"
                minValue={1}
              />
              <FormNumberInput
                control={control}
                label="Quantidade de apartamentos por andar"
                name="qtdAptAndar"
                minValue={1}
              />
              <FormNumberInput
                className="col-span-2"
                control={control}
                label="Quantidade de zeros"
                name="zeros"
                tooltip={
                  "Representa os zeros após o andar e antes da unidade.\nEx: padrão 201 é usando 1 zero e o ap 2001 é usando 2 zeros"
                }
                minValue={1}
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
