import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function SystemLayout() {
  const { pathname } = useLocation();

  const baseUrl = useTabBaseUrl("system");
  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do sistema",
      action: "get.system",
    },
    {
      id: `${baseUrl}subsistema/`,
      href: `${baseUrl}subsistema/`,
      label: "Subsistema",
      action: "list.subsystem",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do Sistema</PageTitle>
      </div>
      <Tabs aria-label="Modulos do sistema" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
