import { FieldValues } from "react-hook-form";

import { apiProvider } from "@/shared/services";

import {
  FormSelectApiResourceInput,
  FormSelectApiResourceInputProps,
} from "..";

export type FormSelectContactTypeInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormSelectApiResourceInputProps<T, TFieldValues, string>,
  "service" | "queryKey" | "label" | "mapElements"
>;

export function FormSelectContactTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormSelectContactTypeInputProps<T, TFieldValues>) {
  return (
    <FormSelectApiResourceInput
      label="Tipo"
      queryKey={["contactTypes"]}
      service={apiProvider.services.ContatosService.getTiposContatosTiposGet}
      mapElements={(c) => ({ id: c.id, text: c.nome })}
      {...props}
    />
  );
}
