import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useTopic(id: string) {
  return useQuery({
    queryKey: ["topic", id],
    queryFn: async () => {
      return apiProvider.services.TopicosService.getOneObjTopicoIdGet({
        id: +id,
      });
    },
    enabled: !!id,
  });
}
