import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { ContactCreateFormWizard } from "../components/contact-create-form-wizard";

export function ContactCreate() {
  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar contato</PageTitle>
        <ContactCreateFormWizard />
      </div>
    </AnimatedContainer>
  );
}
