import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useTopic } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { TopicCreateForm } from "../../create/components";

export function ManualTopicEdit() {
  const { manualId, topicId } = useRequiredParams();
  const topicQuery = useTopic(topicId);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar tópico</PageTitle>
      </div>
      <QueryHandler
        {...topicQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Tópico não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <TopicCreateForm
            redirectTo="../"
            manualId={+manualId}
            useFormProps={{ defaultValues: data }}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
