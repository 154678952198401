import { FieldValues } from "react-hook-form";

import { FormComboBoxInput, FormComboBoxInputProps } from ".";
import { InputItem } from "..";

export function FormBrStateInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormComboBoxInputProps<T, TFieldValues>) {
  return (
    <FormComboBoxInput {...props}>
      <InputItem id="AC">AC</InputItem>
      <InputItem id="AL">AL</InputItem>
      <InputItem id="AM">AM</InputItem>
      <InputItem id="AP">AP</InputItem>
      <InputItem id="BA">BA</InputItem>
      <InputItem id="CE">CE</InputItem>
      <InputItem id="DF">DF</InputItem>
      <InputItem id="ES">ES</InputItem>
      <InputItem id="GO">GO</InputItem>
      <InputItem id="MA">MA</InputItem>
      <InputItem id="MG">MG</InputItem>
      <InputItem id="MS">MS</InputItem>
      <InputItem id="MT">MT</InputItem>
      <InputItem id="PA">PA</InputItem>
      <InputItem id="PB">PB</InputItem>
      <InputItem id="PE">PE</InputItem>
      <InputItem id="PI">PI</InputItem>
      <InputItem id="PR">PR</InputItem>
      <InputItem id="RJ">RJ</InputItem>
      <InputItem id="RN">RN</InputItem>
      <InputItem id="RO">RO</InputItem>
      <InputItem id="RR">RR</InputItem>
      <InputItem id="RS">RS</InputItem>
      <InputItem id="SC">SC</InputItem>
      <InputItem id="SE">SE</InputItem>
      <InputItem id="SP">SP</InputItem>
      <InputItem id="TO">TO</InputItem>
    </FormComboBoxInput>
  );
}
