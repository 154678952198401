import { useParams } from "react-router-dom";

import { Am, AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { useProducts } from "@/shared/hooks/api";

import { ProductTable } from "../components";
import { ProductModalAssociate } from "../components/product-modal-associate";

export function ProductList() {
  const { subsystemId } = useParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useProducts({ subsistemaId: subsystemId ? +subsystemId : 0, text: "" });

  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <Am I="SeuManual">
      {(isSuperUser) => (
        <AnimatedContainer className="space-y-4">
          <FilterAndCreate
            resourceName="produto"
            onSearchChange={handleSearchChange}
            createOnPress={subsystemId && isSuperUser ? turnOn : undefined}
            createAction="create.product"
          />
          <ProductTable
            search={filters.text ?? undefined}
            rowsData={data?.content}
            isLoading={isLoading}
            pagination={{
              currentPage: filters.page ?? 1,
              pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
              onPageChange: (page) => updateFilters({ page }),
            }}
          />
          {subsystemId && (
            <ProductModalAssociate
              subsystemId={+subsystemId}
              isOpen={isOn}
              onOpenChange={setOpen}
              onSuccess={turnOff}
            />
          )}
        </AnimatedContainer>
      )}
    </Am>
  );
}
