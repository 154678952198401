import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import {
  GetColaboradoresColaboradorGetData,
  apiProvider,
} from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseCompanyWorkersFilters = GetColaboradoresColaboradorGetData;

export function useCompanyWorkers(initial: UseCompanyWorkersFilters) {
  const [filters, updateFilters] =
    useObjReducer<UseCompanyWorkersFilters>(initial);

  const query = useQuery({
    queryKey: ["companyWorkers", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ColaboradorService.getColaboradoresColaboradorGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
