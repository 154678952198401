import { get } from "lodash";

import { useAuthorization } from "../contexts";
import { permissions } from "../utils";

export interface CanProps {
  I: AuthActions;
  children: React.ReactNode | ((allowed: boolean) => React.ReactNode);
}

export function Can({ I, children }: CanProps) {
  const { ability } = useAuthorization();
  const allowed = ability.can(get(permissions, I));

  if (allowed && !(children instanceof Function)) {
    return children;
  }

  if (children instanceof Function) {
    return children(allowed);
  }
}
