import {
  Separator as AriaSeparator,
  SeparatorProps,
} from "react-aria-components";

import { cn } from "../utils";

export function Separator({ className, ...props }: SeparatorProps) {
  return (
    <AriaSeparator
      {...props}
      className={cn("my-4 w-full border-white-3", className)}
    />
  );
}
