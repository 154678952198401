import { RouterProvider } from "react-aria-components";
import { Outlet, useNavigate } from "react-router-dom";

import { AuthorizationProvider } from "@/shared/contexts/authorization";

import PrivateHeader from "../components/private-header";

export function PrivateLayout() {
  const navigate = useNavigate();

  return (
    <RouterProvider navigate={navigate}>
      <AuthorizationProvider>
        <div className="flex h-screen w-screen flex-col bg-white-2">
          <PrivateHeader />
          <Outlet />
        </div>
      </AuthorizationProvider>
    </RouterProvider>
  );
}
