import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useDevelopmentStep(developmentStepId: string) {
  return useQuery({
    queryKey: ["developmentStep", developmentStepId],
    queryFn: async () => {
      return apiProvider.services.EtapasService.getOneObjEtapaIdGet({
        id: +developmentStepId,
      });
    },
    enabled: !!developmentStepId,
  });
}
