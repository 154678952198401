import { Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { DevelopmentStepCreateForm } from "../components";

export function DevelopmentStepCreate() {
  const { developmentId } = useRequiredParams();

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar etapa</PageTitle>
      </div>
      <DevelopmentStepCreateForm
        redirectTo="../"
        useFormProps={{ defaultValues: { empreendimentoId: +developmentId } }}
      />
    </div>
  );
}
