interface ExpirationStringProps {
  frequency?: number | null;
  timeUnit?: string | null;
}

export const expirationString = ({
  frequency,
  timeUnit,
}: ExpirationStringProps) => {
  if (frequency && frequency > 1) {
    if (timeUnit === "mês") {
      return `${frequency} meses`;
    }
    return `${frequency} ${timeUnit}s`;
  }

  return `${frequency} ${timeUnit}`;
};
