import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

type CompanyUpdatePayload = Parameters<
  typeof apiProvider.services.ConstrutorasService.updateObjConstrutorasIdPut
>[0];

export function useMutationCompanyUpdate() {
  return useMutation({
    mutationFn: (payload: CompanyUpdatePayload) => {
      return apiProvider.services.ConstrutorasService.updateObjConstrutorasIdPut(
        payload,
      );
    },
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["company", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar construtora: ${e.message}`,
      }),
  });
}
