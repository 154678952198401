import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  ActiveFlagFilter,
  Can,
  ClientTypeFilter,
  CreatedAtFilter,
  DetailsCell,
  DevelopmentFilter,
  FiltersAccordion,
  Link,
  PageTitle,
  PaginationButtons,
  SearchInput,
  Table,
  TableRow,
} from "@/shared/components";
import { useClients } from "@/shared/hooks/api";

export function ListClients() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useClients({ text: "" });

  return (
    <div className="flex h-full flex-col p-8 animate-in fade-in slide-in-from-left-2">
      <div className="mb-6 flex justify-between">
        <PageTitle primary>Clientes</PageTitle>
        <Can I="create.client">
          <div className="flex items-center">
            <Link className="w-auto px-10 py-3" href="cadastrar/">
              Cadastrar cliente
            </Link>
          </div>
        </Can>
      </div>
      <div className="mb-4 flex gap-3">
        <SearchInput className="w-full" onDebounceChange={handleSearchChange} />
      </div>
      <FiltersAccordion className="mb-4">
        <DevelopmentFilter isDisabled />
        <ClientTypeFilter
          onSelectionChange={(v) =>
            updateFilters({ tipoId: v ? (+v > 0 ? +v : undefined) : undefined })
          }
        />
        <CreatedAtFilter onDateChange={(data) => updateFilters({ data })} />
        <ActiveFlagFilter
          onSelectionChange={(status) => updateFilters({ status })}
        />
      </FiltersAccordion>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0 shadow-header">
        <div className="overflow-y-auto">
          <Can I="get.client">
            {(allowed) => (
              <Table
                aria-label="Lista clientes"
                headers={["Código", "Nome", "E-mail", "Status"]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "cliente",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell>{data.id}</Cell>
                    <Cell>{data.nome}</Cell>
                    <Cell>{data.email}</Cell>
                    <ActiveFlagCell active={data.flagAtivo} />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
