import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useProfile() {
  return useQuery({
    queryKey: ["profile"],
    queryFn: apiProvider.services.UsuariosService.getProfileUsuariosProfileGet,
    staleTime: 60000,
  });
}
