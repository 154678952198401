import { cn } from "../utils";

interface AnimatedContainerProps {
  children: React.ReactNode;
  className?: string;
}

export function AnimatedContainer({
  className,
  children,
}: AnimatedContainerProps) {
  return (
    <div
      className={cn("mt-4 animate-in fade-in slide-in-from-left-2", className)}
    >
      {children}
    </div>
  );
}
