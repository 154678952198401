import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useCompanyWorker(id: string) {
  return useQuery({
    queryKey: ["companyWorker", id],
    queryFn: async () => {
      return apiProvider.services.ColaboradorService.getColaboradorColaboradorIdGet(
        {
          id: +id,
        },
      );
    },
    enabled: !!id,
  });
}
