import clsx from "clsx";

interface PageTitleProps {
  children: React.ReactNode;
  primary?: boolean;
  className?: string;
}

export function PageTitle({ primary, children, className }: PageTitleProps) {
  return (
    <h4
      className={clsx(
        {
          "text-h4 font-bold text-primary": primary,
          "text-h4 font-bold text-gray-12": !primary,
        },
        className,
      )}
    >
      {children}
    </h4>
  );
}
