import {
  Modal as AriaModal,
  ModalOverlay,
  ModalOverlayProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

const overlayStyles = tv({
  base: "fixed inset-0 z-10 flex h-screen items-center justify-center bg-black-0/25 p-4 backdrop-blur",
  variants: {
    isEntering: {
      true: "duration-300 ease-out animate-in fade-in",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out fade-out",
    },
  },
});

const modalStyles = tv({
  base: "max-h-full overflow-hidden rounded-2xl bg-white-0 shadow-xl",
  variants: {
    isEntering: {
      true: "duration-300 ease-out animate-in zoom-in-95",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out zoom-out-95",
    },
  },
});

export function Modal(props: Omit<ModalOverlayProps, "className">) {
  return (
    <ModalOverlay className={overlayStyles} {...props}>
      <AriaModal className={modalStyles} {...props} />
    </ModalOverlay>
  );
}
