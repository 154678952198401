import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { SubsystemFaqCreateForm } from "../components";

export function SubsystemFaqCreate() {
  const { subsystemId } = useRequiredParams();

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar FAQ</PageTitle>
        <SubsystemFaqCreateForm
          redirectTo="../"
          useFormProps={{
            defaultValues: {
              subsistemaId: +subsystemId,
            },
          }}
        />
      </div>
    </AnimatedContainer>
  );
}
