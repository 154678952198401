import { Handle, RouteObject } from "react-router-dom";

import { CompanyEdit } from "../companies/containers";
import { editCompanyAction } from "../companies/modules/edit/actions";
import { editWorkerAction } from "../companies/modules/workers/actions";
import {
  WorkerDetail,
  WorkerEdit,
} from "../companies/modules/workers/containers";
import { CompanyDetail } from "./containers/company-detail";

export const workerCompanyRouter: RouteObject = {
  path: "construtora",
  handle: {
    crumb: "Detalhes da construtora",
    action: "get.company",
  } as Handle,
  children: [
    {
      index: true,
      element: <CompanyDetail />,
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar construtora",
        action: "update.company",
      } as Handle,
      action: editCompanyAction,
      element: <CompanyEdit />,
    },
    {
      path: "colaborador/:companyWorkerId",
      handle: {
        crumb: "Detalhes do colaborador",
        action: "get.worker",
      } as Handle,
      children: [
        {
          index: true,
          element: <WorkerDetail />,
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar colaborador",
            action: "update.worker",
          } as Handle,
          action: editWorkerAction,
          element: <WorkerEdit />,
        },
      ],
    },
  ],
};
