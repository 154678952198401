import { Add, ArrowRight2, TrendUp, UserOctagon } from "iconsax-react";
import { useMemo } from "react";
import { DialogTrigger } from "react-aria-components";

import { DevelopmentManuals } from "@/modules/companies/modules/developments/components";
import { ManualFormModal } from "@/modules/manual/components";
import {
  AnimatedContainer,
  Button,
  Can,
  Card,
  DownloadFiles,
  EditLink,
  FieldSetTitle,
  Link,
  QueryHandler,
  ResourceProfileImage,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { useRequiredParams } from "@/shared/hooks";
import {
  useClients,
  useDevelopment,
  useManuals,
  useMutationDevelopmentUpdate,
  useMutationManualCreate,
} from "@/shared/hooks/api";
import { cnpjMask, nullishStringMask } from "@/shared/utils/masks";

import {
  DevelopmentDataError,
  DevelopmentDataLoading,
  DevelopmentDataModules,
} from "../components";

export function DevelopmentData() {
  const { developmentId, companyId } = useRequiredParams();
  const developmentQuery = useDevelopment(developmentId);
  const clientsQuery = useClients({
    empreendimentoId: +developmentId,
    elementsPerPage: 1,
  });
  const manualsQuery = useManuals({ empreendimentoId: +developmentId });
  const manualCreateMutation = useMutationManualCreate({
    companyId: +companyId,
    onSuccess: () => {
      toastQueue.add({
        type: "success",
        message: "Sucesso ao adicionar manual",
      });
    },
  });
  const mutationDevelopmentUpdate = useMutationDevelopmentUpdate();

  const { data } = developmentQuery;
  const _files = useMemo(() => {
    if (!data || (!data.contrato && !data.artCrea)) {
      return undefined;
    }

    const result: string[] = [data.contrato, data.artCrea].filter(
      (file) => typeof file === "string",
    ) as string[];

    return result;
  }, [data]);

  return (
    <AnimatedContainer className="space-y-7">
      <QueryHandler
        {...developmentQuery}
        renderLoading={DevelopmentDataLoading}
        renderError={(error) => <DevelopmentDataError error={error} />}
      >
        {(data) => (
          <>
            <div className="space-y-4">
              <Card className="relative flex items-center justify-between gap-4 p-6 shadow-header">
                <div className="absolute left-0 top-0 h-full w-2 rounded-l-lg bg-primary" />
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <ResourceProfileImage size="small" file={data?.foto} />
                      <h5 className="text-h5 font-bold text-black-2">
                        {data?.nome}
                      </h5>
                      <Can I="update.development">
                        <EditLink />
                      </Can>
                      <div className="flex items-center gap-2">
                        {data && _files && (
                          <DownloadFiles
                            className="gap-1 px-3 text-xs [&>div]:!size-4 [&>div]:!border-2 [&>div]:!border-y-info"
                            color="info"
                            styleType="secondary"
                            files={_files}
                            zipName={`documentos_${data.nome}`}
                          >
                            <TrendUp size="1rem" />
                            Baixar documentos
                          </DownloadFiles>
                        )}
                      </div>
                    </div>
                    <Can I="update.development">
                      <SwitchStatusButton
                        isDisabled={mutationDevelopmentUpdate.isPending}
                        isSelected={data?.flagAtivo}
                        onChange={(status) =>
                          mutationDevelopmentUpdate.mutateAsync({
                            id: +developmentId,
                            requestBody: { flagAtivo: status },
                          })
                        }
                      />
                    </Can>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="ml-12 flex items-center gap-4 self-end text-xs font-medium text-gray-10">
                      <p>
                        Contato:
                        <span className="ml-2 font-bold text-primary-light-4">
                          {nullishStringMask(data?.email)}
                        </span>
                      </p>
                      <p>
                        CNPJ:
                        <span className="ml-2 font-bold text-primary-light-4">
                          {nullishStringMask(cnpjMask(data?.cnpj ?? undefined))}
                        </span>
                      </p>
                      <p>
                        Data da entrega:
                        <span className="ml-2 font-bold text-primary-light-4">
                          {nullishStringMask(
                            data?.dataEntrega
                              ? new Date(data?.dataEntrega).toLocaleDateString()
                              : undefined,
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <Separator />
                  <p className="mb-3 text-sm font-bold text-black-2">Módulos</p>
                  <DevelopmentDataModules
                    manualInterativo={data?.manualInterativo}
                    assistenciaTecnica={data?.assistenciaTecnica}
                    gestaoDeManutencaoPreventiva={
                      data?.gestaoDeManutencaoPreventiva
                    }
                  />
                  <p className="mt-5 rounded border-l-4 border-primary-light-2 p-2 text-sm  italic text-gray-12">
                    Informativo sobre o empreendimento e seus módulos: Cada
                    empreendimento possui especificações e configurações
                    próprias, os módulos apresentado acima estão habilitados
                    para uso nesse empreendimento. Se desejar adicionar ou tirar
                    algum dos módulos entre em contato com a administração
                  </p>
                  <div className="mt-4 flex flex-col">
                    <p className="text-sm font-bold text-black-2">
                      Administração Seu Manual pro
                    </p>
                    <p className="text-sm font-medium text-primary-light-4">
                      contato@seumanualpro.com.br
                    </p>
                  </div>
                </div>
              </Card>
              <Can I="list.client">
                <Card className="flex justify-between shadow-header">
                  <div className="flex items-center gap-5 font-semibold text-primary">
                    <UserOctagon variant="Bold" size="2rem" />
                    <span className="text-5xl ">
                      {clientsQuery[0].data?.total}
                    </span>
                    <span className="text-h5 text-black-2">clientes</span>
                  </div>
                  <Link
                    className="group flex items-center gap-4 font-semibold"
                    styleType="default"
                    href="cliente/"
                  >
                    Ver clientes{" "}
                    <ArrowRight2 className="transition-all group-rac-hover:translate-x-1" />
                  </Link>
                </Card>
              </Can>
            </div>
            <Can I="list.manual">
              <Card className="space-y-4 py-8 shadow-header">
                <div className="flex items-center">
                  <FieldSetTitle className="m-0 flex-1 text-base">
                    Manuais do empreendimento
                  </FieldSetTitle>
                  <Can I="create.manual">
                    <DialogTrigger>
                      <Button
                        className="ml-4 flex items-center gap-4 justify-self-end"
                        styleType="default"
                      >
                        <Add />
                        Adicionar manual
                      </Button>
                      <ManualFormModal
                        useFormProps={{
                          defaultValues: { empreendimentoId: +developmentId },
                        }}
                        onSubmit={async (values, close) => {
                          manualCreateMutation.mutateAsync(
                            {
                              ...values,
                              link: values.link ? values.link[0].key : null,
                            },
                            {
                              onSuccess: close,
                            },
                          );
                        }}
                      />
                    </DialogTrigger>
                  </Can>
                </div>
                <DevelopmentManuals
                  from="details"
                  manuals={manualsQuery[0].data?.content ?? []}
                />
              </Card>
            </Can>
          </>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
