import { FieldValues } from "react-hook-form";

import {
  FormComboBoxInput,
  FormComboBoxInputProps,
  InputItem,
} from "@/shared/components";
import { useCompanyCategories } from "@/shared/hooks/api";

export function FormCompanyCategoryInput<
  T extends object,
  TFieldValues extends FieldValues,
>({ isDisabled, ...props }: FormComboBoxInputProps<T, TFieldValues>) {
  const { data: categories, isLoading, isError } = useCompanyCategories();
  return (
    <FormComboBoxInput
      {...props}
      isDisabled={isDisabled || isLoading || isError}
    >
      {categories?.map((category) => (
        <InputItem key={category.id} id={category.id}>
          {category.nome}
        </InputItem>
      ))}
    </FormComboBoxInput>
  );
}
