import { wrapCreateBrowserRouter } from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";

import privateRouter from "./private-router";
import publicRouter from "./public-router";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([...privateRouter, ...publicRouter]);

export default router;
