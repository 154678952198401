import {
  AnimatedContainer,
  Card,
  ColorInput,
  FieldSetTitle,
  QueryHandler,
  ResourceProfileImage,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useDevelopment } from "@/shared/hooks/api";

import { AppearanceError, AppearanceSkeleton } from "../components";

export function DevelopmentAppearance() {
  const { developmentId } = useRequiredParams();
  const developmentQuery = useDevelopment(developmentId);

  return (
    <AnimatedContainer>
      <QueryHandler
        {...developmentQuery}
        renderLoading={AppearanceSkeleton}
        renderError={(error) => <AppearanceError error={error} />}
      >
        {(data) => (
          <Card className="space-y-6 p-6">
            <div>
              <FieldSetTitle>Favicon</FieldSetTitle>
              <ResourceProfileImage file={data?.foto} />
            </div>
            <div>
              <FieldSetTitle>Cores principais</FieldSetTitle>
              <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <ColorInput
                  label="Cor principal"
                  defaultValue={data?.corPrincipal}
                  isReadOnly
                />
                <ColorInput
                  label="Cor secundária"
                  defaultValue={data?.corSecundaria}
                  isReadOnly
                />
              </div>
            </div>
            <div>
              <FieldSetTitle>Cores do rodapé</FieldSetTitle>
              <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <ColorInput
                  label="Cor da fonte"
                  defaultValue={data?.corDaFonte}
                  isReadOnly
                />
                <ColorInput
                  label="Cor rodapé principal"
                  defaultValue={data?.corRodapePrincipal}
                  isReadOnly
                />
                <ColorInput
                  label="Cor rodapé secundário"
                  defaultValue={data?.corRodapeSecundaria}
                  isReadOnly
                />
                <ColorInput
                  label="Cor da fonte rodapé"
                  defaultValue={data?.corDaFonteRodape}
                  isReadOnly
                />
              </div>
            </div>
          </Card>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
