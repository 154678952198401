import { FaqTable } from "@/modules/faq/components";
import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFaqs } from "@/shared/hooks/api";

export function FaqList() {
  const { subsystemId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useFaqs({ subsistemaId: +subsystemId, text: "" });

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="pergunta"
        onSearchChange={handleSearchChange}
        createAction="create.faq"
      />
      <FaqTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
