import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

const service = apiProvider.services.PapeisService.getObjsPapeisGet;

export function useAcls() {
  const query = useQuery({
    queryKey: ["acls"],
    queryFn: service,
  });

  return [query] as const;
}
