import { LogoutCurve, Personalcard, UserEdit } from "iconsax-react";
import {
  Header,
  Key,
  Menu,
  MenuItem,
  Section,
  Separator,
} from "react-aria-components";
import { useFetcher, useNavigate } from "react-router-dom";

import { Can, ResourceProfileImage } from "@/shared/components";
import { useProfile } from "@/shared/hooks/api";

import { HeadPopover } from "./header-popover";

export interface ProfileMenuProps {
  onClose?: () => void;
}

export function ProfileMenu({ onClose }: ProfileMenuProps) {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const profileQuery = useProfile();

  const handleAction = (key: Key) => {
    if (key === "/app/logout") {
      return fetcher.submit(null, { method: "post", action: key });
    }

    onClose?.();
    navigate(key.toString());
  };

  return (
    <HeadPopover className="w-64" translateX={8}>
      <Menu
        aria-labelledby="Profile"
        className="*:cursor-pointer *:outline-transparent"
        onAction={handleAction}
      >
        <Section className="*:cursor-default">
          <Header className="flex items-center justify-between px-2 py-4">
            <div className="flex-1">
              <p className="font-bold">{profileQuery.data?.nome}</p>
              <p className="text-sm text-primary">
                {profileQuery.data?.papel.nome}
              </p>
            </div>
            <ResourceProfileImage
              file={profileQuery.data?.foto}
              size="extraSmall"
              emptyIcon="profile"
            />
          </Header>
        </Section>
        <Separator className="mb-2 border-t border-gray-1" />
        <Can I="update.profile">
          <MenuItem
            id="/perfil/"
            className="flex gap-4 rounded-lg px-2 py-4 text-gray-6 transition-all hover:bg-black-0/5"
          >
            <UserEdit variant="Bold" />
            Perfil do usuário
          </MenuItem>
        </Can>
        <Can I="list.user">
          <MenuItem
            id="/usuarios/"
            className="flex gap-4 rounded-lg px-2 py-4 text-gray-6 transition-all hover:bg-black-0/5"
          >
            <Personalcard variant="Bold" />
            Usuários
          </MenuItem>
        </Can>
        <Separator className="my-2 border-t border-gray-1" />
        <MenuItem
          id="/app/logout"
          className="group flex gap-4 rounded-lg px-2 py-4 font-bold text-error transition-all hover:bg-error-20"
        >
          <LogoutCurve className="transition-all group-hover:-translate-x-1" />
          Sair
        </MenuItem>
      </Menu>
    </HeadPopover>
  );
}
