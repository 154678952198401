import { QRCodeSVG } from "qrcode.react";

import { QueryHandler } from "@/shared/components";
import { useFile } from "@/shared/hooks/api";

export interface ManualQrCodeProps {
  url: string;
  imageKey?: string;
}

export function ManualQrCode({ url, imageKey }: ManualQrCodeProps) {
  const logoQuery = useFile(imageKey);

  return (
    <QueryHandler {...logoQuery}>
      {(data) => (
        <QRCodeSVG
          value={url}
          size={256}
          imageSettings={
            data?.url
              ? {
                  src: data.url,
                  height: 51,
                  width: 51,
                  excavate: true,
                }
              : undefined
          }
        />
      )}
    </QueryHandler>
  );
}
