import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface ClientDetailErrorProps {
  error: Error;
}
export function ClientDetailError({ error }: ClientDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Cliente não encontrado..."
          : undefined
      }
    />
  );
}
