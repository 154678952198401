import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";

import { useCep } from "@/shared/hooks/api";
import { CepApiResponse } from "@/shared/services/cep-api-interface";
import { cepMask } from "@/shared/utils/masks";

import { FormTextInput, FormTextInputProps } from ".";

interface FormBrCepInputProps<T extends FieldValues>
  extends FormTextInputProps<T> {
  onCepDataChanged?: (cep?: CepApiResponse | null) => void;
}

export function FormBrCepInput<T extends FieldValues>({
  onCepDataChanged,
  ...props
}: FormBrCepInputProps<T>) {
  const [cep, setCep] = useState("");

  const { data } = useCep(cep);

  useEffect(() => {
    onCepDataChanged?.(data);
  }, [data, onCepDataChanged]);

  return <FormTextInput {...props} onChange={setCep} mask={cepMask} />;
}
