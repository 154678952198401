import { FieldValues } from "react-hook-form";

import { PapelWithOutDates, apiProvider } from "@/shared/services";

import {
  FormSelectApiResourceInput,
  FormSelectApiResourceInputProps,
} from "..";

export type FormSelectSystemUserTypeInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormSelectApiResourceInputProps<T, TFieldValues, PapelWithOutDates>,
  "service" | "queryKey" | "label" | "mapElements"
>;

export function FormSelectSystemUserInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormSelectSystemUserTypeInputProps<T, TFieldValues>) {
  return (
    <FormSelectApiResourceInput
      label="Cargo"
      queryKey={["systemUserType"]}
      service={
        apiProvider.services.PapeisService
          .getPapeisEngegroutPapeisTiposPapeisEngegroutGet
      }
      mapElements={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
