import { Cell } from "react-aria-components";

import {
  AnimatedContainer,
  Can,
  DetailsCell,
  FilterAndCreate,
  PaginationButtons,
  Table,
  TableRow,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useSubsystems } from "@/shared/hooks/api";

export function SubsystemList() {
  const { systemId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useSubsystems({ sistemaId: +systemId });

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="subsistema"
        onSearchChange={handleSearchChange}
        createAction="create.subsystem"
      />
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Can I="get.subsystem">
            {(allowed) => (
              <Table
                aria-label="Lista subsistemas"
                headers={["Nome"]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "subsistema",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={({ id, nome }) => (
                  <TableRow
                    key={id}
                    href={allowed ? `detalhes/${id}/` : undefined}
                  >
                    <Cell>{nome}</Cell>
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </AnimatedContainer>
  );
}
