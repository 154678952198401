import { z } from "@/shared/utils";

export const SubsystemEditSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  memorialId: z.number().int().nullish(),
  especificacoes: z.string().trim().nullish(),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  sistemaId: z.number().int(),
});

export type SubsystemEdit = z.infer<typeof SubsystemEditSchema>;
