import { cn } from "@/shared/utils";

export interface AclSessionListProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  permissions?: string[];
}

export function AclSessionList({
  className,
  name,
  permissions = [],
  ...props
}: AclSessionListProps) {
  return (
    <div className={cn("flex flex-col", className)} {...props}>
      <p className="mb-3 text-sm text-black-2">{name}</p>
      <div className="flex-1 space-y-4 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
        {permissions.length > 0 ? (
          permissions.map((perm) => (
            <div key={perm}>
              <p className="font-bold text-primary">{perm}</p>
            </div>
          ))
        ) : (
          <p>Vazio</p>
        )}
      </div>
    </div>
  );
}
