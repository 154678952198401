import { forwardRef } from "react";

import { cn } from "../utils";

export interface CardProps extends React.ComponentProps<"div"> {}

export const Card = forwardRef(
  (
    { children, className, ...props }: CardProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        className={cn(
          "rounded-lg border border-white-3 bg-white-0 px-6 py-4",
          className,
        )}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
