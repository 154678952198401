import { Cell } from "react-aria-components";

import {
  AnimatedContainer,
  Can,
  DetailsCell,
  FilterAndCreate,
  PaginationButtons,
  Table,
  TableRow,
} from "@/shared/components";
import { useNbrs } from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function RecordNbrList() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useNbrs();

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="NBR"
        onSearchChange={handleSearchChange}
        createAction="create.nbr"
      />
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Can I="get.nbr">
            {(allowed) => (
              <Table
                aria-label="Lista Nbr"
                headers={["NBR", "Descrição"]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "NBR",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={({ id, nome, descricao }) => (
                  <TableRow
                    key={id}
                    href={allowed ? `detalhes/${id}/` : undefined}
                  >
                    <Cell>{nome}</Cell>
                    <Cell className="max-w-36">
                      {descricao ? (
                        <p
                          className="line-clamp-3 overflow-hidden text-ellipsis"
                          dangerouslySetInnerHTML={{
                            __html: sanitize(descricao, true),
                          }}
                        />
                      ) : (
                        "Vazio"
                      )}
                    </Cell>
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </AnimatedContainer>
  );
}
