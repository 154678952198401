import { Outlet, useLocation } from "react-router-dom";

import { DrawerlessLayout, TabPanel, Tabs } from "@/shared/components";
import { useAuthorization } from "@/shared/contexts";
import { useAuthorizedTabs } from "@/shared/hooks";

export function UsersLayout() {
  const { pathname } = useLocation();
  const { authorized } = useAuthorization();

  const tabs = useAuthorizedTabs([
    {
      id: "/usuarios/",
      href: "/usuarios/",
      label: "Usuários",
      action: "list.user",
    },
    {
      id: "/usuarios/niveis-de-acesso/",
      href: "/usuarios/niveis-de-acesso/",
      label: "Níveis de acesso",
      action: "list.permission",
    },
  ]);

  return (
    <DrawerlessLayout title="Usuários">
      <Tabs
        className="rounded-t-none shadow-none"
        aria-label="Usuários"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          {authorized ?? true ? <Outlet /> : <p>Não autorizado</p>}
        </TabPanel>
      </Tabs>
    </DrawerlessLayout>
  );
}
