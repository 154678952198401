import { Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { ApartmentCreateForm } from "../components";

export function ApartmentCreate() {
  const { developmentId, blockId } = useRequiredParams();

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <div className="flex-1 space-y-4 overflow-y-scroll p-8 animate-in fade-in slide-in-from-left-2">
        <Breadcrumbs />
        <PageTitle primary>Apartamento</PageTitle>
        <ApartmentCreateForm
          redirectTo="../"
          useFormProps={{ defaultValues: { unidade: { blocoId: +blockId } } }}
          empreendimentoId={+developmentId}
        />
      </div>
    </div>
  );
}
