import { apiProvider } from "@/shared/services";

import { ComboBoxApiResourceInput, ComboBoxApiResourceInputProps } from ".";

type ServiceFunction = typeof service;

export type ComboBoxCompanyInputProps<T extends object> = Omit<
  ComboBoxApiResourceInputProps<
    T,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;

const service = apiProvider.services.ConstrutorasService.getObjsConstrutorasGet;

export function ComboBoxCompanyInput<T extends object>(
  props: ComboBoxCompanyInputProps<T>,
) {
  return (
    <ComboBoxApiResourceInput
      label="Construtora"
      queryKey={["constructionCompanies"]}
      service={async ({ pageParam: page }, extraFilters) =>
        await service({
          page,
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
