import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface ProductDetailErrorProps {
  error: Error;
}

export function ProductDetailError({ error }: ProductDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Produto não encontrado..."
          : undefined
      }
    />
  );
}
