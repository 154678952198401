import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useTopics } from "@/shared/hooks/api";

import { TopicTable } from "../components";

export function ManualTopicList() {
  const { manualId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useTopics({ manualId: +manualId });

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="tópico"
        onSearchChange={handleSearchChange}
        createAction="create.topic"
      />
      <TopicTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
