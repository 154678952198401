import { Handle, RouteObject } from "react-router-dom";

import { DocumentList } from "@/shared/modules/document/containers";

import { editBlockAction } from "../edit/actions";
import { DevelopmentBlockEdit } from "../edit/containers";
import { apartmentCreateAction, editApartmentAction } from "./actions";
import {
  ApartmentCreate,
  ApartmentDetail,
  ApartmentDetailLayout,
  ApartmentEdit,
  ApartmentsList,
  BlockData,
} from "./containers";
import { BlockLayout } from "./layout";

export const blockDetailRouter: RouteObject = {
  path: "detalhes/:blockId",
  handle: {
    crumb: "Detalhes do bloco",
    tabBaseUrl: "block",
    action: "get.block",
  } as Handle,
  children: [
    {
      element: <BlockLayout />,
      children: [
        {
          index: true,
          element: <BlockData />,
        },
        {
          path: "apartamento",
          handle: {
            crumb: "Lista de apartamentos",
            action: "list.apartment",
          } as Handle,
          element: <ApartmentsList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar bloco",
        action: "update.block",
      } as Handle,
      action: editBlockAction,
      element: <DevelopmentBlockEdit />,
    },
    {
      path: "apartamento",
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar apartamento",
            action: "create.apartment",
          } as Handle,
          action: apartmentCreateAction,
          element: <ApartmentCreate />,
        },
        {
          path: "detalhes/:apartmentId",
          handle: {
            crumb: "Detalhes do apartamento",
            tabBaseUrl: "apartment",
            action: "get.apartment",
          } as Handle,
          children: [
            {
              element: <ApartmentDetailLayout />,
              children: [
                {
                  index: true,
                  element: <ApartmentDetail />,
                },

                {
                  path: "arquivos",
                  handle: {
                    crumb: "Lista de arquivos",
                    action: "list.document",
                  } as Handle,
                  element: <DocumentList />,
                },
              ],
            },
            {
              path: "editar",
              handle: {
                crumb: "Editar apartamento",
                action: "update.apartment",
              } as Handle,
              action: editApartmentAction,
              element: <ApartmentEdit />,
            },
          ],
        },
      ],
    },
  ],
};
