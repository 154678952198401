import {
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  ResourceProfileImage,
  Skeleton,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationUserUpdate, useUser } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

export function UserDetail() {
  const { userId } = useRequiredParams();
  const userQuery = useUser(userId);
  const mutationUserUpdate = useMutationUserUpdate();

  function renderLoading() {
    return (
      <Card className="flex justify-between shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
        <div className="flex items-center gap-4">
          <div className="flex size-24 animate-pulse items-center justify-center rounded-full bg-gray-1 text-xs text-gray-10" />
          <div className="flex flex-col justify-center">
            <Skeleton className="my-2 h-4 w-40" />
            <div className="flex gap-6">
              <Skeleton className="my-2 h-4 w-24" />
              <Skeleton className="my-2 h-4 w-24" />
              <Skeleton className="my-2 h-4 w-24" />
            </div>
          </div>
        </div>
      </Card>
    );
  }

  function renderError(error: unknown) {
    return (
      <ErrorContainer
        message={
          error instanceof ApiError && error.status === 404
            ? "Usuário não encontrado..."
            : undefined
        }
      />
    );
  }

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle>Detalhes do usuário</PageTitle>
      </div>
      <div className="space-y-4">
        <QueryHandler
          {...userQuery}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {(data) => (
            <Card className="flex justify-between shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
              <div className="flex items-center gap-4">
                <ResourceProfileImage file={data?.foto} />
                <div className="flex flex-col justify-center">
                  <div className="flex items-center gap-2">
                    <p className="black-2 font-bold">{data?.nome}</p>
                    <Can I="update.user">
                      <EditLink />
                    </Can>
                  </div>
                  <div className="flex gap-6 text-xs text-primary">
                    <p>
                      Permissão:{" "}
                      <span className="font-semibold">
                        {nullishStringMask(data?.papel.nome)}
                      </span>
                    </p>
                    <p>
                      Telefone:{" "}
                      <span className="font-semibold">
                        {nullishStringMask(
                          phoneMask(data?.telefone ?? undefined),
                        )}
                      </span>
                    </p>
                    <p>
                      E-mail:{" "}
                      <span className="font-semibold">
                        {nullishStringMask(data?.email)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <SwitchStatusButton
                className="self-start justify-self-end"
                isDisabled={mutationUserUpdate.isPending}
                isSelected={data?.flagAtivo}
                onChange={(status) =>
                  mutationUserUpdate.mutateAsync({
                    id: +userId,
                    requestBody: { flagAtivo: status },
                  })
                }
              />
            </Card>
          )}
        </QueryHandler>
      </div>
    </div>
  );
}
