import { ModalOverlayProps } from "react-aria-components";

import { Dialog, Modal, S3Image } from "@/shared/components";
import { cn } from "@/shared/utils";

interface ModalImagePreviewProps extends Omit<ModalOverlayProps, "children"> {
  file: { key: string; fileName: string }[];
}

export function ModalImagePreview({ file, ...props }: ModalImagePreviewProps) {
  return (
    <Modal isDismissable {...props}>
      <Dialog className="w-screen max-w-[40vw]" title="Prévia da imagem">
        <S3Image
          className={(type) =>
            cn({
              "object-contain": type === "img",
              "h-full w-full": type !== "empty",
            })
          }
          file={file[0].key}
          emptyIcon={"default"}
        />
      </Dialog>
    </Modal>
  );
}
