import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useCompanyWorker } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { WorkerEditForm } from "../components";

export function WorkerEdit() {
  const { companyWorkerId } = useRequiredParams();

  const companyWorkerQuery = useCompanyWorker(companyWorkerId);

  const getDefaultValues = (data: typeof companyWorkerQuery.data) => {
    if (!data) {
      return undefined;
    }
    const { cpf, telefone, construtoraId, ...values } = data;
    return {
      defaultValues: {
        ...values,
        construtoraId: construtoraId ?? undefined,
        cpf: cpfMask(cpf ?? undefined),
        telefone: phoneMask(telefone ?? undefined),
      },
    };
  };

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar colaborador</PageTitle>
        <QueryHandler
          {...companyWorkerQuery}
          renderLoading={() => <Spinner />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Colaborador não encontrado..."
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <WorkerEditForm
              useFormProps={getDefaultValues(data)}
              redirectTo="../"
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
