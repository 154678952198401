export const displayDateHourMin = (date?: string | null) => {
  if (!date) {
    return "Vazio";
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(dateObj);
};
