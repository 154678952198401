import * as Sentry from "@sentry/react";
import {
  Navigate,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";

export function ErrorPage() {
  const error = useRouteError();

  console.error(error);
  Sentry.captureException(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <h1 className="mb-6 text-4xl font-semibold">Oops!</h1>
      <p className="mb-6 ">Sinto muito, mas ocorreu um erro.</p>
      <p className="text-gray-700">
        <i>{isRouteErrorResponse(error) ? error.statusText : ""}</i>
      </p>
    </div>
  );
}
