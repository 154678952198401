import { Handle, RouteObject } from "react-router-dom";

import { PageLayout } from "@/shared/layout";

import { createUserAction, userEditAction } from "./actions";
import {
  AclDetail,
  AclList,
  CreateUser,
  UserDetail,
  UserEdit,
  UsersList,
} from "./containers";
import { UsersLayout } from "./layout";

export const usersRouter: RouteObject = {
  path: "usuarios",
  handle: {
    crumb: "Lista de usuários",
  } as Handle,
  children: [
    {
      element: <UsersLayout />,
      children: [
        {
          index: true,
          handle: {
            action: "list.user",
          } as Handle,
          element: <UsersList />,
        },
        {
          path: "niveis-de-acesso/",
          handle: {
            crumb: "Lista de permissões",
            action: "list.permission",
          } as Handle,
          element: <AclList />,
        },
      ],
    },
    {
      element: <PageLayout />,
      children: [
        {
          path: "detalhes/:userId",
          handle: {
            crumb: "Detalhes do usuário",
            action: "get.user",
          } as Handle,
          children: [
            { index: true, element: <UserDetail /> },
            {
              path: "editar",
              handle: {
                crumb: "Editar usuário",
                action: "update.user",
              } as Handle,
              action: userEditAction,
              element: <UserEdit />,
            },
          ],
        },
        {
          path: "cadastrar/",
          handle: {
            crumb: "Cadastrar usuário",
            action: "create.user",
          } as Handle,
          action: createUserAction,
          element: <CreateUser />,
        },
        {
          path: "niveis-de-acesso/",
          handle: {
            crumb: "Lista de permissões",
            action: "list.permission",
          } as Handle,
          children: [
            {
              path: "detalhes/:aclId",
              handle: {
                crumb: "Detalhes da permissão",
                action: "get.permission",
              } as Handle,
              element: <AclDetail />,
            },
          ],
        },
      ],
    },
  ],
};
