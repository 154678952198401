import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";
import { z } from "zod";

import { getDigits } from "@/shared/utils";

export const CreateClientSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  email: z.string().email(),
  telefone: z
    .string()
    .nullish()
    .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  cpf: z
    .string()
    .nullish()
    .transform((v) => (v ? getDigits(v) : v))
    .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
});

export type CreateClient = z.infer<typeof CreateClientSchema>;
