import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFaq } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { z } from "@/shared/utils";

import { FaqCreateForm, FaqCreateSchema } from "../../create/components";

export function FaqEdit() {
  const { faqId } = useRequiredParams();
  const faqQuery = useFaq(faqId);

  const getDefaultValues = (data: typeof faqQuery.data) => {
    if (!data) {
      return undefined;
    }

    const defaultValues = {
      publicoAlvo: data.seConstrutora ? "construtora" : "cliente",
      nome: data?.nome,
      descricao: data?.descricao,
      videoUrl: data?.videoUrl
        ? [
            {
              fileName: data.videoUrl,
              key: data.videoUrl,
            },
          ]
        : undefined,
    };

    return { defaultValues: defaultValues as z.infer<typeof FaqCreateSchema> };
  };

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar FAQ</PageTitle>
      </div>
      <QueryHandler
        {...faqQuery}
        renderLoading={() => <Spinner />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Faq não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <FaqCreateForm
            redirectTo="../"
            useFormProps={getDefaultValues(data)}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
