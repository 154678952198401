import { Editor } from "@tiptap/react";
import clsx from "clsx";
import {
  Image,
  Link21,
  Smallcaps,
  TableDocument,
  TextBold,
  TextItalic,
  TextUnderline,
  TextalignCenter,
  TextalignJustifycenter,
  TextalignLeft,
  TextalignRight,
} from "iconsax-react";
import { useCallback } from "react";
import {
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuTrigger,
  Popover,
  Separator,
} from "react-aria-components";

import { getImage } from "@/shared/utils";

import { toastQueue } from "../toast";

interface ToolbarProps {
  editor: Editor | null;
}

const actions: Record<string, (editor: Editor | null) => void> = {
  "create-table": (editor: Editor | null) =>
    editor
      ?.chain()
      .focus()
      .insertTable({ rows: 2, cols: 2, withHeaderRow: true })
      .run(),
  "delete-table": (editor: Editor | null) =>
    editor?.chain().focus().deleteTable().run(),
  "create-column-before": (editor: Editor | null) =>
    editor?.chain().focus().addColumnBefore().run(),
  "create-column-after": (editor: Editor | null) =>
    editor?.chain().focus().addColumnAfter().run(),
  "delete-column": (editor: Editor | null) =>
    editor?.chain().focus().deleteColumn().run(),
  "add-row-before": (editor: Editor | null) =>
    editor?.chain().focus().addRowBefore().run(),
  "add-row-after": (editor: Editor | null) =>
    editor?.chain().focus().addRowAfter().run(),
  "delete-row": (editor: Editor | null) =>
    editor?.chain().focus().deleteRow().run(),
  "merge-cells": (editor: Editor | null) =>
    editor?.chain().focus().mergeCells().run(),
  "split-cells": (editor: Editor | null) =>
    editor?.chain().focus().splitCell().run(),
  "toggle-header-column": (editor: Editor | null) =>
    editor?.chain().focus().toggleHeaderColumn().run(),
  "toggle-header-row": (editor: Editor | null) =>
    editor?.chain().focus().toggleHeaderRow().run(),
  "toggle-header-cell": (editor: Editor | null) =>
    editor?.chain().focus().toggleHeaderCell().run(),
  "merge-or-split": (editor: Editor | null) =>
    editor?.chain().focus().mergeOrSplit().run(),
};

export function Toolbar({ editor }: ToolbarProps) {
  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [editor]);

  return (
    <div className="flex gap-3 rounded-t-xl bg-primary-light-1 px-4 py-3 text-gray-6 [&>*]:cursor-pointer">
      <TextalignLeft
        className={clsx({
          "text-primary": editor?.isActive({ textAlign: "left" }),
        })}
        size={16}
        onClick={() => editor?.chain().focus().setTextAlign("left").run()}
      />
      <TextalignCenter
        className={clsx({
          "text-primary": editor?.isActive({ textAlign: "center" }),
        })}
        size={16}
        onClick={() => editor?.chain().focus().setTextAlign("center").run()}
      />
      <TextalignRight
        className={clsx({
          "text-primary": editor?.isActive({ textAlign: "right" }),
        })}
        size={16}
        onClick={() => editor?.chain().focus().setTextAlign("right").run()}
      />
      <TextalignJustifycenter
        className={clsx({
          "text-primary": editor?.isActive({ textAlign: "justify" }),
        })}
        size={16}
        onClick={() => editor?.chain().focus().setTextAlign("justify").run()}
      />
      <Link21 size={16} onClick={setLink} />
      <TextItalic
        className={clsx({
          "text-primary": editor?.isActive("italic"),
        })}
        size={16}
        onClick={() => editor?.chain().focus().toggleItalic().run()}
      />
      <TextBold
        className={clsx({
          "text-primary": editor?.isActive("bold"),
        })}
        size={16}
        onClick={() => editor?.chain().focus().toggleBold().run()}
      />
      <TextUnderline
        className={clsx({
          "text-primary": editor?.isActive("underline"),
        })}
        size={16}
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
      />
      <Smallcaps
        className={clsx({
          "text-primary": editor?.isActive("heading", { level: 5 }),
        })}
        size={16}
        onClick={() =>
          editor?.chain().focus().toggleHeading({ level: 5 }).run()
        }
      />
      <Image
        className={clsx({
          "text-primary": editor?.isActive("image"),
        })}
        size={16}
        onClick={async () => {
          const image = await getImage();
          if (!image) {
            toastQueue.add({
              type: "error",
              message: "Imagem não selecionada",
            });
          }

          editor
            ?.chain()
            .focus()
            .setImage({ src: image as string })
            .run();
        }}
      />
      <MenuTrigger>
        <Button aria-label="Menu">
          <TableDocument
            className={clsx({
              "text-primary": editor?.isActive("table"),
            })}
            size={16}
          />
        </Button>
        <Popover className="origin-top-left overflow-auto rounded-md bg-white-0 p-1 text-sm shadow-lg ring-1 ring-black-0 ring-opacity-5 fill-mode-forwards rac-entering:animate-in rac-entering:fade-in rac-entering:zoom-in-95 rac-exiting:animate-out rac-exiting:fade-out rac-exiting:zoom-out-95">
          <Menu
            className="outline-none"
            onAction={(key) => actions[key.toString()](editor)}
          >
            <TableMenuItem id="create-table">Inserir tabela</TableMenuItem>
            <TableMenuItem id="delete-table">Apagar tabela</TableMenuItem>
            <Separator className="mx-3 my-1 h-[1px] bg-gray-3" />
            <TableMenuItem id="create-column-before">
              Adicionar coluna antes
            </TableMenuItem>
            <TableMenuItem id="create-column-after">
              Adicionar coluna depois
            </TableMenuItem>
            <TableMenuItem id="delete-column">Apagar coluna</TableMenuItem>
            <Separator className="mx-3 my-1 h-[1px] bg-gray-3" />
            <TableMenuItem id="add-row-before">
              Adicionar linha antes
            </TableMenuItem>
            <TableMenuItem id="add-row-after">
              Adicionar linha depois
            </TableMenuItem>
            <TableMenuItem id="delete-row">Apagar linha</TableMenuItem>
            <Separator className="mx-3 my-1 h-[1px] bg-gray-3" />
            <TableMenuItem id="merge-cells">Juntar células</TableMenuItem>
            <TableMenuItem id="split-cells">Dividir célula</TableMenuItem>
            <Separator className="mx-3 my-1 h-[1px] bg-gray-3" />
            <TableMenuItem id="toggle-header-column">
              Alternar coluna de cabeçalho
            </TableMenuItem>
            <TableMenuItem id="toggle-header-row">
              Alternar linha de cabeçalho
            </TableMenuItem>
            <TableMenuItem id="toggle-header-cell">
              Alternar célula de cabeçalho
            </TableMenuItem>
            <TableMenuItem id="merge-or-split">Juntar ou Dividir</TableMenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
}

export function TableMenuItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      className={
        "box-border flex w-full cursor-default items-center rounded-md px-3 py-2 text-black-0 outline-none focus:text-white-0 rac-focus:bg-primary-light-3"
      }
    />
  );
}
