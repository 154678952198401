import { CloseCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { AriaFieldProps, useField } from "react-aria";
import { Button } from "react-aria-components";

import { useSwitch } from "@/shared/hooks";
import { Nbr } from "@/shared/services";
import { cn } from "@/shared/utils";

import { FieldError, FieldLabel, NbrListModal } from "..";
import { InputStyleProps, container, input } from "./style/input";

export interface NbrPickerInputProps
  extends Omit<AriaFieldProps, "description">,
    InputStyleProps {
  className?: string;
  errorMessage?: React.ReactNode;
  onNbrSelected?: (nbr?: Nbr) => void;
  initialNbr?: Nbr;
}

export function NbrPickerInput({
  className,
  onNbrSelected,
  initialNbr,
  ...props
}: NbrPickerInputProps) {
  const { isOn, turnOn, setOpen } = useSwitch(false);
  const [nbr, setNbr] = useState<Nbr | undefined>(initialNbr);
  const { labelProps, fieldProps, errorMessageProps } = useField(props);

  useEffect(() => {
    setNbr(initialNbr);
  }, [initialNbr]);

  const handleUpdateNbr = (nbr?: Nbr) => {
    setNbr(nbr);
    onNbrSelected?.(nbr);
  };

  return (
    <>
      <div className={cn(container(props), className)}>
        <FieldLabel {...labelProps}>{props.label}</FieldLabel>
        <div
          {...fieldProps}
          className={cn(
            input(props),
            "flex items-center justify-between",
            "data-[api-data-empty]:cursor-pointer data-[api-data-empty]:text-gray-6",
          )}
          data-api-data-empty
          onClick={turnOn}
        >
          {nbr?.nome ?? "Não selecionado"}
          {nbr && (
            <Button onPress={() => handleUpdateNbr()}>
              <CloseCircle size="1.125rem" />
            </Button>
          )}
        </div>
        <FieldError {...errorMessageProps}>{props.errorMessage}</FieldError>
      </div>
      <NbrListModal
        isOpen={isOn}
        onOpenChange={setOpen}
        onSelected={handleUpdateNbr}
      />
    </>
  );
}
