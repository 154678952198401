import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";
import { z } from "zod";

import { getDigits } from "@/shared/utils";

export const CreateUserSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  email: z.string().email(),
  telefone: z
    .string()
    .optional()
    .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  cpf: z
    .string()
    .optional()
    .transform((v) => (v ? getDigits(v) : v))
    .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
  senha: z.string().min(6),
  papelId: z.number().int(),
});

export const EditUserSchema = CreateUserSchema.omit({ senha: true });

export type EditUser = z.infer<typeof EditUserSchema>;
export type CreateUser = z.infer<typeof CreateUserSchema>;
