import { ArrowRight2 } from "iconsax-react";
import { Cell, Row } from "react-aria-components";

import {
  AnimatedContainer,
  FilterAndCreate,
  PaginationButtons,
  Table,
} from "@/shared/components";
import { useUsers } from "@/shared/hooks/api";
import { cpfMask, nullishStringMask, phoneMask } from "@/shared/utils/masks";

export function UsersList() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useUsers();

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="usuário"
        onSearchChange={handleSearchChange}
        createAction="create.user"
      />
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Table
            aria-label="Lista contatos"
            headers={["Nome", "Email", "Cpf", "Telefone"]}
            rowsData={data?.content}
            isLoading={isLoading}
            emptyProps={{
              resource: "contato",
              search: filters.text ?? undefined,
            }}
            renderRow={({ id, nome, email, cpf, telefone }) => (
              <Row
                className="group transition-all hover:bg-white-1 data-[href]:cursor-pointer"
                key={id}
                href={`/usuarios/detalhes/${id}/`}
              >
                <Cell>{nome}</Cell>
                <Cell>{email}</Cell>
                <Cell>{nullishStringMask(cpfMask(cpf ?? undefined))}</Cell>
                <Cell>
                  {nullishStringMask(phoneMask(telefone ?? undefined))}
                </Cell>
                <Cell className="!py-4 !pl-0 !pr-6">
                  <ArrowRight2 className="transition-all group-hover:translate-x-1" />
                </Cell>
              </Row>
            )}
          />
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </AnimatedContainer>
  );
}
