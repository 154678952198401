import TipTapImage from "@tiptap/extension-image";
import { ReactNodeViewRenderer } from "@tiptap/react";

import { ResizableImageTemplate } from "./resizable-image-template";

export const ImageResize = TipTapImage.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: { renderHTML: ({ width }) => ({ width }) },
      height: { renderHTML: ({ height }) => ({ height }) },
      align: { default: "mx-auto" },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ResizableImageTemplate);
  },
}).configure({ inline: true });
