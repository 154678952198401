import { Notification } from "iconsax-react";
import {
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  OverlayArrow,
  Separator,
} from "react-aria-components";

import { HeadPopover } from "./header-popover";

export default function NotificationButton() {
  return (
    <DialogTrigger>
      <Button aria-label="Menu">
        <Notification className="origin-top text-primary" variant="Bold" />
      </Button>
      <HeadPopover className="w-80">
        <OverlayArrow>
          <svg className="size-8 rotate-180 fill-white-0" viewBox="0 0 12 12">
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
        <Dialog className="outline-transparent">
          <div className="flex-col">
            <Heading className="p-2 font-semibold text-black-2">
              Notificações
            </Heading>
            <Separator className="border-white-2" />
            <div className="mt-3 text-center text-sm font-semibold text-gray-4">
              Vazio
            </div>
          </div>
        </Dialog>
      </HeadPopover>
    </DialogTrigger>
  );
}
