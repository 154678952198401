import { Cell, Row } from "react-aria-components";

export interface TableSkeletonProps {
  rows: number;
  columns: number;
}

export function TableSkeleton({ rows, columns }: TableSkeletonProps) {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <Row key={i} className="h-16 animate-pulse">
          {Array.from({ length: columns }).map((_, i) => (
            <Cell key={i}>
              <div className="h-4 rounded-lg bg-white-3" />
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
}
