import { ArrowRight2 } from "iconsax-react";
import { Cell } from "react-aria-components";

export function DetailsCell() {
  return (
    <Cell className="!py-4 !pl-0 !pr-6">
      <ArrowRight2 className="transition-all group-hover:translate-x-1" />
    </Cell>
  );
}
