import {
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
} from "react-aria-components";

import { cn } from "@/shared/utils";

import { DialogBody, DialogButtons, DialogHeader } from ".";

export interface DialogProps extends AriaDialogProps {
  title: string;
  renderButtons?: AriaDialogProps["children"];
  onClose?: () => void;
}

export function Dialog({
  className,
  title,
  children,
  renderButtons,
  onClose,
  ...props
}: DialogProps) {
  return (
    <AriaDialog
      className={cn(
        "flex max-h-[calc(100vh-5rem)] flex-col outline-none",
        className,
      )}
      {...props}
    >
      {({ close }) => (
        <>
          {title && (
            <DialogHeader
              onClose={() => {
                close();
                onClose?.();
              }}
            >
              {title}
            </DialogHeader>
          )}
          {children && (
            <DialogBody>
              {typeof children === "function" ? children({ close }) : children}
            </DialogBody>
          )}
          {renderButtons && (
            <DialogButtons>
              {typeof renderButtons === "function"
                ? renderButtons({ close })
                : renderButtons}
            </DialogButtons>
          )}
        </>
      )}
    </AriaDialog>
  );
}
