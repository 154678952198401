import { Breadcrumbs, ErrorContainer, PageTitle } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface RecordNbrDetailErrorProps {
  error: Error;
}

export function RecordNbrDetailError({ error }: RecordNbrDetailErrorProps) {
  return (
    <>
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Erro</PageTitle>
      </div>
      <div className="space-y-4">
        <ErrorContainer
          message={
            error instanceof ApiError && error.status === 404
              ? "NBR não encontrado..."
              : undefined
          }
        />
      </div>
    </>
  );
}
