import { Outlet } from "react-router-dom";

import { useAuthorization } from "@/shared/contexts";

import Drawer from "../components/drawer";

export function PrivateMenuLayout() {
  const { authorized } = useAuthorization();

  return (
    <div className="flex h-[calc(100vh-4.313rem)] w-full flex-1">
      <Drawer />
      <div className="flex-1">
        {authorized ?? true ? <Outlet /> : <p>Não autorizado</p>}
      </div>
    </div>
  );
}
