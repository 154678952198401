interface DevelopmentDataProps {
  manualInterativo?: boolean;
  assistenciaTecnica?: boolean;
  gestaoDeManutencaoPreventiva?: boolean;
}

export function DevelopmentDataModules({
  manualInterativo,
  assistenciaTecnica,
  gestaoDeManutencaoPreventiva,
}: DevelopmentDataProps) {
  return (
    <div className="flex gap-[10px] text-xs">
      {manualInterativo && (
        <span className="rounded bg-primary-light-1 px-4 py-[10px] font-bold text-primary">
          Manual interativo
        </span>
      )}

      {assistenciaTecnica && (
        <span className="rounded bg-primary-light-1 px-4 py-[10px] font-bold text-primary">
          Assistência técnica
        </span>
      )}

      {gestaoDeManutencaoPreventiva && (
        <span className="rounded bg-primary-light-1 px-4 py-[10px] font-bold text-primary">
          Manutenções
        </span>
      )}

      {!manualInterativo &&
        !assistenciaTecnica &&
        !gestaoDeManutencaoPreventiva && (
          <span className="font-bold text-gray-12">
            Nenhum módulo cadastrado
          </span>
        )}
    </div>
  );
}
