import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { Preventivo, apiProvider } from "@/shared/services";

export function useTimeUnit() {
  const timeUnitQuery = useQuery({
    queryKey: ["timeUnit"],
    queryFn: apiProvider.services.UnidadeTempoService.getObjsUnidadeTempoGet,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const findTimeQueryById = useCallback(
    (e?: Preventivo) =>
      timeUnitQuery.data?.find((element) => element.id === e?.unidadeTempoId)
        ?.nome,
    [timeUnitQuery.data],
  );

  return { ...timeUnitQuery, findTimeQueryById };
}
