import { getLocalTimeZone, parseAbsolute } from "@internationalized/date";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

import { DateField, DateFieldProps } from "..";

export interface FormDateFieldProps<T extends FieldValues>
  extends DateFieldProps {
  control: Control<T>;
  name: Path<T>;
  value?: PathValue<T, Path<T>>;
}

export function FormDateField<T extends FieldValues>({
  control,
  name,
  isDisabled,
  ...props
}: FormDateFieldProps<T>) {
  const {
    field: { disabled, onChange, value, ...fieldProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: isDisabled || props.isReadOnly,
  });

  return (
    <DateField
      {...props}
      {...fieldProps}
      value={
        value
          ? parseAbsolute((value as Date).toISOString(), getLocalTimeZone())
          : null
      }
      onChange={(date) =>
        onChange(date ? date.toDate(getLocalTimeZone()) : date)
      }
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
      errorMessage={error?.message}
    />
  );
}
