import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { SystemCreate } from "../schemas";

export async function systemCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<SystemCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      await apiProvider.services.SistemasService.createObjSistemaPost({
        requestBody,
      });

      toastQueue.add(
        { type: "success", message: "Sistema criado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
