import { Tab, TabProps } from "react-aria-components";

import { cn } from "@/shared/utils";

export interface FieldSetNavProps extends TabProps {
  icon: (isSelected?: boolean) => React.ReactNode;
  label: string;
}

export function FieldSetTab({
  icon,
  label,
  className,
  ...props
}: FieldSetNavProps) {
  return (
    <Tab
      className={cn(
        "flex cursor-pointer items-center gap-2 rounded-xl px-4 py-3.5 font-light text-gray-7 outline-none transition-all",
        "rac-selected:translate-x-1 rac-selected:bg-primary/10 rac-selected:font-medium rac-selected:text-primary",
        className,
      )}
      {...props}
    >
      {({ isSelected }) => (
        <>
          {icon(isSelected)} {label}
        </>
      )}
    </Tab>
  );
}
