import {
  ListSkeleton,
  PaginatedList,
  PaginatedListProps,
} from "@/shared/components";
import { Arquivo } from "@/shared/services";
import { cn } from "@/shared/utils";

import { DocumentCard } from ".";

export interface DocumentPaginatedListProps
  extends Omit<
    PaginatedListProps<Arquivo>,
    "renderElement" | "renderLoading" | "emptyProps"
  > {
  search?: string;
  onFolderChange?: (file: Arquivo) => void;
}

export function DocumentsPaginatedList({
  search,
  className,
  onFolderChange,
  ...props
}: DocumentPaginatedListProps) {
  return (
    <PaginatedList
      {...props}
      className={cn("space-y-4", className)}
      renderElement={(e) => (
        <DocumentCard
          key={e.id}
          onClick={(isFile) => {
            if (!isFile) {
              onFolderChange?.(e);
            }
          }}
          file={e}
        />
      )}
      renderLoading={(prevCount) => <ListSkeleton elements={prevCount} />}
      emptyProps={{ resource: "documento", search }}
    />
  );
}
