import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { ColaboradorCreate, Usuario, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationCreateWorkerConfig
  extends Omit<
    UseMutationOptions<Usuario, Error, ColaboradorCreate>,
    "mutationFn"
  > {
  companyId?: number;
}

export function useMutationWorkerCreate(
  options?: UseMutationCreateWorkerConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ColaboradorCreate) =>
      apiProvider.services.ColaboradorService.createColaboradorColaboradorPost({
        requestBody,
      }),
    onSettled: async (_, __, { construtoraId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["companyWorkers", { construtoraId }],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar colaborador: ${e.message}`,
      }),
    ...options,
  });
}
