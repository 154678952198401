import { Add } from "iconsax-react";

import {
  Can,
  Link,
  ListSkeleton,
  PaginatedList,
  SearchInput,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useDevelopments } from "@/shared/hooks/api";

import { DevelopmentCard } from "./development-card";

export function ListDevelopments() {
  const { companyId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useDevelopments({ construtoraId: +companyId, text: "" });
  return (
    <>
      <div className="mb-4 flex gap-4">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <Can I="create.development">
          <Link
            className="flex gap-4 p-3"
            styleType="secondary"
            href="cadastrar/"
          >
            <Add />
            Adicionar empreendimento
          </Link>
        </Can>
      </div>
      <PaginatedList
        className="space-y-4"
        data={data?.content}
        renderElement={(e) => <DevelopmentCard key={e.id} development={e} />}
        isLoading={isLoading}
        renderLoading={(prevCount) => (
          <ListSkeleton className="h-64" elements={prevCount} />
        )}
        emptyProps={{
          resource: "empreendimento",
          search: filters.text ?? undefined,
        }}
        currentPage={filters.page ?? 1}
        pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
        onPageChange={(page) => updateFilters({ page })}
      />
    </>
  );
}
