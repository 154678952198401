import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useDevelopmentStep } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { DevelopmentStepCreateForm } from "../../create/components";

export function DevelopmentStepEdit() {
  const { developmentStepId, developmentId } = useRequiredParams();

  const developmentStepQuery = useDevelopmentStep(developmentStepId);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar etapa</PageTitle>
      </div>
      <QueryHandler
        {...developmentStepQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? `Etapa não encontrada...`
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <DevelopmentStepCreateForm
            redirectTo="../"
            useFormProps={{
              defaultValues: {
                empreendimentoId: +developmentId,
                nome: data?.nome,
                percentagem: data?.percentagem,
              },
            }}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
