import { MaintenanceCommonSchema } from "@/shared/modules/system/modules/detail/modules/subsystem/modules/create/schemas";
import { z } from "@/shared/utils";

export const MaintenanceCreateSchema = z.object({
  ...MaintenanceCommonSchema.shape,
  subsistemaId: z.number().int().nullish(),
  produtoId: z.number().int().nullish(),
});

export type MaintenanceCreate = z.infer<typeof MaintenanceCommonSchema>;
