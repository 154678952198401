import { Copy } from "iconsax-react";
import { useMemo } from "react";

import { AnimatedContainer } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { copyString } from "@/shared/utils";

import { ManualQrCode } from "../components";

export function ManualLink() {
  const { developmentId, manualId } = useRequiredParams();

  const url = useMemo(() => {
    const url = new URL(import.meta.env.VITE_CLIENT_URL);
    url.search = new URLSearchParams({
      m: manualId,
      d: developmentId,
    }).toString();

    return url.toString();
  }, [developmentId, manualId]);

  return (
    <AnimatedContainer className="space-y-4">
      <div className="flex flex-col items-center justify-center gap-4 p-4">
        <button
          className="flex cursor-pointer items-center gap-1 text-xs font-bold text-info"
          onClick={copyString(url)}
        >
          <Copy size="1rem" /> Clique aqui para copiar
        </button>
        <ManualQrCode url={url} />
      </div>
    </AnimatedContainer>
  );
}
