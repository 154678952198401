import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "../utils";

interface CircularProgressProps extends PropsWithChildren {
  percentage: number;
  strokeWidth?: number;
  className?: string;
}

export function CircularProgress({
  percentage,
  strokeWidth = 10,
  className,
  children,
}: CircularProgressProps) {
  const [parentHeight, setParentHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight;
      setParentHeight(height);
    }
  }, [className]);

  const radius = Math.max(parentHeight / 2 - strokeWidth / 2, 0);
  const circumference = 2 * Math.PI * radius;
  const dashOffset = Math.max(
    circumference - (percentage / 100) * circumference,
    0,
  );

  return (
    <div ref={containerRef} className={cn("relative", className)}>
      <svg
        className="absolute left-0 top-0"
        width={parentHeight}
        height={parentHeight}
      >
        <circle
          className="stroke-gray-1"
          cx={parentHeight / 2}
          cy={parentHeight / 2}
          r={radius}
          strokeWidth={2}
          fill="none"
        />
        <circle
          className="stroke-primary"
          cx={parentHeight / 2}
          cy={parentHeight / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: dashOffset,
            strokeLinecap: "round",
            transform: `rotate(-90deg)`,
            transformOrigin: "center center",
            transition: `stroke-dashoffset 250ms ease-in-out`,
          }}
        />
        <text
          className="text-xs text-black-2"
          x={parentHeight / 2}
          y={parentHeight / 2}
          textAnchor="middle"
          dy="0.3em"
        >
          {children}
        </text>
      </svg>
    </div>
  );
}
