import { Row, RowProps } from "react-aria-components";

import { cn } from "../utils";

export interface TableRowProps<T extends object> extends RowProps<T> {}

export function TableRow<T extends object>({
  className,
  ...props
}: TableRowProps<T>) {
  return (
    <Row
      className={cn(
        "group transition-all hover:bg-white-1 data-[href]:cursor-pointer",
        className,
      )}
      {...props}
    />
  );
}
