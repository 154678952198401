import { useQuery } from "@tanstack/react-query";

import { ibge } from "@/shared/services";

export function useStateCities(state?: string) {
  return useQuery({
    queryKey: ["state", state],
    queryFn: async () => {
      if (!state) {
        return;
      }
      return await ibge.getStateCities(state);
    },
    enabled: !!state,
    staleTime: Infinity,
    gcTime: state ? Infinity : 0,
  });
}
