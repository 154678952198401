import { useParams } from "react-router-dom";

import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { SystemCreateForm } from "../components";

export function SystemCreate() {
  const { manualId } = useParams();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar sistema</PageTitle>
      </div>
      <SystemCreateForm
        redirectTo="../"
        useFormProps={{
          defaultValues: {
            manualId: manualId ? +manualId : undefined,
          },
        }}
      />
    </AnimatedContainer>
  );
}
