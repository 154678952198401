import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Post, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationAddManualPostProps
  extends Omit<
    UseMutationOptions<Post, Error, AddManualPostPayload>,
    "mutationFn"
  > {}

export interface AddManualPostPayload {
  postId: number;
  manualId: number;
}

export function useMutationAddManualPost(
  options?: UseMutationAddManualPostProps,
) {
  return useMutation({
    mutationFn: async ({ postId, manualId }: AddManualPostPayload) => {
      const { nbr, ...post } =
        await apiProvider.services.PostsService.getOneObjPostIdGet({
          id: postId,
        });
      return await apiProvider.services.PostsService.createObjPostPost({
        requestBody: { manualId, nbrId: nbr?.id, ...post },
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["posts"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar post no manual: ${e.message}`,
      }),
    ...options,
  });
}
