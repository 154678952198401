import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { FormProps as AriaFormProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";
import { useActionData, useSubmit } from "react-router-dom";
import { ZodTypeAny, z } from "zod";

import { usePaginated } from "@/shared/hooks/use-paginated";

import { Card } from "../card";
import { CircularProgress } from "../circular-progress";
import { PageStepButtons } from "../page-step-buttons";
import { FormCore } from "./form-core";

export interface FormWizardProps<TSchema extends ZodTypeAny> {
  onSuccessRedirect: string;
  formSchema: TSchema;
  submitSchema?: ZodTypeAny;
  steps: {
    heading: {
      head: string;
      subHead: string;
    };
    element: React.ReactNode;
  }[];
  useFormProps?: UseFormProps<z.infer<TSchema>>;
}

export function FormWizard<TSchema extends ZodTypeAny>({
  steps,
  formSchema,
  submitSchema,
  onSuccessRedirect,
  useFormProps: { defaultValues, ...useFormProps } = {},
}: FormWizardProps<TSchema>) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const { page, next, previous, isFirstPage, isLastPage } = usePaginated({
    pageCount: steps.length,
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { _formStep: 0, ...defaultValues },
    ...useFormProps,
  });
  const { handleSubmit, getValues } = form;
  const submit = useSubmit();

  const currentStep = useMemo(() => steps[page - 1], [page, steps]);

  useEffect(() => {
    form.setValue("_formStep", page - 1);
  }, [form, page]);

  const handleSub = handleSubmit(() => {
    if (page === steps.length) {
      const { _formStep, ...formValues } = getValues();
      let values = formValues;

      if (submitSchema) {
        values = submitSchema.parse(values);
      }

      submit(
        { ...values, redirectTo: onSuccessRedirect },
        {
          method: "post",
          encType: "application/json",
          action: location.pathname,
        },
      );
    }

    next();
  });

  return (
    <>
      <Card className="mb-4 flex gap-5">
        <CircularProgress
          className="size-16"
          percentage={(page / steps.length) * 100}
        >
          {page} de {steps.length}
        </CircularProgress>
        <div className="flex flex-col justify-center">
          <p className="font-semibold text-black-2">
            {currentStep.heading.head}
          </p>
          <p className="text-sm text-gray-10">{currentStep.heading.subHead}</p>
        </div>
      </Card>
      <FormCore form={form} onSubmit={handleSub} validationErrors={formErrors}>
        {currentStep.element}
      </FormCore>
      <PageStepButtons
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        onPrevious={previous}
        onNext={handleSub}
        onSubmit={handleSub}
      />
    </>
  );
}
