import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { MaintenanceCreate } from "../../create/schemas";

export async function maintenanceEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<MaintenanceCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      const maintenanceId = +params.maintenanceId!;

      await apiProvider.services.PreventivosService.updateObjPreventivoIdPut({
        id: maintenanceId,
        requestBody,
      });

      toastQueue.add(
        {
          type: "success",
          message: "Preventivo atualizado com sucesso",
        },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
