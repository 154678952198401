import { VariantProps, tv } from "tailwind-variants";

import { cn } from "@/shared/utils";

import { Skeleton } from ".";

const container = tv({
  base: "rounded-full",
  variants: {
    size: {
      normal: "size-24",
      small: "size-10",
      extraSmall: "size-9",
    },
  },
  defaultVariants: {
    size: "normal",
  },
});

export interface ResourceProfileImageSkeletonProps
  extends VariantProps<typeof container> {
  className?: string;
}

export function ResourceProfileImageSkeleton({
  className,
  ...props
}: ResourceProfileImageSkeletonProps) {
  return <Skeleton className={cn(container(props), className)} />;
}
