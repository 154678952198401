import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormFileInput,
  FormSelectClientTypeInput,
  FormTextInput,
  Modal,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";

import { ManualCreate, ManualCreateSchema } from "../schemas";

interface ManualFormModalProps extends Omit<ModalOverlayProps, "children"> {
  onSubmit: (values: ManualCreate, close: () => void) => Promise<void>;
  useFormProps?: Omit<UseFormProps<ManualCreate>, "resolver">;
}

export function ManualFormModal({
  onSubmit,
  useFormProps,
  ...props
}: ManualFormModalProps) {
  const [isSubmitting, setisSubmitting] = useState(false);

  const { formState, handleSubmit, control, reset } = useForm<ManualCreate>({
    resolver: zodResolver(ManualCreateSchema),
    ...useFormProps,
  });

  useEffect(() => {
    (async () => {
      if (useFormProps?.defaultValues) {
        reset(
          useFormProps.defaultValues instanceof Function
            ? await useFormProps.defaultValues()
            : useFormProps.defaultValues,
        );
      }
    })();
  }, [reset, useFormProps]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar manual"
        data-cy="manual-form-modal"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              onPress={async () => {
                setisSubmitting(true);
                await handleSubmit((values) => onSubmit(values, close))();
                setisSubmitting(false);
              }}
              showSpinner={isSubmitting}
            >
              Salvar
            </Button>
          </>
        )}
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 p-1">
          <FormTextInput control={control} label="Nome" name="nome" />
          <FormSelectClientTypeInput control={control} name="tipoClienteId" />
          <FormTextInput
            control={control}
            className="col-span-2"
            label="Descrição"
            name="descricao"
          />
          <FormFileInput
            control={control}
            label="Manual"
            name="link"
            maxFileSize={100 * megabyte}
          />
        </form>
      </Dialog>
    </Modal>
  );
}
