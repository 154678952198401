import { CepApiResponse } from "./cep-api-interface";

export interface ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export interface ViaCepResponseError {
  erro: "true";
}

export async function getCepInfo(cep: string): Promise<CepApiResponse> {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = (await response.json()) as ViaCepResponse | ViaCepResponseError;

  if ("erro" in data) {
    throw new Error("Cep not found");
  }

  return {
    cep: data.cep,
    state: data.uf,
    city: data.localidade,
    neighborhood: data.bairro,
    street: data.logradouro,
  } satisfies CepApiResponse;
}
