import { useParams } from "react-router-dom";

import { Am, AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { usePosts } from "@/shared/hooks/api";

import { PostModalAssociate, PostPaginatedList } from "../components";

export function PostList() {
  const { manualId } = useParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    usePosts({ manualId: manualId ? +manualId : 0, text: "" });

  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <Am I="SeuManual">
      {(isSuperUser) => (
        <AnimatedContainer className="space-y-4">
          <FilterAndCreate
            resourceName="post"
            onSearchChange={handleSearchChange}
            createOnPress={manualId && isSuperUser ? turnOn : undefined}
            createAction="create.post"
          />
          <PostPaginatedList
            data={data?.content}
            isLoading={isLoading}
            search={filters.text ?? undefined}
            currentPage={filters.page ?? 1}
            pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
            onPageChange={(page) => updateFilters({ page })}
          />
          {manualId && (
            <PostModalAssociate
              manualId={+manualId}
              isOpen={isOn}
              onOpenChange={setOpen}
              onSuccess={turnOff}
            />
          )}
        </AnimatedContainer>
      )}
    </Am>
  );
}
