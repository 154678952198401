import { BaseTabs, Handle, UIMatch, useMatches } from "react-router-dom";

import { ensureSlashed } from "../utils";

export function useTabBaseUrl(key: BaseTabs) {
  const matches = useMatches() as UIMatch<unknown, Handle>[];

  const baseUrl = matches.find(
    (match) => match.handle?.tabBaseUrl === key,
  )!.pathname;

  return ensureSlashed(baseUrl);
}
