import { FormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  FormFooterButtons,
  SaveDataButton,
} from "@/modules/profile/components";
import {
  AnimatedContainer,
  Card,
  ErrorContainer,
  FieldSetTitle,
  Form,
  FormTextInput,
  Spinner,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { useProfile } from "@/shared/hooks/api";

import { ProfilePasswordSchema } from "../schemas";

export function ProfilePassword() {
  const formErrors = useActionData() as
    | FormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  const profile = useProfile();

  if (profile.isLoading) {
    return <Spinner className="mx-auto" />;
  }

  if (profile.error) {
    return (
      <div className="space-y-4">
        <ErrorContainer />
      </div>
    );
  }

  if (!profile.data) {
    return <p>Dados indisponíveis</p>;
  }

  return (
    <Form
      className="flex gap-4"
      schema={ProfilePasswordSchema}
      autoComplete="on"
      validationErrors={formErrors}
    >
      {({ control }) => (
        <>
          <AnimatedContainer className="mb-20 mt-0 flex-1">
            <Card className="w-full p-8">
              <FieldSetTitle>Informações da conta</FieldSetTitle>
              <div className="space-y-4">
                <input type="hidden" name="email" value={profile.data.email} />
                <FormTextInput
                  control={control}
                  label="Senha atual"
                  name="currentPassword"
                  autoComplete="current-password"
                  isSecret
                />
                <FormTextInput
                  control={control}
                  label="Nova senha"
                  name="newPassword"
                  autoComplete="new-password"
                  isSecret
                />
                <FormTextInput
                  control={control}
                  label="Repita a nova senha"
                  name="confirmNewPassword"
                  autoComplete="new-password"
                  isSecret
                />
              </div>
            </Card>
          </AnimatedContainer>
          <FormFooterButtons>
            <SaveDataButton type="submit" showSpinner={isSubmitting} />
          </FormFooterButtons>
        </>
      )}
    </Form>
  );
}
