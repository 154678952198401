import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";

import { AddProductModal } from ".";
import { SubsystemCreate } from "../schemas";

interface FormProductListProps {
  className?: string;
  control: Control<SubsystemCreate>;
}

export function FormProductList({ control, ...props }: FormProductListProps) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <FormListInput
      {...props}
      control={control}
      name="produtos"
      onAddPress={turnOn}
      resourceOptions={{
        word: "produto",
        pluralRadical: "s",
        isMasculine: true,
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.marca + e.nome + e.modelo}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          <AddProductModal
            isOpen={isOn}
            onOpenChange={setOpen}
            onAdd={(value) => {
              update([...values, value]);
              turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
