import { forwardRef } from "react";
import {
  DateField as AriaDateField,
  DateFieldProps as AriaDateFieldProps,
  DateValue,
  Label,
  ValidationResult,
} from "react-aria-components";

import { cn } from "@/shared/utils";

import { DateInput } from ".";
import { FieldError } from "../..";
import {
  InputStyleProps,
  container,
  label as labelStyle,
} from "../style/input";

export interface DateFieldProps<T extends DateValue = DateValue>
  extends AriaDateFieldProps<T>,
    InputStyleProps {
  label: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export const DateField = forwardRef(
  <T extends DateValue>(
    { label, errorMessage, className, ...props }: DateFieldProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <AriaDateField {...props} className={cn(container(props), className)}>
        <Label className={labelStyle(props)}>{label}</Label>
        <DateInput ref={ref} />
        <FieldError>{errorMessage}</FieldError>
      </AriaDateField>
    );
  },
);
