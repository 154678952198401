import { ActionFunctionArgs, redirect } from "react-router-dom";

import { SigninPayload, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function loginAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<
    SigninPayload & { redirectTo: string | null; keepAuthenticated?: boolean }
  >(request, async ({ redirectTo, keepAuthenticated, ...payload }) => {
    await apiProvider.signin(payload, keepAuthenticated);

    return redirect(redirectTo || "/app");
  });
}
