import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface SubsystemFaqDetailErrorProps {
  error: Error;
}

export function SubsystemFaqDetailError({
  error,
}: SubsystemFaqDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? `FAQ não encontrado...`
          : undefined
      }
    />
  );
}
