import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useNbr(nbrId?: string) {
  return useQuery({
    queryKey: ["nbr", nbrId],
    queryFn: async () => {
      if (!nbrId) {
        return;
      }
      return apiProvider.services.NbrsService.getOneObjNbrIdGet({ id: +nbrId });
    },
    enabled: !!nbrId,
  });
}
