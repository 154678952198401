import { useFormContext } from "react-hook-form";

import { LocationFieldset } from "@/shared/components";

import { CompanyAddress } from "../schemas";

export function CompanyLocationForm() {
  const { control } = useFormContext<CompanyAddress>();

  return (
    <LocationFieldset
      control={control}
      names={{
        cep: "cep",
        state: "estado",
        city: "cidade",
        neighborhood: "bairro",
        street: "rua",
        number: "numero",
        complement: "complemento",
      }}
    />
  );
}
