import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useContact } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { cepMask, phoneMask } from "@/shared/utils/masks";

import { ContactCreateFormWizard } from "../../create/components/contact-create-form-wizard";

export function EditContact() {
  const { contactId } = useRequiredParams();

  const contactQuery = useContact(contactId);

  const getDefaultValues = (data: typeof contactQuery.data) => {
    if (!data) {
      return undefined;
    }
    const { logo, telefone, tipoContatoId, cep, ...values } = data;
    return {
      defaultValues: {
        ...values,
        cep: cep ? cepMask(cep) : undefined,
        telefone: telefone ? phoneMask(telefone) : undefined,
        tipo: tipoContatoId,
        logo: logo
          ? [
              {
                fileName: logo,
                key: logo,
              },
            ]
          : undefined,
      },
    };
  };

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar contato</PageTitle>
        <QueryHandler
          {...contactQuery}
          renderLoading={() => <Spinner />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Contato não encontrado..."
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <ContactCreateFormWizard useFormProps={getDefaultValues(data)} />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
