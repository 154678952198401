import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useFaq(id: string) {
  return useQuery({
    queryKey: ["faq", id],
    queryFn: async () => {
      return apiProvider.services.FaqsService.getOneObjFaqIdGet({ id: +id });
    },
    enabled: !!id,
  });
}
