import { useEffect } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

export interface SetFieldValueProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  value: PathValue<T, Path<T>>;
}

export function SetFieldValue<T extends FieldValues>({
  control,
  name,
  value,
}: SetFieldValueProps<T>) {
  const {
    field: { onChange },
  } = useController({ name, control });
  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return null;
}
