import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { WarrantyFailureCommonSchema } from "@/shared/modules/warranty/containers";
import { z } from "@/shared/utils";

import { AddWarrantyFailureModal } from ".";

const WarrantyFailureContainerSchema = z.object({
  falhas: z.array(WarrantyFailureCommonSchema).default([]),
});

type WarrantyFailureContainer = z.infer<typeof WarrantyFailureContainerSchema>;

interface FormWarrantyFailureListProps<T extends WarrantyFailureContainer> {
  className?: string;
  control: Control<T>;
}

export function FormWarrantyFailureList<T extends WarrantyFailureContainer>({
  control,
  ...props
}: FormWarrantyFailureListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<WarrantyFailureContainer>;

  return (
    <FormListInput
      {...props}
      control={_control}
      name="falhas"
      onAddPress={turnOn}
      resourceOptions={{
        word: "falha",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          <AddWarrantyFailureModal
            isOpen={isOn}
            onOpenChange={setOpen}
            onAdd={(value) => {
              update([...values, value]);
              turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
