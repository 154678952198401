import { z } from "@/shared/utils";

export const ManualCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  empreendimentoId: z.number().int(),
  tipoClienteId: z.number().int(),
  link: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
});

export type ManualCreate = z.infer<typeof ManualCreateSchema>;
