import { InfoCircle } from "iconsax-react";

export interface CollectionEmptyProps {
  resource: string;
  isResourceFeminine?: boolean;
  search?: string;
}

export function CollectionEmpty({
  resource,
  isResourceFeminine,
  search,
}: CollectionEmptyProps) {
  return (
    <div className="my-10 flex flex-col items-center gap-2 text-gray-8">
      <InfoCircle className="animate-bounce-up repeat-1" />
      <p className="text-sm italic">
        {"Nenhum" + (isResourceFeminine ? "a" : "")} {resource}{" "}
        {search && `com '${search}'`}{" "}
        {"encontrad" + (isResourceFeminine ? "a" : "o")}
      </p>
    </div>
  );
}
