import { tv } from "tailwind-variants";

import { focusRing } from "@/shared/utils/focus-ring";

export const itemStyles = tv({
  extend: focusRing,
  base: "group relative flex cursor-default select-none items-center gap-8 rounded-md px-2.5 py-1.5 text-sm will-change-transform forced-color-adjust-none",
  variants: {
    isSelected: {
      false: "-outline-offset-2 rac-hover:bg-gray-2",
      true: "bg-primary text-white-0 -outline-offset-4 outline-white-0 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] forced-colors:outline-[HighlightText] [&+[data-selected]]:rounded-t-none [&:has(+[data-selected])]:rounded-b-none",
    },
    isDisabled: {
      true: "text-gray-5 forced-colors:text-[GrayText]",
    },
  },
});

export const dropdownItemStyles = tv({
  base: "group flex cursor-default select-none items-center gap-4 rounded-md py-2 pl-3 pr-1 text-sm outline outline-0 forced-color-adjust-none",
  variants: {
    isDisabled: {
      true: "text-gray-2 forced-colors:text-[GrayText]",
    },
    isFocused: {
      true: "bg-gray-1 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]",
    },
  },
});
