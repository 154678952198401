import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import {
  Content,
  EditorContent,
  EditorProviderProps,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { cn } from "@/shared/utils";

import { ImageResize } from "./image-resize";
import { Toolbar } from "./toolbar";

export interface TiptapProps extends Omit<EditorProviderProps, "children"> {
  className?: string;
  content?: Content;
  onChange?: (text: string) => void;
}

export function Tiptap({
  className,
  content,
  onChange,
  ...props
}: TiptapProps) {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          HTMLAttributes: {
            class: "text-h5 font-semibold",
          },
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        HTMLAttributes: {
          class: "text-info underline cursor-pointer",
        },
      }),
      Underline,
      ImageResize.configure({
        allowBase64: true,
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    content,
    editorProps: {
      attributes: { class: cn("outline-none flex-1", className) ?? "" },
    },
    onUpdate({ editor }) {
      const value = editor.isEmpty ? "" : editor.getHTML();
      onChange?.(value);
    },
    ...props,
  });

  return (
    <div className="flex min-h-48 flex-col rounded-xl shadow-header">
      <Toolbar editor={editor} />
      <EditorContent
        className="rich-text-editor flex max-h-96 flex-1 overflow-y-scroll rounded-b-xl p-4"
        editor={editor}
      />
    </div>
  );
}
