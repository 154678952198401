import { useFormContext } from "react-hook-form";

import { Card, FieldSetTitle } from "@/shared/components";

import { DevelopmentModuleData } from "../schemas";
import { ModuleCheckBoxGroup } from "./module-checkbox-group";

export function DevelopmentModuleDataForm() {
  const { control } = useFormContext<DevelopmentModuleData>();

  return (
    <Card className="mb-4 p-6">
      <FieldSetTitle>Dados dos módulos</FieldSetTitle>
      <ModuleCheckBoxGroup control={control} />
    </Card>
  );
}
