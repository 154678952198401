import {
  Tabs as AriaTabs,
  TabsProps as AriaTabsProps,
  Tab,
  TabList,
  TabProps,
} from "react-aria-components";

import { cn } from "../utils";

export interface TabsProps extends AriaTabsProps {
  tabs: ({ id: string; label: string } & TabProps)[];
  children?: React.ReactNode;
}

export function Tabs({ className, tabs, children, ...props }: TabsProps) {
  return (
    <AriaTabs {...props}>
      <TabList
        className={cn(
          "flex gap-6 rounded-lg bg-white-0 px-4 pt-2 text-sm shadow-[6px_6px_54px_0_rgba(0,0,0,0.05)]",
          className,
        )}
      >
        {tabs.map((t) => (
          <Tab
            className="group relative cursor-default pb-1 text-gray-8 outline-none rac-selected:font-semibold rac-selected:text-black-2"
            key={t.id}
            {...t}
          >
            <span className="px-2 ">{t.label}</span>
            <div
              className={cn(
                "absolute bottom-0 left-1/2 right-1/2 h-[2px] w-0 transition-[width,left,right]",
                "group-rac-hover:left-0 group-rac-hover:right-full group-rac-hover:w-full group-rac-hover:bg-gray-2",
                "group-rac-selected:left-0 group-rac-selected:right-full group-rac-selected:w-full group-rac-selected:bg-primary",
              )}
            />
          </Tab>
        ))}
      </TabList>
      {children}
    </AriaTabs>
  );
}
