import { VariantProps, tv } from "tailwind-variants";

import { S3Image, S3ImageProps } from ".";
import { cn } from "../utils";

const container = tv({
  base: "flex items-center justify-center overflow-clip rounded-full bg-primary-light-2 text-primary-light-4",
  variants: {
    size: {
      normal: "size-24 [&>svg]:size-12",
      small: "size-10",
      extraSmall: "size-9",
    },
  },
  defaultVariants: {
    size: "normal",
  },
});

export interface ResourceProfileImageProps
  extends S3ImageProps,
    VariantProps<typeof container> {}

export function ResourceProfileImage({
  file,
  className,
  emptyIcon,
  ...props
}: ResourceProfileImageProps) {
  return (
    <div className={cn(container(props), className)}>
      <S3Image
        className={(type) =>
          cn({
            "object-contain": type === "img",
            "h-full w-full": type !== "empty",
          })
        }
        file={file}
        emptyIcon={emptyIcon}
      />
    </div>
  );
}
