import { ArrowDown3, ArrowUp3, InfoCircle } from "iconsax-react";
import {
  Button,
  ButtonProps,
  FieldError,
  Group,
  Input,
  Label,
  NumberField,
  NumberFieldProps,
  ValidationResult,
} from "react-aria-components";

import { TextTooltip } from "@/shared/components";
import { cn } from "@/shared/utils";

import {
  InputStyleProps,
  container,
  input,
  label as labelStyle,
} from "./style/input";

export interface NumberInputProps extends NumberFieldProps, InputStyleProps {
  label?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  inputRef?: React.Ref<HTMLInputElement>;
  tooltip?: string;
}

export function NumberInput({
  className,
  label,
  errorMessage,
  inputRef,
  tooltip,
  ...props
}: NumberInputProps) {
  return (
    <NumberField
      {...props}
      className={cn(container(props), className, "group")}
    >
      <div className="flex items-center gap-1">
        <Label className={labelStyle(props)}>{label}</Label>
        {tooltip && (
          <TextTooltip text={tooltip}>
            <InfoCircle className="text-gray-9" size="1rem" />
          </TextTooltip>
        )}
      </div>
      <Group className={cn(input(props), "flex p-0")}>
        <StepperButton slot="increment">
          <ArrowUp3 aria-hidden size="1rem" />
        </StepperButton>
        <Input
          className="flex-1 bg-transparent py-3"
          ref={inputRef}
          data-cy={label}
        />
        <StepperButton slot="decrement">
          <ArrowDown3 aria-hidden className="h-4 w-4" />
        </StepperButton>
      </Group>
      <FieldError className="text-xs font-semibold text-error">
        {errorMessage}
      </FieldError>
    </NumberField>
  );
}

function StepperButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      {...props}
      className={cn(
        "cursor-default rounded-xl px-4 py-3 text-black-0 rac-pressed:bg-gray-1 group-rac-disabled:text-gray-7",
        className,
      )}
    />
  );
}
