import { useQuery } from "@tanstack/react-query";

import { viaCepApi } from "@/shared/services";

export function useCep(cep: string) {
  return useQuery({
    queryKey: ["cep", cep],
    queryFn: async () => {
      return await viaCepApi.getCepInfo(cep);
    },
    enabled: cep.length === 9,
    staleTime: Infinity,
    gcTime: cep.length === 9 ? Infinity : 0,
  });
}
