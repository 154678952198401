import { ArrowRight2 } from "iconsax-react";
import { Cell, Row } from "react-aria-components";

import { AnimatedContainer, Table } from "@/shared/components";
import { useAcls } from "@/shared/hooks/api";

export function AclList() {
  const [{ data, isLoading }] = useAcls();

  return (
    <AnimatedContainer className="space-y-4">
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Table
            aria-label="Lista niveis de acesso"
            headers={["Nível de acesso"]}
            rowsData={data}
            isLoading={isLoading}
            emptyProps={{
              resource: "nivel de acesso",
            }}
            renderRow={({ id, nome }) => (
              <Row
                className="group transition-all hover:bg-white-1 data-[href]:cursor-pointer"
                key={id}
                href={`detalhes/${id}/`}
              >
                <Cell>{nome}</Cell>
                <Cell className="!py-4 !pl-0 !pr-6">
                  <ArrowRight2 className="transition-all group-hover:translate-x-1" />
                </Cell>
              </Row>
            )}
          />
        </div>
      </div>
    </AnimatedContainer>
  );
}
