import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { apiProvider } from "@/shared/services";

export function useClientType() {
  const clientTypeQuery = useQuery({
    queryKey: ["clientType"],
    queryFn: apiProvider.services.TiposClientesService.getObjsTiposClientesGet,
  });

  const findClientById = useCallback(
    (id?: number) => clientTypeQuery.data?.find((e) => id === e.id)?.nome,
    [clientTypeQuery.data],
  );

  return { ...clientTypeQuery, findClientById };
}
