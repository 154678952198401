import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormProps,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import { WarrantyEditSchema } from "../../create/schemas";

interface WarrantyEditFormProps
  extends Omit<
    FormProps<typeof WarrantyEditSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function WarrantyEditForm({
  redirectTo,
  ...formProps
}: WarrantyEditFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={WarrantyEditSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={redirectTo} />
            <div className="space-y-4">
              <FormTextInput control={control} label="Título" name="nome" />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
