import {
  Card,
  ColorInputSkeleton,
  FieldSetTitle,
  ResourceProfileImageSkeleton,
} from "@/shared/components";

export function AppearanceSkeleton() {
  return (
    <Card className="space-y-6 p-6">
      <div>
        <FieldSetTitle>Favicon</FieldSetTitle>
        <ResourceProfileImageSkeleton />
      </div>
      <div>
        <FieldSetTitle>Cores principais</FieldSetTitle>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <ColorInputSkeleton />
          <ColorInputSkeleton />
        </div>
      </div>
      <div>
        <FieldSetTitle>Cores do rodapé</FieldSetTitle>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <ColorInputSkeleton />
          <ColorInputSkeleton />
          <ColorInputSkeleton />
          <ColorInputSkeleton />
        </div>
      </div>
    </Card>
  );
}
