import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormRichTextInput,
  FormTextInput,
  Modal,
} from "@/shared/components";

import { FaqCreate, FaqCreateSchema } from "../schemas";

interface AddFaqModalProps extends Omit<ModalOverlayProps, "children"> {
  onAdd: (value: FaqCreate) => void;
  useFormProps?: UseFormProps<FaqCreate>;
}

export function AddFaqModal({
  onAdd,
  useFormProps,
  ...props
}: AddFaqModalProps) {
  const { formState, handleSubmit, control, reset } = useForm<FaqCreate>({
    resolver: zodResolver(FaqCreateSchema),
    ...useFormProps,
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar faq"
        data-cy="faq-modal"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button className="px-7 py-3" onPress={() => handleSubmit(onAdd)()}>
              Adicionar
            </Button>
          </>
        )}
      >
        <form className="grid gap-x-4 gap-y-6 px-1 pb-4">
          <FormTextInput control={control} label="Título" name="nome" />
          <FormRichTextInput
            control={control}
            label="Descrição"
            name="descricao"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
