import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import { Button, Dialog, FormTextInput, Modal } from "@/shared/components";
import {
  WarrantyCommonSchema,
  WarrantyCreate,
} from "@/shared/modules/warranty/modules/create/schemas";

import { FormWarrantyFailureList } from ".";

interface AddWarrantyModalProps extends Omit<ModalOverlayProps, "children"> {
  onAdd: (value: WarrantyCreate) => void;
  useFormProps?: UseFormProps<WarrantyCreate>;
}

export function AddWarrantyModal({
  onAdd,
  useFormProps,
  ...props
}: AddWarrantyModalProps) {
  const { formState, handleSubmit, control, reset } = useForm<WarrantyCreate>({
    resolver: zodResolver(WarrantyCommonSchema),
    ...useFormProps,
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar garantia"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button className="px-7 py-3" onPress={() => handleSubmit(onAdd)()}>
              Adicionar
            </Button>
          </>
        )}
        data-cy="warranty-modal"
      >
        <form className="grid gap-x-4 gap-y-6 px-1 pb-4">
          <FormTextInput control={control} label="Título" name="nome" />
          <FormWarrantyFailureList control={control} />
        </form>
      </Dialog>
    </Modal>
  );
}
