import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsTopicoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseTopicsFilters = GetObjsTopicoGetData;

export function useTopics(initial: UseTopicsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseTopicsFilters>(initial);

  const query = useQuery({
    queryKey: ["topics", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.TopicosService.getObjsTopicoGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
