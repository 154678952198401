export interface BrCity {
  id: number;
  nome: string;
}

export async function getStateCities(state: string) {
  const response = await fetch(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = (await response.json()) as BrCity[];
  return data;
}
