import { z } from "@/shared/utils";

export const WarrantyFailureCommonSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  prazo: z.number().int().min(1),
  unidadeTempoId: z.number().int(),
});

export type WarrantyFailureCommon = z.infer<typeof WarrantyFailureCommonSchema>;

export const WarrantyFailureCreateSchema = z.object({
  ...WarrantyFailureCommonSchema.shape,
  garantiaId: z.number().int(),
});

export type WarrantyFailureCreate = z.infer<typeof WarrantyFailureCreateSchema>;
