import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFailure, useMutationFailureUpdate } from "@/shared/hooks/api";
import { expirationString } from "@/shared/utils";

import { FailureDetailError, FailureDetailLoading } from "../components";

export function FailureDetail() {
  const { failureId } = useRequiredParams();
  const failureQuery = useFailure(failureId);
  const mutationFailureUpdate = useMutationFailureUpdate();

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto p-8">
      <Breadcrumbs />
      <PageTitle primary className="py-4">
        Detalhes da falha
      </PageTitle>
      <QueryHandler
        {...failureQuery}
        renderLoading={FailureDetailLoading}
        renderError={(error) => <FailureDetailError error={error} />}
      >
        {(data) => (
          <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.nome}</PageTitle>
                <Can I="update.failure">
                  <EditLink />
                </Can>
              </div>
              <Can I="update.failure">
                <SwitchStatusButton
                  isDisabled={mutationFailureUpdate.isPending}
                  isSelected={data?.flagAtivo}
                  onChange={(status) =>
                    mutationFailureUpdate.mutateAsync({
                      id: +failureId,
                      requestBody: { flagAtivo: status },
                    })
                  }
                />
              </Can>
            </div>
            <Separator />
            <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
              {expirationString({
                frequency: data?.prazo,
                timeUnit: data?.unidadeTempo.nome,
              })}
            </div>
          </Card>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
