import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Key } from "react-aria-components";
import { FieldValues } from "react-hook-form";
import { useIntersectionObserver } from "usehooks-ts";

import {
  FormComboBoxInput,
  FormComboBoxInputProps,
  InputItem,
} from "@/shared/components";
import { getPageCountFromTotal } from "@/shared/utils";

export interface FormComboBoxApiResourceInputProps<
  T extends object,
  TFieldValues extends FieldValues,
  TContent,
  TFilters,
> extends FormComboBoxInputProps<T, TFieldValues> {
  queryKey: string[];
  service: (
    data: {
      pageParam: number;
      inputText: string;
    },
    extraFilters?: Partial<TFilters>,
  ) => Promise<{ page: number; content: Array<TContent>; total: number }>;
  mapElementsKey: (element: TContent) => { id: Key; text: string };
  filters?: Partial<TFilters>;
  initialPageParam?: number;
}
export function FormComboBoxApiResourceInput<
  T extends object,
  TFieldValues extends FieldValues,
  TContent,
  TFilters,
>({
  isDisabled,
  queryKey,
  service,
  mapElementsKey,
  filters,
  initialPageParam = 0,
  ...props
}: FormComboBoxApiResourceInputProps<T, TFieldValues, TContent, TFilters>) {
  const [inputText, setInputText] = useState("");
  const debouncedInputText = useDebounce(inputText, 300);

  const { data, isLoading, isError, fetchNextPage } = useInfiniteQuery({
    queryKey: [...queryKey, { ...filters, debouncedInputText }],
    initialPageParam,
    queryFn: async ({ pageParam }) =>
      await service({ pageParam, inputText: debouncedInputText }, filters),
    getNextPageParam: (lastPage, _) =>
      getPageCountFromTotal(lastPage.total) > lastPage.page
        ? lastPage.page + 1
        : undefined,
    placeholderData: (previousData) => previousData,
  });

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, isIntersecting]);

  const _elements = data?.pages.flatMap((p) => p.content) ?? [];
  const _mapElementsKey = _elements.map(mapElementsKey);

  return (
    <FormComboBoxInput
      {...props}
      isDisabled={isDisabled || isLoading || isError}
      inputValue={inputText}
      onInputChange={setInputText}
      onSelectionChange={(k) => {
        const keyText = _mapElementsKey.find((o) => o.id === k)?.text;

        if (!props.allowsCustomValue) {
          return setInputText(keyText ?? "");
        }

        if (keyText) {
          setInputText(keyText);
        }
      }}
    >
      {_mapElementsKey.map((element, i) => (
        <InputItem
          key={element.id}
          id={element.id}
          ref={i === _mapElementsKey.length - 1 ? ref : null}
        >
          {element.text}
        </InputItem>
      ))}
    </FormComboBoxInput>
  );
}
