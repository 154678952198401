import { TickCircle } from "iconsax-react";
import {
  MenuItem as AriaMenuItem,
  MenuItemProps,
  composeRenderProps,
} from "react-aria-components";

import { dropdownItemStyles } from "../list-box";

export function MenuItem(props: MenuItemProps) {
  return (
    <AriaMenuItem {...props} className={dropdownItemStyles}>
      {composeRenderProps(
        props.children,
        (children, { selectionMode, isSelected }) => (
          <>
            {selectionMode !== "none" && (
              <span className="flex w-4 items-center">
                {isSelected && <TickCircle aria-hidden className="h-4 w-4" />}
              </span>
            )}
            <span className="flex flex-1 items-center gap-2 truncate font-normal group-rac-selected:font-semibold">
              {children}
            </span>
          </>
        ),
      )}
    </AriaMenuItem>
  );
}
