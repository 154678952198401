import { useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormFileInput,
  FormTextInput,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";
import { cnpjMask, cpfMask, phoneMask } from "@/shared/utils/masks";

import { CompanyInfo } from "../schemas";
import { FormCompanyCategoryInput } from "./form-company-category-input";

export interface CompanyInfoFormProps {
  isEditing?: boolean;
}

export function CompanyInfoForm({ isEditing }: CompanyInfoFormProps) {
  const { control } = useFormContext<CompanyInfo>();

  return (
    <>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Dados da construtora</FieldSetTitle>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <FormTextInput
            control={control}
            label="Nome da construtora"
            name="nome"
          />
          <FormTextInput
            control={control}
            mask={cnpjMask}
            label="CNPJ"
            name="cnpj"
          />
          <FormTextInput control={control} label="E-mail" name="email" />
          <FormTextInput control={control} label="Site" name="site" />
          <FormCompanyCategoryInput
            control={control}
            label="Categoria"
            name="categoriaConstrutoraId"
          />
          <FormFileInput
            className="col-span-2"
            control={control}
            name="foto"
            label="Icone"
            acceptedFileTypes={["image/png", "image/jpeg"]}
            maxFileSize={20 * megabyte}
          />
        </div>
      </Card>
      {!isEditing && (
        <Card className="mb-4 p-6">
          <FieldSetTitle>Responsável</FieldSetTitle>
          <div className="grid grid-cols-2 gap-x-4 gap-y-6">
            <FormTextInput
              control={control}
              label="Nome"
              name="responsavel.nome"
            />
            <FormTextInput
              control={control}
              mask={cpfMask}
              label="CPF"
              name="responsavel.cpf"
            />
            <FormTextInput
              control={control}
              label="E-mail"
              name="responsavel.email"
            />
            <FormTextInput
              control={control}
              mask={phoneMask}
              label="Telefone"
              name="responsavel.telefone"
            />
          </div>
        </Card>
      )}
    </>
  );
}
