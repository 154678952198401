import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { EtapaCreate, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function developmentStepCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<EtapaCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      await apiProvider.services.EtapasService.createObjEtapaPost({
        requestBody: payload,
      });

      toastQueue.add(
        { type: "success", message: "Etapa criada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
