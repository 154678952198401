import { useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormDateField,
  FormTextInput,
  LocationFieldset,
} from "@/shared/components";
import { cnpjMask } from "@/shared/utils/masks";

import { DevelopmentData } from "../schemas";
import { FormDevelopmentCategoryInput } from "./form-development-category-input";

export function DevelopmentDataForm() {
  const { control } = useFormContext<DevelopmentData>();

  return (
    <>
      <Card className="mb-4 p-6">
        <FieldSetTitle>Dados do empreendimento</FieldSetTitle>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <FormTextInput
            control={control}
            label="Nome do empreendimento"
            name="nome"
          />
          <FormTextInput
            control={control}
            mask={cnpjMask}
            label="CNPJ"
            name="cnpj"
          />
          <FormTextInput control={control} label="E-mail" name="email" />
          <FormDevelopmentCategoryInput
            control={control}
            label="Categoria"
            name="padraoConstrutivoId"
          />
          <FormDateField
            className="col-span-2"
            control={control}
            name="dataEntrega"
            label="Data de entrega"
            granularity="day"
          />
        </div>
      </Card>
      <LocationFieldset
        control={control}
        names={{
          cep: "cep",
          state: "estado",
          city: "cidade",
          neighborhood: "bairro",
          street: "rua",
          number: "numero",
          complement: "complemento",
        }}
      />
    </>
  );
}
