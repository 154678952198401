import { useState } from "react";
import { Checkbox } from "react-aria-components";

import "./checkbox-input.css";

interface CheckboxInputProps extends React.ComponentProps<typeof Checkbox> {
  children: React.ReactNode;
}

export function CheckboxInput({
  children,
  ...checkboxProps
}: CheckboxInputProps) {
  const [selected, setSelection] = useState(
    checkboxProps.defaultSelected ?? false,
  );

  return (
    <Checkbox {...checkboxProps} isSelected={selected} onChange={setSelection}>
      <div className="checkbox">
        <svg viewBox="0 0 18 18" aria-hidden="true">
          <polyline points="1 9 7 14 15 4" />
        </svg>
      </div>
      {children}
    </Checkbox>
  );
}
