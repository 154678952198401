import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { Bloco } from "@/shared/services";

export interface BlockTableProps
  extends Pick<TableProps<Bloco>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function BlockTable({ pagination, search, ...props }: BlockTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.block">
          {(allowed) => (
            <Table
              {...props}
              aria-label="Lista blocos"
              headers={["Nome", "Status"]}
              emptyProps={{ resource: "bloco", search }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, nome, flagAtivo }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{nome}</Cell>
                  <ActiveFlagCell active={flagAtivo} />
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
