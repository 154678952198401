import { Trash } from "iconsax-react";
import { Button as AriaButton } from "react-aria-components";

export interface ListInputElementProps extends React.PropsWithChildren {
  onDelete?: () => void;
}

export function ListInputElement({
  onDelete,
  children,
}: ListInputElementProps) {
  return (
    <div className="flex justify-between border-b border-white-3 p-3 text-sm font-semibold text-black-2">
      <span>{children}</span>
      <AriaButton onPress={onDelete}>
        <Trash className="text-error" variant="Bold" />
      </AriaButton>
    </div>
  );
}
