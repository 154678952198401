import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

import { ListInput, ListInputProps } from "@/shared/components";

export interface FormListInputProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TValue extends PathValue<TFieldValues, TName>,
> extends Omit<ListInputProps<TValue[]>, "values" | "children"> {
  control: Control<TFieldValues>;
  name: TName;
  children?: (
    values: TValue,
    update: (newValues: TValue) => void,
  ) => React.ReactNode;
}

export function FormListInput<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TValue extends PathValue<TFieldValues, TName>,
>({
  name,
  control,
  children,
  onAddPress,
  ...props
}: FormListInputProps<TFieldValues, TName, TValue>) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: [] as PathValue<TFieldValues, TName>,
  });

  return (
    <>
      <ListInput
        values={value}
        onAddPress={() => {
          onBlur();
          onAddPress?.();
        }}
        {...props}
      >
        {(values) => children?.(values as TValue, onChange)}
      </ListInput>
      {error && (
        <small className="text-xs font-semibold text-error">
          {error?.message}
        </small>
      )}
    </>
  );
}
