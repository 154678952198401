import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { Falha } from "@/shared/services";

export interface FailureTableProps
  extends Pick<TableProps<Falha>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function FailureTable({
  pagination,
  search,
  ...props
}: FailureTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.failure">
          {(allowed) => (
            <Table
              {...props}
              aria-label="falhas"
              headers={["Nome", "Status"]}
              emptyProps={{ resource: "falha", search }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, nome, flagAtivo }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{nome}</Cell>
                  <ActiveFlagCell active={flagAtivo} />
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
