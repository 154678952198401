import { Fragment, cloneElement, useRef } from "react";
import { AriaButtonOptions, mergeProps, useButton } from "react-aria";
import {
  TooltipTrigger,
  TooltipTriggerComponentProps,
} from "react-aria-components";

import { Tooltip } from ".";

function TriggerWrapperWithoutDiv(
  props: AriaButtonOptions<"button"> & { children: React.ReactElement },
) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, triggerRef);

  return cloneElement(
    props.children,
    mergeProps(buttonProps, props.children.props, { ref: triggerRef }),
  );
}

export interface TextTooltipProps extends TooltipTriggerComponentProps {
  text: string;
  children: React.ReactElement;
}

export function TextTooltip({ text, children, ...props }: TextTooltipProps) {
  return (
    <TooltipTrigger delay={250} closeDelay={100} {...props}>
      <TriggerWrapperWithoutDiv>{children}</TriggerWrapperWithoutDiv>
      <Tooltip>
        {text.split("\n").map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
      </Tooltip>
    </TooltipTrigger>
  );
}
