import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsUnidadesGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseApartmentsFilters = GetObjsUnidadesGetData;

export function useApartments(initial: UseApartmentsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseApartmentsFilters>(initial);
  const query = useQuery({
    queryKey: ["apartments", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.UnidadesService.getObjsUnidadesGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
