import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsMemorialGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseMemorialsFilters = GetObjsMemorialGetData;

export function useMemorials(initial: UseMemorialsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseMemorialsFilters>(initial);

  const query = useQuery({
    queryKey: ["memorials", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.MemoriaisService.getObjsMemorialGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
