import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  Manual,
  UpdateObjManualIdPutData,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationManualUpdateConfig
  extends Omit<
    UseMutationOptions<Manual, Error, UpdateObjManualIdPutData>,
    "mutationFn"
  > {}

export function useMutationManualUpdate(
  options?: UseMutationManualUpdateConfig,
) {
  return useMutation({
    mutationFn: apiProvider.services.ManuaisService.updateObjManualIdPut,
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["manual", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar manual: ${e.message}`,
      }),
    ...options,
  });
}
