import { Breadcrumbs, Skeleton } from "@/shared/components";

export function PostDetailLoading() {
  return (
    <>
      <div className="space-y-4">
        <Breadcrumbs />
        <Skeleton className="h-9 w-48" />
      </div>
      <div className="space-y-4">
        <Skeleton className="w-96" />
        <Skeleton />
      </div>
    </>
  );
}
