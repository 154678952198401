import { FieldValues } from "react-hook-form";

import {
  FormRadioGroupInput,
  FormRadioGroupInputProps,
  Radio,
} from "@/shared/components";

export interface FormTargetAudienceInputProps<T extends FieldValues>
  extends Omit<FormRadioGroupInputProps<T>, "label" | "children"> {}

export function FormTargetAudienceInput<T extends FieldValues>(
  props: FormTargetAudienceInputProps<T>,
) {
  return (
    <FormRadioGroupInput {...props} label="Público alvo">
      <div className="flex gap-6">
        <Radio value="cliente">Cliente</Radio>
        <Radio value="construtora">Construtora</Radio>
      </div>
    </FormRadioGroupInput>
  );
}
