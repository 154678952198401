import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface WarrantyDetailErrorProps {
  error: Error;
}

export function WarrantyDetailError({ error }: WarrantyDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? `Garantia não encontrada...`
          : undefined
      }
    />
  );
}
