import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormProps,
  FormRootError,
  FormSelectWorkerTypeInput,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { WorkerCreateSchema } from "../../../schemas";

interface WorkerEditFormProps
  extends Omit<
    FormProps<typeof WorkerCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function WorkerEditForm({
  redirectTo,
  ...formProps
}: WorkerEditFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmiting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={WorkerCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormSelectWorkerTypeInput control={control} name="papelId" />

              <FormTextInput control={control} label="Nome" name="nome" />
              <FormTextInput
                control={control}
                label="Email"
                name="email"
                type="email"
              />
              <FormTextInput
                control={control}
                mask={cpfMask}
                label="CPF"
                name="cpf"
              />
              <FormTextInput
                control={control}
                mask={phoneMask}
                label="Telefone"
                name="telefone"
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmiting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
