import { get } from "lodash";

import { TabsProps } from "../components";
import { useAuthorization } from "../contexts";
import { permissions } from "../utils";
import { useProfile } from "./api";

export type AuthorizedTabsProps = ({
  action?: AuthActions;
  role?: RoleType;
} & TabsProps["tabs"][number])[];

export function useAuthorizedTabs(tabs: AuthorizedTabsProps) {
  const { ability } = useAuthorization();
  const { data } = useProfile();

  return tabs.reduce(
    (acc, { action, role, ...tab }) => {
      const isAuthorized = !action || ability.can(get(permissions, action));
      const hasRole = !role || role === data?.papel.tipo;

      if (isAuthorized && hasRole) {
        acc.push(tab);
      }
      return acc;
    },
    [] as TabsProps["tabs"],
  );
}
