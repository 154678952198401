import {
  FieldError as AriaFieldError,
  FieldErrorProps as AriaFieldErrorProps,
} from "react-aria-components";

import { cn } from "@/shared/utils";

export interface FieldErrorProps extends AriaFieldErrorProps {}

export function FieldError({ className, children, ...props }: FieldErrorProps) {
  return (
    <AriaFieldError
      {...props}
      className={cn("text-xs font-semibold text-error", className)}
    >
      {children}
    </AriaFieldError>
  );
}
