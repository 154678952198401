import { cn } from "../utils";

export interface ListSkeletonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  elements: number;
}

export function ListSkeleton({
  elements,
  className,
  ...props
}: ListSkeletonProps) {
  return (
    <>
      {Array.from({ length: elements }).map((_, i) => (
        <div
          key={i}
          {...props}
          className={cn("h-32 animate-pulse rounded-lg bg-gray-1", className)}
        />
      ))}
    </>
  );
}
