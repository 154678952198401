import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { Faq } from "@/shared/services";
import { sanitize } from "@/shared/utils";
import { dateTimeMask } from "@/shared/utils/masks";

export interface FaqTableProps
  extends Pick<TableProps<Faq>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function FaqTable({ pagination, search, ...props }: FaqTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.faq">
          {(allowed) => (
            <Table
              {...props}
              aria-label="Perguntas"
              headers={["Pergunta", "Resposta", "Data de criação", "Status"]}
              emptyProps={{ resource: "faq", search }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, nome, descricao, criadoEm, flagAtivo }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{nome}</Cell>
                  <Cell className="max-w-48">
                    <p
                      className="line-clamp-1 overflow-hidden text-ellipsis"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(descricao, true),
                      }}
                    />
                  </Cell>
                  <Cell>{dateTimeMask(criadoEm)}</Cell>
                  <ActiveFlagCell active={flagAtivo} />
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
