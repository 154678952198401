import { useCallback, useState } from "react";
import { Control, FieldValues, Path, useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormBrCepInput,
  FormBrCityInput,
  FormBrStateInput,
  FormTextInput,
} from "@/shared/components";

import { CepApiResponse } from "../services/cep-api-interface";

interface LocationFormProps<T extends FieldValues> {
  control: Control<T>;
  names: {
    cep: Path<T>;
    state: Path<T>;
    city: Path<T>;
    neighborhood: Path<T>;
    street: Path<T>;
    number: Path<T>;
    complement: Path<T>;
  };
}

export function LocationFieldset<T extends FieldValues>({
  control,
  names,
}: LocationFormProps<T>) {
  const [cepFound, setCepFound] = useState(false);
  const { setValue, watch } = useFormContext();
  const watchedField = watch(names.state);

  const handleCepDataChanged = useCallback(
    (data?: CepApiResponse | null) => {
      if (data) {
        // HACK: 'as string' is needed, without it the second parameter never matches
        setValue(names.state as string, data.state);
        setValue(names.city as string, data.city);
        setValue(names.street as string, data.street);
        setValue(names.neighborhood as string, data.neighborhood);
        setCepFound(true);
        return;
      }
      setCepFound(false);
    },
    [names.city, names.neighborhood, names.state, names.street, setValue],
  );

  return (
    <Card className="p-6">
      <FieldSetTitle>Dados da localização</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormBrCepInput
          control={control}
          label="CEP"
          name={names.cep}
          onCepDataChanged={handleCepDataChanged}
        />
        <FormBrStateInput
          isReadOnly={cepFound}
          control={control}
          label="Estado"
          name={names.state}
        />
        <FormBrCityInput
          isReadOnly={cepFound}
          control={control}
          label="Cidade"
          name={names.city}
          state={watchedField}
        />
        <FormTextInput
          isReadOnly={cepFound}
          control={control}
          label="Bairro"
          name={names.neighborhood}
        />
        <FormTextInput
          isReadOnly={cepFound}
          control={control}
          label="Rua"
          name={names.street}
        />
        <FormTextInput control={control} label="Número" name={names.number} />
        <FormTextInput
          control={control}
          label="Complemento"
          name={names.complement}
        />
      </div>
    </Card>
  );
}
