import { FieldValues } from "react-hook-form";

import { PapelWithOutDates, apiProvider } from "@/shared/services";

import {
  FormSelectApiResourceInput,
  FormSelectApiResourceInputProps,
} from "..";

export type FormSelectWorkerTypeInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormSelectApiResourceInputProps<T, TFieldValues, PapelWithOutDates>,
  "service" | "queryKey" | "label" | "mapElements"
>;

export function FormSelectWorkerTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormSelectWorkerTypeInputProps<T, TFieldValues>) {
  return (
    <FormSelectApiResourceInput
      label="Cargo"
      queryKey={["workerType"]}
      service={
        apiProvider.services.PapeisService
          .getPapeisConstrutoraPapeisTiposPapeisConstrutoraGet
      }
      mapElements={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
