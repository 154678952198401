import { useState } from "react";

interface usePaginatedParams {
  pageCount: number;
}

export function usePaginated({ pageCount }: usePaginatedParams) {
  const [page, setPage] = useState(1);

  function next() {
    setPage((p) => Math.min(p + 1, pageCount));
  }

  function previous() {
    setPage((p) => Math.max(p - 1, 1));
  }

  return {
    pageCount,
    page,
    next,
    previous,
    isFirstPage: page === 1,
    isLastPage: page === pageCount,
    setPage,
  };
}
