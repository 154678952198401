import { WarrantyCommonSchema } from "@/shared/modules/warranty/modules/create/schemas";
import { z } from "@/shared/utils";

export const MaintenanceCommonSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  descricao: z.string().trim().nullish(),
  frequencia: z.number().int(),
  unidadeTempoId: z.number().int(),
  nbrId: z.number().int().nullish(),
  tipoClienteId: z.number().int(),
});

export type MaintenanceCommon = z.infer<typeof MaintenanceCommonSchema>;

export const FaqCreateSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  descricao: z.string().min(1, "Obrigatório").trim(),
});

export type FaqCreate = z.infer<typeof FaqCreateSchema>;

export const ProductCreateSchema = z.object({
  marca: z.string().trim().min(1, "Obrigatório"),
  nome: z.string().trim().min(1, "Obrigatório"),
  modelo: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().optional(),
  linkManualExterno: z
    .string()
    .startsWith("https://", "Precisa começar com https://")
    .url()
    .trim()
    .optional(),
  especificacaoTecnica: z.string().trim().optional(),
  notaFiscal: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .default([]),
  garantias: z.array(WarrantyCommonSchema).default([]),
  manutencoes: z.array(MaintenanceCommonSchema).default([]),
});

export type ProductCreate = z.infer<typeof ProductCreateSchema>;

export const SubsystemCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  descricao: z.string().trim().nullish(),
  memorialId: z.number().int().nullish(),
  especificacoes: z.string().trim().nullish(),
  sistemaId: z.number().int(),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  produtos: z.array(ProductCreateSchema).default([]),
  garantias: z.array(WarrantyCommonSchema).default([]),
  manutencoes: z.array(MaintenanceCommonSchema).default([]),
  faqs: z.array(FaqCreateSchema).default([]),
});

export type SubsystemCreate = z.infer<typeof SubsystemCreateSchema>;
