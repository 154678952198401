import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface FailureDetailErrorProps {
  error: Error;
}

export function FailureDetailError({ error }: FailureDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? `Falha não encontrada...`
          : undefined
      }
    />
  );
}
