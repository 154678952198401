import { Key } from "iconsax-react";

import {
  Breadcrumbs,
  Card,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Separator,
  Skeleton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useAcl } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { AclSessionList } from ".";

export function AclDetail() {
  const { aclId } = useRequiredParams();
  const aclQuery = useAcl(aclId);

  function renderLoading() {
    return (
      <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div className="flex size-24 animate-pulse items-center justify-center rounded-full bg-gray-1 text-xs text-gray-10" />
            <div className="flex flex-col justify-center">
              <Skeleton className="my-2 h-4 w-24" />
            </div>
          </div>
        </div>
        <Separator />
        <div className="grid auto-rows-[1fr] grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] gap-4">
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
          <Skeleton className="h-80 w-full" />
        </div>
      </Card>
    );
  }

  function renderError(error: Error) {
    return (
      <ErrorContainer
        message={
          error instanceof ApiError && error.status === 404
            ? "Nível de acesso não encontrado..."
            : undefined
        }
      />
    );
  }

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle>Detalhes do nível de acesso</PageTitle>
      </div>
      <div className="space-y-4">
        <QueryHandler
          {...aclQuery}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {(data) => (
            <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
              <div className="flex justify-between">
                <div className="flex items-center gap-4">
                  <div className="flex size-24 items-center justify-center rounded-full bg-primary-light-2 text-primary-light-4">
                    <Key variant="Bold" size="3rem" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <p className="black-2 font-bold">{data?.nome}</p>
                  </div>
                </div>
              </div>
              <Separator />
              <div className="grid auto-rows-[1fr] grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] gap-4">
                <AclSessionList
                  name="Usuários"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("usuarios"),
                  )}
                />
                <AclSessionList
                  name="Categorias de construtora"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("categorias-construtora"),
                  )}
                />
                <AclSessionList
                  name="Construtoras"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("construtoras"),
                  )}
                />
                <AclSessionList
                  name="Papeis"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("papeis"),
                  )}
                />
                <AclSessionList
                  name="Padrões construtivos"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("padroes-construtivos"),
                  )}
                />
                <AclSessionList
                  name="Empreendimentos"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("empreendimentos"),
                  )}
                />
                <AclSessionList
                  name="Blocos"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("blocos"),
                  )}
                />
                <AclSessionList
                  name="Unidades"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("unidades"),
                  )}
                />
                <AclSessionList
                  name="Tipos de clientes"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("tipos-clientes"),
                  )}
                />
                <AclSessionList
                  name="Arquivos"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("files"),
                  )}
                />
                <AclSessionList
                  name="Manual"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("manual"),
                  )}
                />
                <AclSessionList
                  name="Posts do manual"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("post"),
                  )}
                />
                <AclSessionList
                  name="NBR"
                  permissions={data?.permissoes.filter((e) =>
                    e.startsWith("nbr"),
                  )}
                />
              </div>
            </Card>
          )}
        </QueryHandler>
      </div>
    </div>
  );
}
