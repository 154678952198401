import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useApartment(id: string) {
  return useQuery({
    queryKey: ["apartment", id],
    queryFn: async () => {
      return apiProvider.services.UnidadesService.getOneObjUnidadesIdGet({
        id: +id,
      });
    },
    enabled: !!id,
  });
}
