import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { UpdateObjBlocosIdPutData, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export type BlockUpdatePayload = UpdateObjBlocosIdPutData;

export function useMutationBlockUpdate() {
  return useMutation({
    mutationFn: (payload: BlockUpdatePayload) => {
      return apiProvider.services.BlocosService.updateObjBlocosIdPut(payload);
    },
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["block", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar bloco: ${e.message}`,
      }),
  });
}
