import { Button, Dialog, Modal } from "@/shared/components";
import { useMutationDocumentDelete } from "@/shared/hooks/api";

export interface DocumentDeleteModalProps {
  isFile?: boolean;
  id: number;
  name: string;
}

export function DocumentDeleteModal({
  id,
  isFile,
  name,
}: DocumentDeleteModalProps) {
  const deleteDocumentMutation = useMutationDocumentDelete();

  return (
    <Modal>
      <Dialog
        role="alertdialog"
        title={`Apagar ${isFile ? "arquivo" : "pasta"} '${name}'`}
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button
              className="px-7 py-3"
              color="error"
              onPress={async () => {
                await deleteDocumentMutation.mutateAsync({ id });
                close();
              }}
              showSpinner={deleteDocumentMutation.isPending}
            >
              Excluir
            </Button>
          </>
        )}
      >
        <p className="font-medium">Essa ação é permanente, deseja continuar?</p>
      </Dialog>
    </Modal>
  );
}
