import { AxiosError } from "axios";
import { FormProps } from "react-aria-components";
import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "../components/toast";
import { ApiError, apiProvider } from "../services";
import { capitalizeString } from "./capitalize-string";

export type HandleActionReturnType = Promise<
  FormProps["validationErrors"] | Response | null
>;

export async function handleAction<T>(
  request: ActionFunctionArgs["request"],
  service: (payload: T) => HandleActionReturnType,
): HandleActionReturnType {
  const payload = await request.json();

  try {
    return await service(payload);
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.status >= 400 && error.status < 500) {
        if (error.status === 401) {
          toastQueue.add(
            { type: "error", message: "Sessão expirada" },
            {
              timeout: 5000,
            },
          );

          apiProvider.removeAuthToken();
          return redirect(`/login?from=${new URL(request.url).pathname}`);
        }

        const validationErrors: ValidationErrorResponse = (
          error.body as { errors: ValidationErrorResponse }
        ).errors;

        if (validationErrors.length === 0) {
          toastQueue.add({
            type: "error",
            message: "Erro ao enviar, cheque novamente",
          });
        } else {
          for (const { message } of validationErrors) {
            toastQueue.add({
              type: "error",
              message: capitalizeString(message),
            });
          }
        }

        return validationErrors.reduce(
          (acc, fieldError) => ({
            ...acc,
            [fieldError.field]: fieldError.message,
          }),
          {},
        );
      }

      if (error.status >= 500) {
        return { "": "Erro interno no servidor" };
      }
    }

    console.error(error);
    if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
      return { "": "Servidor fora do ar" };
    }

    return { "": "Erro desconhecido" };
  }
}
