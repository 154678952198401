import type { AriaToastProps } from "@react-aria/toast";
import { useToast } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { CloseCircle, TickCircle } from "iconsax-react";
import { useRef } from "react";
import { Button } from "react-aria-components";
import { tv } from "tailwind-variants";

import { ToastPayload } from ".";

interface ToastProps extends AriaToastProps<ToastPayload> {
  state: ToastState<ToastPayload>;
}

type ToastIcons = {
  [key in ToastPayload["type"]]: React.ReactElement;
};

const toast = tv({
  base: "flex max-w-[40vw] items-center gap-4 rounded-md p-5 font-medium text-white-0 animate-in fade-in slide-in-from-left-1",
  variants: {
    type: {
      success: "bg-success",
      error: "bg-error",
    },
  },
});

export function Toast({ state, ...props }: ToastProps) {
  const ref = useRef(null);
  const { toastProps, titleProps, closeButtonProps } = useToast(
    props,
    state,
    ref,
  );

  const toastIcons: ToastIcons = {
    success: <TickCircle variant="Bold" />,
    error: <CloseCircle variant="Bold" />,
  };

  return (
    <div
      {...toastProps}
      ref={ref}
      className={toast({ type: props.toast.content.type })}
    >
      {toastIcons[props.toast.content.type]}
      <span {...titleProps} className="flex-1">
        {props.toast.content.message}
      </span>
      <Button {...closeButtonProps} className="ml-4">
        <CloseCircle variant="Outline" />
      </Button>
    </div>
  );
}
