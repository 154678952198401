import { Cell } from "react-aria-components";

import {
  ActiveFlagCell,
  AnimatedContainer,
  Can,
  DetailsCell,
  FilterAndCreate,
  PaginationButtons,
  Table,
  TableRow,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useClients } from "@/shared/hooks/api";

export function ClientsList() {
  const { developmentId } = useRequiredParams();

  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useClients({
      empreendimentoId: +developmentId,
      text: "",
    });

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="cliente"
        onSearchChange={handleSearchChange}
        createAction="create.client"
      />
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Can I="get.client">
            {(allowed) => (
              <Table
                aria-label="Lista clientes"
                headers={["Código", "Nome", "E-mail", "Status"]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "cliente",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell>{data.id}</Cell>
                    <Cell>{data.nome}</Cell>
                    <Cell>{data.email}</Cell>
                    <ActiveFlagCell active={data.flagAtivo} />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </AnimatedContainer>
  );
}
