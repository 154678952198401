import { Label, LabelProps } from "react-aria-components";

import { cn } from "@/shared/utils";

import { InputStyleProps, label } from "../inputs/style/input";

export interface FieldLabelProps extends LabelProps, InputStyleProps {}

export function FieldLabel({ className, children, ...props }: FieldLabelProps) {
  return (
    <Label {...props} className={cn(label(props), className)}>
      {children}
    </Label>
  );
}
