import { useFile } from "../hooks/api";

export interface VideoPlayerProps
  extends React.HTMLAttributes<HTMLVideoElement> {
  url?: string | null;
}
export function VideoPlayer({ url, ...props }: VideoPlayerProps) {
  const fileQuery = useFile(url);

  if (fileQuery.data) {
    return (
      <video controls {...props}>
        <source src={fileQuery.data.url} type="video/mp4" />
      </video>
    );
  }
}
