import { saveAs } from "file-saver";

import { Button, ButtonProps, Spinner } from ".";
import { useMutationFilesZip } from "../hooks/api";

export interface DownloadFilesProps extends ButtonProps {
  files?: string[];
  zipName?: string;
}

export function DownloadFiles({
  files,
  zipName,
  children,
  ...props
}: DownloadFilesProps) {
  const filesDownloadMutation = useMutationFilesZip({
    onSuccess: (zip) => {
      saveAs(
        zip,
        zipName
          ? zipName.replace(/[^a-z0-9]/gi, "_").toLowerCase()
          : "arquivos.zip",
      );
    },
  });

  return (
    <Button
      {...props}
      onPress={() => files && filesDownloadMutation.mutateAsync(files)}
    >
      {filesDownloadMutation.isPending ? <Spinner /> : children}
    </Button>
  );
}
