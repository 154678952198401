import { Cell } from "react-aria-components";

import { cn } from "../utils";

export function ActiveFlagCell({ active }: { active?: boolean }) {
  return (
    <Cell>
      <p
        className={cn(
          "inline-block rounded-lg px-5 py-1.5 text-sm font-semibold",
          {
            "bg-success/15 text-success": active,
            "bg-error/15 text-error": !active,
          },
        )}
      >
        {active ? "Ativo" : "Inativo"}
      </p>
    </Cell>
  );
}
