import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFailures } from "@/shared/hooks/api";

import { FailureTable } from "../components";

export function FailureList() {
  const { warrantyId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useFailures({ garantiaId: +warrantyId, text: "" });

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="falha"
        onSearchChange={handleSearchChange}
        createAction="create.failure"
      />
      <FailureTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
