import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsFaqGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseFaqFilters = GetObjsFaqGetData;

export function useFaqs(initial: UseFaqFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseFaqFilters>(initial);

  const query = useQuery({
    queryKey: ["faqs", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.FaqsService.getObjsFaqGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
