import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useConstructionStandards() {
  return useQuery({
    queryKey: ["constructionStandards"],
    queryFn:
      apiProvider.services.PadroesConstrutivosService
        .getObjsPadroesConstrutivosGet,
  });
}
