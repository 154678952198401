import { isMobilePhone, isTaxID } from "validator";

import { getDigits, z } from "@/shared/utils";

export const WorkerCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  email: z.string().email(),
  cpf: z
    .string()
    .optional()
    .transform((v) => (v ? getDigits(v) : v))
    .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
  telefone: z
    .string()
    .optional()
    .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  construtoraId: z.number().int(),
  papelId: z.number().int(),
});

export type WorkerCreate = z.infer<typeof WorkerCreateSchema>;
