import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useMaintenance(maintenanceId: string) {
  return useQuery({
    queryKey: ["maintenance", maintenanceId],
    queryFn: () =>
      apiProvider.services.PreventivosService.getOneObjPreventivoIdGet({
        id: +maintenanceId,
      }),
    enabled: !!maintenanceId,
  });
}
