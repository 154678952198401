import { useColorField } from "@react-aria/color";
import { type ColorFieldProps, useColorFieldState } from "@react-stately/color";
import { useRef } from "react";

import { cn } from "@/shared/utils";

export interface ColorInputProps extends ColorFieldProps {
  className?: string;
  errorMessage?: string;
  name?: string;
}

export function ColorInput({
  className,
  errorMessage,
  ...props
}: ColorInputProps) {
  const state = useColorFieldState(props);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { labelProps, inputProps } = useColorField(props, state, inputRef);

  const descriptionId = inputProps["aria-describedby"];

  return (
    <div className={cn("flex flex-col gap-2 text-sm", className)}>
      <label {...labelProps} className="font-semibold text-gray-12">
        {props.label}
      </label>
      <div className="flex gap-3">
        <input
          {...inputProps}
          className="flex-1 rounded-xl border border-transparent bg-white-2 px-4 py-3 focus:border focus:border-primary focus:outline focus:outline-[3px] focus:outline-primary-light-2"
          ref={inputRef}
        />
        <div
          className="w-12 rounded-xl border border-white-3"
          style={{ background: state.colorValue?.toString("css") }}
        />
      </div>
      <small className="text-xs font-semibold text-error" id={descriptionId}>
        {errorMessage}
      </small>
    </div>
  );
}
