import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { z } from "@/shared/utils";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { FaqCreateSchema } from "../../create/components";

export async function faqEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<z.infer<typeof FaqCreateSchema> & { redirectTo: string }>(
    request,
    async ({ redirectTo, publicoAlvo, videoUrl, ...payload }) => {
      const faqId = +params.faqId!;
      await apiProvider.services.FaqsService.updateObjFaqIdPut({
        id: faqId,
        requestBody: {
          seCliente: publicoAlvo === "cliente",
          seConstrutora: publicoAlvo === "construtora",
          videoUrl: videoUrl ? videoUrl?.[0].key : null,
          ...payload,
        },
      });

      toastQueue.add(
        { type: "success", message: "FAQ atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
