import { z } from "@/shared/utils";

export const SystemCreateSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  descricao: z.string().trim().nullish(),
  manualId: z.number().int().nullish(),
});

export type SystemCreate = z.infer<typeof SystemCreateSchema>;
