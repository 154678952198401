import { Gallery } from "iconsax-react";

import { cn } from "@/shared/utils";

import { S3Image } from ".";

interface FileTriggerContentProps {
  file?: { key: string; fileName: string }[];
  isImage: boolean;
}

export function FileTriggerContent({ file, isImage }: FileTriggerContentProps) {
  if (!file) {
    return (
      <>
        <Gallery
          className="group-rac-drop-target:animate-bounce-up"
          variant="Bold"
        />
        <p>
          Arraste ou <span className="font-bold underline">procure</span>
        </p>
      </>
    );
  }

  if (isImage) {
    return (
      <S3Image
        className={(type) =>
          cn({
            "object-contain": type === "img",
            "h-4/5 w-4/5": type !== "empty",
          })
        }
        file={file[0].key}
        emptyIcon="default"
      />
    );
  }
}
