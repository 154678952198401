import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { Breadcrumb, Breadcrumbs, Button } from "react-aria-components";

import { Arquivo } from "@/shared/services";

export interface DocumentPathProps {
  onPathChange?: (id: number) => void;
  path: Arquivo[];
}

export function DocumentPath({ onPathChange, path }: DocumentPathProps) {
  return (
    <div className="flex justify-between">
      <Breadcrumbs className="flex items-center gap-2 text-sm text-gray-8">
        <Breadcrumb className="flex items-center gap-2">
          <Button onPress={() => onPathChange?.(0)}>Raiz</Button>
          {path.length > 0 && <ArrowRight2 className="text-gray-8" size={10} />}
        </Breadcrumb>
        {path.map((folder, i) => (
          <Breadcrumb className="flex items-center gap-2" key={folder.id}>
            <Button onPress={() => onPathChange?.(i + 1)}>{folder.nome}</Button>
            {i !== path.length - 1 && (
              <ArrowRight2 className="text-gray-8" size={10} />
            )}
          </Breadcrumb>
        ))}
      </Breadcrumbs>

      {path.length > 0 && (
        <Button
          className="flex items-center justify-center gap-1 text-sm text-gray-8"
          onPress={() => onPathChange?.(-1)}
        >
          <ArrowLeft2 size={16} />
          Voltar
        </Button>
      )}
    </div>
  );
}
