import { Control, FieldValues, Path, useController } from "react-hook-form";

import { ComboBoxInput, ComboBoxInputProps } from "..";

export interface FormComboBoxInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends ComboBoxInputProps<T> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
}

export function FormComboBoxInput<
  T extends object,
  TFieldValues extends FieldValues,
>({
  children,
  control,
  name,
  ...props
}: FormComboBoxInputProps<T, TFieldValues>) {
  const {
    field: { onChange, value, disabled, ref, ...fieldProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: props.isDisabled,
  });

  return (
    <ComboBoxInput
      {...props}
      {...fieldProps}
      isDisabled={disabled || props.isReadOnly}
      isInvalid={invalid}
      inputRef={ref}
      errorMessage={error?.message}
      validationBehavior="aria"
      selectedKey={props.allowsCustomValue ? undefined : value ?? ""}
      inputValue={props.allowsCustomValue ? value ?? "" : undefined}
      onSelectionChange={(key) => {
        if (!props.allowsCustomValue) {
          onChange(key);
        }
      }}
      onInputChange={(key) => {
        if (props.allowsCustomValue) {
          onChange(key);
        }
      }}
    >
      {children}
    </ComboBoxInput>
  );
}
