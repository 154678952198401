import { forwardRef } from "react";
import {
  DateInput as AriaDateInput,
  DateInputProps,
  DateSegment,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { fieldGroupStyles } from "../..";

const segmentStyles = tv({
  base: "inline rounded caret-transparent outline outline-0 forced-color-adjust-none rac-type-literal:px-0 forced-colors:text-[ButtonText]",
  variants: {
    isPlaceholder: {
      true: "italic text-gray-11",
    },
    isDisabled: {
      true: "text-gray-1 forced-colors:text-[GrayText]",
    },
    isFocused: {
      true: "bg-gray-1 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]",
    },
  },
});

export const DateInput = forwardRef(
  (props: Omit<DateInputProps, "children">, ref: React.Ref<HTMLDivElement>) => {
    return (
      <AriaDateInput
        className={(renderProps) =>
          fieldGroupStyles({
            ...renderProps,
            class: "h-full w-full",
          })
        }
        {...props}
        ref={ref}
      >
        {(segment) => (
          <DateSegment segment={segment} className={segmentStyles} />
        )}
      </AriaDateInput>
    );
  },
);
