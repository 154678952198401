import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useFailure(failureId: string) {
  return useQuery({
    queryKey: ["failure", failureId],
    queryFn: () =>
      apiProvider.services.FalhasService.getOneObjFalhaIdGet({
        id: +failureId,
      }),
    enabled: !!failureId,
  });
}
