import {
  Breadcrumbs,
  Can,
  EditLink,
  PageTitle,
  QueryHandler,
  S3Image,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationUpdateManualPost, usePost } from "@/shared/hooks/api";
import { cn, sanitize } from "@/shared/utils";

import { PostDetailError, PostDetailLoading } from "../components";

export function PostDetail() {
  const { postId } = useRequiredParams();
  const postQuery = usePost(postId);
  const mutationPostUpdate = useMutationUpdateManualPost();

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <QueryHandler
        {...postQuery}
        renderLoading={PostDetailLoading}
        renderError={(error) => <PostDetailError error={error} />}
      >
        {(data) => (
          <>
            <div className="space-y-4">
              <Breadcrumbs />
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.titulo}</PageTitle>
                <Can I="update.post">
                  <EditLink />
                </Can>
              </div>
              <Can I="update.post">
                <SwitchStatusButton
                  isDisabled={mutationPostUpdate.isPending}
                  isSelected={data?.flagAtivo}
                  onChange={(status) =>
                    mutationPostUpdate.mutateAsync({
                      id: +postId,
                      requestBody: { flagAtivo: status },
                    })
                  }
                />
              </Can>
            </div>
            <div className="space-y-4">
              {data?.capa ? (
                <S3Image
                  className={(type) =>
                    cn("max-w-100 max-h-56 rounded-lg", {
                      "object-cover": type === "img",
                    })
                  }
                  file={data.capa}
                />
              ) : (
                <img
                  className="max-w-100 max-h-56 object-cover"
                  src="/default-manual-post-cover.png"
                />
              )}
              <div
                className="tiptap text-black-0 [&>p]:min-h-6"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data?.texto ?? ""),
                }}
              />
            </div>
          </>
        )}
      </QueryHandler>
    </div>
  );
}
