import { Cell } from "react-aria-components";

import {
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { Topico } from "@/shared/services";

export interface TopicTableProps
  extends Pick<TableProps<Topico>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function TopicTable({ pagination, search, ...props }: TopicTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.topic">
          {(allowed) => (
            <Table
              {...props}
              aria-label="Lista topicos"
              headers={["Posição", "Título"]}
              emptyProps={{ resource: "topico", search }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, posicao, titulo }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{posicao}</Cell>
                  <Cell>{titulo}</Cell>
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
