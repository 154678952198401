import { cn } from "@/shared/utils";

import { Skeleton } from ".";

export interface ColorInputSkeletonProps {
  className?: string;
}

export function ColorInputSkeleton({ className }: ColorInputSkeletonProps) {
  return (
    <div className={cn("flex h-11 w-full gap-3", className)}>
      <Skeleton className="h-full flex-1" />
      <Skeleton className="h-full w-12 rounded-xl" />
    </div>
  );
}
