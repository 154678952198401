import { FieldValues } from "react-hook-form";

import { TipoCliente, apiProvider } from "@/shared/services";

import {
  FormSelectApiResourceInput,
  FormSelectApiResourceInputProps,
} from "..";

export type FormSelectClientTypeInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormSelectApiResourceInputProps<T, TFieldValues, TipoCliente>,
  "service" | "queryKey" | "label" | "mapElements"
>;
export function FormSelectClientTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormSelectClientTypeInputProps<T, TFieldValues>) {
  return (
    <FormSelectApiResourceInput
      label="Tipo do cliente"
      queryKey={["clientType"]}
      service={
        apiProvider.services.TiposClientesService.getObjsTiposClientesGet
      }
      mapElements={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
