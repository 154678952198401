import { Control, FieldValues, Path, useController } from "react-hook-form";

import { RadioGroup, RadioGroupProps } from "..";

export interface FormRadioGroupInputProps<T extends FieldValues>
  extends RadioGroupProps {
  className?: string;
  control: Control<T>;
  name: Path<T>;
}

export function FormRadioGroupInput<T extends FieldValues>({
  control,
  name,
  children,
  ...props
}: FormRadioGroupInputProps<T>) {
  const {
    field: { value, onChange, disabled },
    fieldState: { error },
  } = useController({
    name,
    control,
    disabled: props.isDisabled,
  });

  return (
    <RadioGroup
      {...props}
      name={name}
      value={value}
      onChange={onChange}
      isDisabled={disabled}
      errorMessage={error?.message}
    >
      {children}
    </RadioGroup>
  );
}
