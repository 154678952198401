import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";

interface ManuaisServiceProps {
  manualId: number;
}

const baseURL = import.meta.env.VITE_API_URL;
export const ManuaisService = {
  downloadObjManualDownloadIdGet: ({ manualId }: ManuaisServiceProps) => {
    return axios.get(`${baseURL}/manual/download/${manualId}`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${apiProvider.accessToken}`,
      },
    });
  },
};

export function useManualDownload() {
  return useMutation({
    mutationFn: async ({ manualId }: ManuaisServiceProps) => {
      const response = await ManuaisService.downloadObjManualDownloadIdGet({
        manualId,
      });

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileName = `manual_${manualId}.pdf`;
      saveAs(blob, fileName);
    },

    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao enviar imagem ao servidor: ${e.message}`,
      }),
  });
}
