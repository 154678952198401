import { cn } from "../utils";

interface ErrorContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: string;
}
export function ErrorContainer({
  message,
  className,
  children,
  ...props
}: ErrorContainerProps) {
  return (
    <div
      className={cn(
        "flex w-full flex-col items-center justify-center py-16",
        className,
      )}
      {...props}
    >
      <h1 className="mb-6 text-4xl font-semibold">Oops!</h1>
      {children ? (
        children
      ) : (
        <p className="mb-6">
          {message === undefined
            ? "Sinto muito, mas ocorreu um erro."
            : message}{" "}
        </p>
      )}
    </div>
  );
}
