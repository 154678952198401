import { Link as LinkIcon } from "iconsax-react";
import { Link } from "react-aria-components";

import {
  AnimatedContainer,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationProductUpdate, useProduct } from "@/shared/hooks/api";
import { nullishStringMask } from "@/shared/utils/masks";

import { ProductDetailError, ProductDetailLoading } from "../components";

export function ProductDetail() {
  const { productId } = useRequiredParams();
  const productQuery = useProduct(productId);
  const mutationProductUpdate = useMutationProductUpdate();

  return (
    <AnimatedContainer>
      <div className="space-y-4">
        <QueryHandler
          {...productQuery}
          renderLoading={ProductDetailLoading}
          renderError={(error) => <ProductDetailError error={error} />}
        >
          {(data) => (
            <Card className="shadow-header">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-2">
                      <PageTitle primary>{data?.nome}</PageTitle>
                      <Can I="update.product">
                        <EditLink />
                      </Can>
                    </div>
                  </div>
                </div>
                <Can I="update.product">
                  <SwitchStatusButton
                    isDisabled={mutationProductUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationProductUpdate.mutateAsync({
                        id: +productId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              </div>
              <p className="my-4 text-sm">{data?.descricao}</p>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Dados gerais</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                <div>
                  <p>Marca ou Fabricante</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.fabricante)}
                  </p>
                </div>

                <div>
                  <p>Modelo</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.modelo)}
                  </p>
                </div>

                <div>
                  <p>Site</p>
                  <Link
                    className="flex items-center gap-1 font-bold text-info"
                    href={data?.linkManual ?? undefined}
                    target="_blank"
                  >
                    {data?.linkManual && <LinkIcon size="1rem" />}
                    {nullishStringMask(data?.linkManual ?? undefined)}
                  </Link>
                </div>
              </div>
              <Separator />
            </Card>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
