import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { FailureCreateForm } from "../components";

export function FailureCreate() {
  const { warrantyId } = useRequiredParams();

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar falha</PageTitle>
        <FailureCreateForm
          redirectTo="../"
          useFormProps={{
            defaultValues: {
              garantiaId: +warrantyId,
            },
          }}
        />
      </div>
    </AnimatedContainer>
  );
}
