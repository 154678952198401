import { useParams } from "react-router-dom";

import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { DevelopmentFormWizard } from "../components/development-form-wizard";
import {
  DevelopmentCreateFormSchema,
  DevelopmentCreateSchema,
} from "../schemas";

export function DevelopmentCreate() {
  const { companyId } = useParams();

  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle>Cadastrar empreendimento</PageTitle>
        <DevelopmentFormWizard
          onSuccessRedirect="../"
          formSchema={DevelopmentCreateFormSchema}
          submitSchema={DevelopmentCreateSchema}
          useFormProps={{
            defaultValues: {
              module: ["manual"],
              construtoraId: companyId ? +companyId : undefined,
            },
          }}
        />
      </div>
    </AnimatedContainer>
  );
}
