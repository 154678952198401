import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFailure } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { FailureCreateForm } from "../../create/components";

export function FailureEdit() {
  const { failureId, warrantyId } = useRequiredParams();

  const failureQuery = useFailure(failureId);

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar falha</PageTitle>
        <QueryHandler
          {...failureQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? `Falha não encontrada...`
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <FailureCreateForm
              redirectTo="../"
              useFormProps={{
                defaultValues: {
                  garantiaId: +warrantyId,
                  nome: data?.nome,
                  prazo: data?.prazo,
                  unidadeTempoId: data?.unidadeTempoId,
                },
              }}
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
