import { Building4, Buildings2 } from "iconsax-react";
import { MenuTrigger } from "react-aria-components";

import {
  AnimatedContainer,
  Button,
  Can,
  Menu,
  MenuItem,
  SearchInput,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useBlocks } from "@/shared/hooks/api";

import { BlockTable } from "../components";

export function BlockList() {
  const { developmentId } = useRequiredParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useBlocks({ empreendimentoId: +developmentId, text: "" });

  return (
    <AnimatedContainer className="space-y-4">
      <div className="flex gap-2">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <Can I="create.block">
          {(allowedOne) => (
            <Can I="bulk.create.block">
              {(allowedBulk) =>
                (allowedOne || allowedBulk) && (
                  <MenuTrigger>
                    <Button className="cursor-pointer px-16 py-3 font-medium">
                      Adicionar bloco{allowedOne && allowedBulk && "(s)"}
                    </Button>
                    <Menu>
                      {allowedOne && (
                        <MenuItem id="single-create" href="cadastrar/">
                          <Building4 />
                          Criar um
                        </MenuItem>
                      )}
                      {allowedBulk && (
                        <Can I="bulk.create.block">
                          <MenuItem id="bulk-create" href="lote/cadastrar/">
                            <Buildings2 />
                            Criar vários
                          </MenuItem>
                        </Can>
                      )}
                    </Menu>
                  </MenuTrigger>
                )
              }
            </Can>
          )}
        </Can>
      </div>
      <BlockTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
