import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { CompanyFormWizard } from "../components/company-create-form-wizard";
import { CompanyCreateFormSchema, CompanyCreateSchema } from "../schemas";

export function CompanyCreate() {
  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle className="py-4">Cadastrar construtora</PageTitle>
        <CompanyFormWizard
          formSchema={CompanyCreateFormSchema}
          submitSchema={CompanyCreateSchema}
        />
      </div>
    </AnimatedContainer>
  );
}
