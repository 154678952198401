import { ArrowDown2 } from "iconsax-react";
import { useEffect } from "react";
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectProps,
  SelectValue,
} from "react-aria-components";

import { cn } from "../utils";

export interface StatusInputProps
  extends Omit<SelectProps<typeof Select>, "onSelectionChange"> {
  showLabel?: boolean;
  onSelectionChange?: (status?: boolean) => void;
}

export function StatusInput({
  className,
  showLabel,
  onSelectionChange,
  ...props
}: StatusInputProps) {
  useEffect(() => {
    onSelectionChange?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      className={cn("text-sm text-gray-6", className)}
      defaultSelectedKey="true"
      onSelectionChange={(key) =>
        onSelectionChange?.(key !== "all" ? key === "true" : undefined)
      }
      {...props}
    >
      <Label hidden={!showLabel}>Status</Label>
      <Button className="group flex w-full justify-between rounded-lg border border-white-3 bg-white-0 p-3">
        <span>
          Status:{" "}
          <span className="font-semibold">
            <SelectValue />
          </span>
        </span>
        <span aria-hidden="true">
          <ArrowDown2
            className="transition-all group-rac-hover:translate-y-1"
            size={20}
            variant="Bold"
          />
        </span>
      </Button>
      <Popover className="w-[--trigger-width] rounded-lg border border-white-3 bg-white-0 p-3 text-sm shadow-dialog">
        <ListBox className="*:rounded-lg *:p-2">
          <ListBoxItem className="hover:bg-black-0/10" id="all">
            Todos
          </ListBoxItem>
          <ListBoxItem className="hover:bg-black-0/10" id="true">
            Ativo
          </ListBoxItem>
          <ListBoxItem className="hover:bg-black-0/10" id="false">
            Inativo
          </ListBoxItem>
        </ListBox>
      </Popover>
    </Select>
  );
}
