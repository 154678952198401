import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  Arquivo,
  ArquivoBase,
  ArquivoCreateWithLink,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationDocumentCreateConfig
  extends Omit<
    UseMutationOptions<Arquivo, Error, ArquivoBase | ArquivoCreateWithLink>,
    "mutationFn"
  > {}

export function useMutationDocumentCreate(
  options?: UseMutationDocumentCreateConfig,
) {
  return useMutation({
    mutationFn: (requestBody: ArquivoBase | ArquivoCreateWithLink) => {
      if ("link" in requestBody) {
        return apiProvider.services.ArquivoService.createArquivoArquivoPost({
          requestBody,
        });
      }

      return apiProvider.services.ArquivoService.createPastaArquivoPastaPost({
        requestBody,
      });
    },
    onSettled: async (_, __, { manualId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["documents", { manualId }],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar documento: ${e.message}`,
      }),
    ...options,
  });
}
