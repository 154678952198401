import { useToggle } from "@uidotdev/usehooks";
import { ArrowDown2 } from "iconsax-react";
import { Button, DialogTrigger } from "react-aria-components";

import {
  QueryHandler,
  ResourceProfileImage,
  Skeleton,
} from "@/shared/components";
import { useProfile } from "@/shared/hooks/api";

import { ProfileMenu } from "./profile-menu";

export function ProfileButton() {
  const [on, toggle] = useToggle(false);
  const profileQuery = useProfile();

  return (
    <DialogTrigger isOpen={on} onOpenChange={toggle}>
      <Button
        aria-label="Profile"
        className="group ml-4 flex items-center gap-3"
        data-cy="profile-button"
      >
        <div>
          <QueryHandler
            {...profileQuery}
            renderLoading={() => (
              <>
                <Skeleton className="mb-1 h-4 w-32" />
                <Skeleton className="ml-auto h-3 w-16" />
              </>
            )}
            renderError={() => (
              <p className="text-body-sm font-medium text-gray-10">Erro</p>
            )}
          >
            {(data) =>
              data ? (
                <>
                  <p className="text-body-sm font-medium text-gray-10">
                    {data.nome}
                  </p>
                  <p className="text-right text-xs text-primary ">
                    {data.papel.nome}
                  </p>
                </>
              ) : (
                <p className="text-body-sm font-medium text-gray-10">
                  Sem Dados
                </p>
              )
            }
          </QueryHandler>
        </div>
        <ArrowDown2
          className="text-primary transition-all group-hover:translate-y-1"
          variant="Bold"
        />
        <ResourceProfileImage
          file={profileQuery.data?.foto}
          size="extraSmall"
          emptyIcon="profile"
        />
      </Button>
      <ProfileMenu onClose={() => toggle(false)} />
    </DialogTrigger>
  );
}
