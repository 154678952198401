import { Label } from "react-aria-components";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { cn } from "@/shared/utils";

import {
  InputStyleProps,
  container,
  label as labelStyle,
} from "../inputs/style/input";
import { Tiptap, TiptapProps } from "../tiptap/tiptap";

export interface FormRichTextInputProps<T extends FieldValues>
  extends Omit<TiptapProps, "children">,
    InputStyleProps {
  className?: string;
  label?: string;
  control: Control<T>;
  name: Path<T>;
}

export function FormRichTextInput<T extends FieldValues>({
  className,
  label,
  control,
  name,
  ...props
}: FormRichTextInputProps<T>) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div className={cn(container(props), className)}>
      {label && <Label className={labelStyle(props)}>{label}</Label>}
      <Tiptap content={value} onChange={onChange} onBlur={onBlur} />
      {error?.message && (
        <small className="text-xs font-semibold text-error">
          {error.message}
        </small>
      )}
    </div>
  );
}
