import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export function useMutationClientUpdate() {
  return useMutation({
    mutationFn: apiProvider.services.ClientesService.updateClienteClientesIdPut,
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["client", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar cliente: ${e.message}`,
      }),
  });
}
