import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useProduct(productId: string) {
  return useQuery({
    queryKey: ["product", productId],
    queryFn: () =>
      apiProvider.services.ProdutosService.getOneObjProdutoIdGet({
        id: +productId,
      }),
    enabled: !!productId,
  });
}
