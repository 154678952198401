import { DialogTrigger } from "react-aria-components";

import { DevelopmentCard } from "@/modules/companies/modules/detail/components";
import {
  Am,
  AnimatedContainer,
  Button,
  Can,
  Link,
  ListSkeleton,
  PageTitle,
  PaginatedList,
  SearchInput,
  UnauthorizedModal,
} from "@/shared/components";
import { useDevelopments } from "@/shared/hooks/api";

export function ListDevelopments() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useDevelopments({ text: "" });

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col overflow-y-auto p-8">
      <div className="mb-6 flex justify-between">
        <PageTitle primary>Empreendimentos</PageTitle>
        <Can I="create.development">
          <div className="flex items-center">
            <Am I="SeuManual">
              <Link className="w-auto px-8 py-3" href="cadastrar/">
                Adicionar empreendimento
              </Link>
            </Am>
            <Am I="Construtora">
              <DialogTrigger>
                <Button className="w-auto px-8 py-3">
                  Adicionar empreendimento
                </Button>
                <UnauthorizedModal />
              </DialogTrigger>
            </Am>
          </div>
        </Can>
      </div>
      <div className="mb-4 flex gap-4">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
      </div>
      <PaginatedList
        className="space-y-4"
        data={data?.content}
        renderElement={(e) => <DevelopmentCard key={e.id} development={e} />}
        isLoading={isLoading}
        renderLoading={(prevCount) => (
          <ListSkeleton className="h-64" elements={prevCount} />
        )}
        emptyProps={{
          resource: "empreendimento",
          search: filters.text ?? undefined,
        }}
        currentPage={filters.page ?? 1}
        pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
        onPageChange={(page) => updateFilters({ page })}
      />
    </AnimatedContainer>
  );
}
