import { Control, FieldValues, Path, useController } from "react-hook-form";

import { useNbr } from "@/shared/hooks/api";

import { NbrPickerInput, NbrPickerInputProps } from "..";

export interface FormNbrPickerInputProps<T extends FieldValues>
  extends Omit<
    NbrPickerInputProps,
    "label" | "onNbrSelected" | "validationBehavior" | "errorMessage"
  > {
  control: Control<T>;
  name: Path<T>;
  initialNbrId?: number | null | undefined;
}

export function FormNbrPickerInput<T extends FieldValues>({
  control,
  name,
  ...props
}: FormNbrPickerInputProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const nbrQuery = useNbr(value);

  return (
    <NbrPickerInput
      {...props}
      label="NBR"
      onNbrSelected={(nbr) => onChange(nbr?.id)}
      validationBehavior="aria"
      errorMessage={error?.message}
      initialNbr={nbrQuery.data}
    />
  );
}
