import {
  TabPanel as AriaTabPanel,
  TabPanelProps as AriaTabPanelProps,
} from "react-aria-components";

import { cn } from "../utils";

export interface TabPanelProps extends AriaTabPanelProps {}

export function TabPanel({ className, children, ...props }: TabPanelProps) {
  return (
    <AriaTabPanel
      className={cn("mt-4 animate-in fade-in slide-in-from-left-2", className)}
      {...props}
    >
      {children}
    </AriaTabPanel>
  );
}
