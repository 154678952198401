import { AnyMongoAbility, PureAbility } from "@casl/ability";
import { createContext } from "react";

export type AppAbility = PureAbility<string> & AnyMongoAbility;

export type AuthorizationContext = {
  ability: AppAbility;
  authorized: boolean | null;
};

export const AuthorizationContext = createContext<AuthorizationContext | null>(
  null,
);
