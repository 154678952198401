import {
  UnknownKeysParam,
  ZodObject,
  ZodRawShape,
  ZodTypeAny,
  objectInputType,
  objectOutputType,
  z,
} from "zod";

export function generateStepSchema<
  T extends ZodRawShape,
  UnknownKeys extends UnknownKeysParam = UnknownKeysParam,
  Catchall extends ZodTypeAny = ZodTypeAny,
  Output = objectOutputType<T, Catchall, UnknownKeys>,
  Input = objectInputType<T, Catchall, UnknownKeys>,
>(schema: ZodObject<T, UnknownKeys, Catchall, Output, Input>, index: number) {
  return z.object({
    _formStep: z.literal(index),
    ...schema.shape,
  });
}
