import { useFormContext } from "react-hook-form";

import {
  Card,
  FieldSetTitle,
  FormColorInput,
  FormFileInput,
} from "@/shared/components";
import { megabyte } from "@/shared/utils";

import { DevelopmentStyle } from "../schemas";

export function DevelopmentStyleForm() {
  const { control } = useFormContext<DevelopmentStyle>();

  return (
    <Card className="p-6">
      <FieldSetTitle>Cores principais</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormColorInput
          control={control}
          name="corPrincipal"
          label="Cor principal"
          defaultValue="#226355"
        />
        <FormColorInput
          control={control}
          name="corSecundaria"
          label="Cor secundária"
          defaultValue="#9e5b35"
        />
      </div>
      <FieldSetTitle className="mb-6 mt-3.5">Cores do rodapé</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormColorInput
          control={control}
          name="corDaFonte"
          label="Cor da fonte"
          defaultValue="#438176"
        />
        <FormColorInput
          control={control}
          name="corRodapePrincipal"
          label="Cor rodapé principal"
          defaultValue="#1a3934"
        />
        <FormColorInput
          control={control}
          name="corRodapeSecundaria"
          label="Cor rodapé secundário"
          defaultValue="#1a3934"
        />
        <FormColorInput
          control={control}
          name="corDaFonteRodape"
          label="Cor da fonte rodapé"
          defaultValue="#e2ebe9"
        />
      </div>
      <FieldSetTitle className="mb-6 mt-3.5">Favicon</FieldSetTitle>
      <div className="flex gap-4">
        <FormFileInput
          control={control}
          label="Foto"
          name="foto"
          acceptedFileTypes={["image/png", "image/jpeg"]}
          maxFileSize={20 * megabyte}
          description="Proporção sugerida: 16:9"
        />
        <FormFileInput
          control={control}
          label="Capa"
          name="capa"
          acceptedFileTypes={["image/png", "image/jpeg"]}
          maxFileSize={20 * megabyte}
          description="Proporção sugerida: 4:3"
        />
      </div>
    </Card>
  );
}
