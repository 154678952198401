import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useBlock } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { BlockCreateForm } from "../../create/components";

export function DevelopmentBlockEdit() {
  const { blockId } = useRequiredParams();
  const blockQuery = useBlock(blockId);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar bloco</PageTitle>
      </div>
      <QueryHandler
        {...blockQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Bloco não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <BlockCreateForm
            redirectTo="../"
            useFormProps={{ defaultValues: data }}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
