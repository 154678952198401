import {
  ListSkeleton,
  PaginatedList,
  PaginatedListProps,
} from "@/shared/components";
import { PostWithAutor } from "@/shared/services";
import { cn } from "@/shared/utils";

import { PostCard } from ".";

export interface PostPaginatedListProps
  extends Omit<
    PaginatedListProps<PostWithAutor>,
    "renderElement" | "renderLoading" | "emptyProps"
  > {
  search?: string;
}

export function PostPaginatedList({
  search,
  className,
  ...props
}: PostPaginatedListProps) {
  return (
    <PaginatedList
      {...props}
      className={cn("space-y-4", className)}
      renderElement={(e) => <PostCard key={e.id} {...e} />}
      renderLoading={(prevCount) => <ListSkeleton elements={prevCount} />}
      emptyProps={{ resource: "post", search }}
    />
  );
}
