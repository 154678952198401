import { Card, Skeleton } from "@/shared/components";

export function DevelopmentDataLoading() {
  return (
    <div className="space-y-4">
      <Card className="relative p-6 shadow-header">
        <div className="absolute left-0 top-0 h-full w-2 rounded-l-lg bg-primary" />
        <div className="flex items-center gap-2">
          <Skeleton className="size-9 rounded-full" />
          <Skeleton className="h-6 w-44" />
        </div>
        <div className="flex items-center justify-between">
          <div className="ml-12 flex items-center gap-4 self-end">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-24" />
          </div>
          <Skeleton className="h-7 w-40" />
        </div>
      </Card>
      <Card className="flex justify-between shadow-header">
        <div className="flex items-center gap-5">
          <Skeleton className="size-8" />
          <Skeleton className="h-12 w-28" />
          <Skeleton className="h-7 w-20" />
        </div>
        <Skeleton className="my-auto h-7 w-32" />
      </Card>
    </div>
  );
}
