import { useState } from "react";
import { useParams } from "react-router-dom";

import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { useDocuments } from "@/shared/hooks/api";
import { Arquivo } from "@/shared/services";

import { DocumentCreateModal } from "../../create/components";
import { DocumentPath, DocumentsPaginatedList } from "../components";

export function DocumentList() {
  const { manualId, apartmentId } = useParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useDocuments({
      manualId: manualId ? +manualId : undefined,
      unidadeId: apartmentId ? +apartmentId : undefined,
      status: true,
      paiId: 0,
      text: "",
    });
  const [path, setPath] = useState<Array<Arquivo>>([]);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  const moveInsideFolder = (folder: Arquivo) => {
    updateFilters({ paiId: folder.id });
    setPath((prev) => [...prev, folder]);
  };

  const changePath = (index: number) => {
    if (path.length === 0 || path.length === index) {
      return;
    }

    const newPath = path.slice(0, index);
    const current = newPath.at(-1);
    updateFilters({ paiId: current ? current.id : 0 });
    setPath(newPath);
  };

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName=""
        onSearchChange={handleSearchChange}
        createOnPress={turnOn}
        createAction="create.document"
      />
      <DocumentPath path={path} onPathChange={changePath} />
      <DocumentsPaginatedList
        data={data?.content}
        isLoading={isLoading}
        search={filters.text ?? undefined}
        currentPage={filters.page ?? 1}
        pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
        onPageChange={(page) => updateFilters({ page })}
        onFolderChange={moveInsideFolder}
      />
      <DocumentCreateModal
        isOpen={isOn}
        onOpenChange={setOpen}
        manualId={manualId ? +manualId : undefined}
        apartmentId={apartmentId ? +apartmentId : undefined}
        paiId={
          typeof filters.paiId === "number" && filters.paiId !== 0
            ? filters.paiId
            : undefined
        }
      />
    </AnimatedContainer>
  );
}
