import {
  Am,
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
  VideoPlayer,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFaq, useMutationFaqUpdate } from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

import { FaqDetailError, FaqDetailLoading } from "../components";

export function FaqDetail() {
  const { faqId } = useRequiredParams();
  const faqQuery = useFaq(faqId);
  const mutationFaqUpdate = useMutationFaqUpdate();

  return (
    <div className="flex h-full flex-col overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <Breadcrumbs />
      <PageTitle className="py-4">Detalhes do FAQ</PageTitle>
      <QueryHandler
        {...faqQuery}
        renderLoading={() => <FaqDetailLoading />}
        renderError={(error) => <FaqDetailError error={error} />}
      >
        {(data) => (
          <Card className="space-y-4 shadow-header">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.nome}</PageTitle>
                <Am I="SeuManual">
                  <Can I="update.faq">
                    <EditLink />
                  </Can>
                </Am>
              </div>

              {data && (
                <Am I="SeuManual">
                  <Can I="update.faq">
                    <SwitchStatusButton
                      isDisabled={mutationFaqUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationFaqUpdate.mutateAsync({
                          id: +faqId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                </Am>
              )}
            </div>
            <div
              className="tiptap text-black-0 [&>p]:min-h-6"
              dangerouslySetInnerHTML={{
                __html: sanitize(data?.descricao ?? ""),
              }}
            />
            <VideoPlayer className="w-96 rounded-lg" url={data?.videoUrl} />
          </Card>
        )}
      </QueryHandler>
    </div>
  );
}
