import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

const service = apiProvider.services.SistemasService.updateObjSistemaIdPut;

export type SystemUpdatePayload = Parameters<typeof service>[0];

export function useMutationSystemUpdate() {
  return useMutation({
    mutationFn: (payload: SystemUpdatePayload) => {
      return service(payload);
    },
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["system", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar sistema: ${e.message}`,
      }),
  });
}
