import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios from "axios";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { bytesMask } from "@/shared/utils/masks";

export interface UseMutationFileUploadData {
  fileName: string;
  key: string;
}

export interface UseMutationFileUploadOptions
  extends Omit<
    UseMutationOptions<UseMutationFileUploadData[], Error, File[]>,
    "mutationFn"
  > {
  maxFileSize?: number;
}

export class FileSizeExceeded extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

export class FileExtensionMissing extends Error {
  constructor() {
    super("Faltando extensão do arquivo");
    this.name = "ValidationError";
  }
}

export function useMutationFileUpload({
  maxFileSize,
  ...props
}: UseMutationFileUploadOptions) {
  return useMutation({
    mutationFn: async (files: File[]) => {
      for (const file of files) {
        if (maxFileSize && file.size > maxFileSize) {
          throw new FileSizeExceeded(
            `Arquivo ${file.name} de tamanho ${bytesMask(file.size)} ultrapassou o limite de ${bytesMask(maxFileSize)}`,
          );
        }
      }

      return await Promise.all(
        files.map(async (file) => {
          const fileExtension = file.name.split(".").pop();

          if (!fileExtension) {
            throw FileExtensionMissing;
          }

          const presignedUrl =
            await apiProvider.services.FilesService.getPresignedUploadUrlFilesUploadGet(
              { contentType: fileExtension },
            );
          await axios.put(presignedUrl.url, file, {
            headers: {
              "Content-Type": fileExtension,
            },
          });

          return { fileName: file.name, key: presignedUrl.key };
        }),
      );
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao enviar arquivo ao servidor: ${e.message}`,
      }),
    ...props,
  });
}
