import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsNbrGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseNbrsFilters = GetObjsNbrGetData;

export function useNbrs() {
  const [filters, updateFilters] = useObjReducer<UseNbrsFilters>({
    page: 1,
    text: "",
  });

  const query = useQuery({
    queryKey: ["nbrs", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.NbrsService.getObjsNbrGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
