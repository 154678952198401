import {
  Form as AriaForm,
  FormProps as AriaFormProps,
} from "react-aria-components";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

export interface FormCoreProps<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> extends AriaFormProps {
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
}

export function FormCore<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  form,
  ...props
}: FormCoreProps<TFieldValues, TContext, TTransformedValues>) {
  return (
    <FormProvider {...form}>
      <AriaForm {...props} />
    </FormProvider>
  );
}
