import { Edit, TrendUp } from "iconsax-react";
import { Button, DialogTrigger } from "react-aria-components";

import { ManualFormModal } from "@/modules/manual/components";
import {
  AnimatedContainer,
  Can,
  Card,
  DownloadFile,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useManual,
  useManualDownload,
  useMutationManualUpdate,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function ManualData() {
  const { manualId } = useRequiredParams();
  const manualQuery = useManual(manualId);
  const mutationManualUpdate = useMutationManualUpdate();
  const manualMutation = useManualDownload();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler {...manualQuery}>
          {(data) => (
            <>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex items-center gap-2">
                    <PageTitle primary>{data?.nome}</PageTitle>
                    <Can I="update.manual">
                      <DialogTrigger>
                        <Button data-cy="edit-manual-button">
                          <Edit className="text-primary" />
                        </Button>
                        <ManualFormModal
                          useFormProps={{
                            defaultValues: {
                              ...data,
                              link: data?.link
                                ? [{ key: data.link, fileName: data.link }]
                                : null,
                            },
                          }}
                          onSubmit={async (values, close) => {
                            mutationManualUpdate.mutateAsync(
                              {
                                id: +manualId,
                                requestBody: {
                                  ...values,
                                  link: values.link ? values.link[0].key : null,
                                },
                              },
                              {
                                onSuccess: close,
                              },
                            );
                          }}
                        />
                      </DialogTrigger>
                    </Can>
                    <DownloadFile
                      className="gap-1 bg-info px-3 text-xs [&>div]:!size-4 [&>div]:!border-2 [&>div]:!border-y-info"
                      onPress={() =>
                        manualId &&
                        manualMutation.mutateAsync({
                          manualId: +manualId,
                        })
                      }
                      isPending={manualMutation.isPending}
                    >
                      <TrendUp size="1rem" />
                      Baixar Manual
                    </DownloadFile>
                  </div>
                  <p className="text-xs text-primary">
                    Tipo do cliente:{" "}
                    <span className="font-semibold">
                      {data?.tipoCliente?.nome}
                    </span>
                  </p>
                </div>
                <Can I="update.manual">
                  {data && (
                    <SwitchStatusButton
                      className="self-start"
                      isDisabled={mutationManualUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationManualUpdate.mutateAsync({
                          id: +manualId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  )}
                </Can>
              </div>
              {data?.descricao && (
                <div
                  className="tiptap text-black-0 [&>p]:min-h-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data.descricao),
                  }}
                />
              )}
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
