import {
  AnimatedContainer,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useBlock, useMutationBlockUpdate } from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function BlockData() {
  const { blockId } = useRequiredParams();
  const blockQuery = useBlock(blockId);
  const mutationBlockUpdate = useMutationBlockUpdate();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler {...blockQuery}>
          {(data) => (
            <>
              <div className="flex justify-between">
                <PageTitle primary className="flex items-center gap-2">
                  {data?.nome}{" "}
                  <Can I="update.block">
                    <EditLink />
                  </Can>
                </PageTitle>
                {data && (
                  <Can I="update.company">
                    <SwitchStatusButton
                      isDisabled={mutationBlockUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationBlockUpdate.mutateAsync({
                          id: +blockId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                )}
              </div>
              {data?.descricao && (
                <div
                  className="tiptap text-black-0 [&>p]:min-h-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data.descricao),
                  }}
                />
              )}
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
