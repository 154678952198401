import { useProfile } from "../hooks/api";

export interface AmProps {
  I: RoleType;
  children: React.ReactNode | ((allowed: boolean) => React.ReactNode);
}

export function Am({ I, children }: AmProps) {
  const { data } = useProfile();
  const allowed = data?.papel.tipo === I;

  if (allowed && !(children instanceof Function)) {
    return children;
  }

  if (children instanceof Function) {
    return children(allowed);
  }
}
