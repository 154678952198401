import { Card, Skeleton } from "@/shared/components";

export function FaqDetailLoading() {
  return (
    <Card className="space-y-4 shadow-header">
      <div className="flex justify-between">
        <Skeleton className="h-10 w-40" />
        <div className="flex gap-4">
          <Skeleton className="h-10 w-24" />
          <Skeleton className="h-10 w-24" />
        </div>
      </div>
      <Skeleton className="my-4 h-48 w-full" />
    </Card>
  );
}
