import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";

import { AddFaqModal } from ".";
import { SubsystemCreate } from "../schemas";

interface FormFaqListProps {
  className?: string;
  control: Control<SubsystemCreate>;
}

export function FormFaqList({ control, ...props }: FormFaqListProps) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <FormListInput
      {...props}
      control={control}
      name="faqs"
      onAddPress={turnOn}
      resourceOptions={{
        word: "faq",
        pluralRadical: "s",
        isMasculine: true,
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          <AddFaqModal
            isOpen={isOn}
            onOpenChange={setOpen}
            onAdd={(value) => {
              update([...values, value]);
              turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
