import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useUser } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { UserEditForm } from "../components/user-edit-form";

export function UserEdit() {
  const { userId } = useRequiredParams();
  const userQuery = useUser(userId);

  const getDefaultValues = (data: typeof userQuery.data) => {
    if (!data) {
      return undefined;
    }
    const { cpf, telefone, papel, ...values } = data;

    return {
      defaultValues: {
        ...values,
        papelId: papel.id,
        cpf: cpf ? cpfMask(cpf) : undefined,
        telefone: telefone ? phoneMask(telefone) : undefined,
      },
    };
  };

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar usuário</PageTitle>
      </div>
      <QueryHandler
        {...userQuery}
        renderLoading={() => <Spinner />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Usuário não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <UserEditForm
            redirectTo="../"
            useFormProps={getDefaultValues(data)}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
