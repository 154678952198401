import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { DevelopmentDocumentsInfoForm } from "./development-contract-form";
import { DevelopmentDataForm } from "./development-data-form";
import { DevelopmentModuleDataForm } from "./development-module-data-form";
import { DevelopmentStyleForm } from "./development-style-form";

export interface DevelopmentFormWizardProps<TSchema extends ZodTypeAny>
  extends Pick<
    FormWizardProps<TSchema>,
    "useFormProps" | "formSchema" | "submitSchema" | "onSuccessRedirect"
  > {}

export const DevelopmentCreateFormSteps = [
  DevelopmentDataForm,
  DevelopmentModuleDataForm,
  DevelopmentStyleForm,
  DevelopmentDocumentsInfoForm,
] as const;

export function DevelopmentFormWizard<TSchema extends ZodTypeAny>(
  props: DevelopmentFormWizardProps<TSchema>,
) {
  return (
    <FormWizard
      {...props}
      steps={[
        {
          heading: {
            head: "Dados do empreendimento",
            subHead: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          element: <DevelopmentDataForm />,
        },
        {
          heading: {
            head: "Dados dos módulos",
            subHead: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          element: <DevelopmentModuleDataForm />,
        },
        {
          heading: {
            head: "Conf. de Identidade Visual",
            subHead: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          element: <DevelopmentStyleForm />,
        },
        {
          heading: {
            head: "Arquivos dos documentos",
            subHead: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          element: <DevelopmentDocumentsInfoForm />,
        },
      ]}
    />
  );
}
