import { CloseCircle } from "iconsax-react";
import { useContext } from "react";
import { SelectStateContext } from "react-aria-components";

import { Button } from "./button";

export function ClearSelectButton() {
  const state = useContext(SelectStateContext);

  if (!state?.selectedKey) {
    return null;
  }

  return (
    <Button
      slot={null}
      onPress={() => state?.setSelectedKey(null)}
      className="bg-transparent text-gray-9"
    >
      <CloseCircle size={20} variant="Bold" />
    </Button>
  );
}
