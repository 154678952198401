import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsSubsistemaGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseSubsystemsFilters = GetObjsSubsistemaGetData;

export function useSubsystems(initial: UseSubsystemsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseSubsystemsFilters>(initial);

  const query = useQuery({
    queryKey: ["subsystems", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.SubsistemasService.getObjsSubsistemaGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
