import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { Memorial, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationAddManualMemorialProps
  extends Omit<
    UseMutationOptions<Memorial, Error, AddManualMemorialPayload>,
    "mutationFn"
  > {}

export interface AddManualMemorialPayload {
  memorialId: number;
  manualId: number;
}

export function useMutationAddManualMemorial(
  options?: UseMutationAddManualMemorialProps,
) {
  return useMutation({
    mutationFn: async ({ memorialId, manualId }: AddManualMemorialPayload) => {
      const { manualId: _, ...memorial } =
        await apiProvider.services.MemoriaisService.getOneObjMemorialIdGet({
          id: memorialId,
        });
      return await apiProvider.services.MemoriaisService.createObjMemorialPost({
        requestBody: { manualId, ...memorial },
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["memorials"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao adicionar memorial no manual: ${e.message}`,
      }),
    ...options,
  });
}
