import { parseDate } from "@internationalized/date";
import { Add } from "iconsax-react";
import { Cell } from "react-aria-components";

import { WorkerCreateModal } from "@/modules/companies/components";
import {
  ActiveFlagCell,
  Button,
  Can,
  DatePickerInput,
  PaginationButtons,
  SearchInput,
  Table,
  TableRow,
} from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { useCompanyWorkers } from "@/shared/hooks/api";
import { cpfMask, nullishStringMask, phoneMask } from "@/shared/utils/masks";

export interface CompanyDetailUsersProps {
  companyId: string;
}

export function CompanyDetailUsers({ companyId }: CompanyDetailUsersProps) {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useCompanyWorkers({
      page: 1,
      construtoraId: +companyId,
      text: "",
    });
  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <div>
      <div className="my-7 flex justify-between">
        <p className="text-lg font-bold text-black-2">
          Usuários administrativos da construtora
        </p>
        <Can I="create.worker">
          <Button className="gap-4" styleType="default" onPress={turnOn}>
            <Add />
            Adicionar usuário
          </Button>
          <WorkerCreateModal
            isOpen={isOn}
            onOpenChange={setOpen}
            companyId={+companyId}
          />
        </Can>
      </div>
      <div className="mb-4 flex gap-3">
        <SearchInput className="w-4/6" onDebounceChange={handleSearchChange} />
        <DatePickerInput
          className="w-2/6"
          onChange={(data) => updateFilters({ data })}
          onReset={() => updateFilters({ data: undefined })}
          value={filters.data ? parseDate(filters.data) : null}
        />
      </div>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
        <div className="overflow-y-auto">
          <Table
            aria-label="Construtoras"
            headers={["Nome", "E-mail", "CPF", "Telefone", "Status"]}
            rowsData={data?.content}
            isLoading={isLoading}
            emptyProps={{
              resource: "funcionário",
              search: filters.text ?? undefined,
            }}
            renderRow={(data) => (
              <TableRow key={data.id} href={`colaborador/${data.id}/`}>
                <Cell>{data.nome}</Cell>
                <Cell>{data.email}</Cell>
                <Cell>{nullishStringMask(cpfMask(data.cpf ?? undefined))}</Cell>
                <Cell>
                  {nullishStringMask(phoneMask(data.telefone ?? undefined))}
                </Cell>
                <ActiveFlagCell active={data.flagAtivo} />
              </TableRow>
            )}
          />
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
