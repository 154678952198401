import { Skeleton } from "@/shared/components";

export function MemorialDetailLoading() {
  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <Skeleton className="h-4 w-48" />
        <Skeleton className="h-4 w-24" />
      </div>
      <Skeleton className="h-4 w-24" />
    </div>
  );
}
