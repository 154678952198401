import { ErrorContainer } from "@/shared/components";
import { ApiError } from "@/shared/services";

export interface FaqDetailErrorProps {
  error: Error | null;
}

export function FaqDetailError({ error }: FaqDetailErrorProps) {
  return (
    <ErrorContainer
      message={
        error instanceof ApiError && error.status === 404
          ? "Faq não encontrado..."
          : undefined
      }
    />
  );
}
