import { FieldValues } from "react-hook-form";

import { apiProvider } from "@/shared/services";

import {
  FormComboBoxApiResourceInput,
  FormComboBoxApiResourceInputProps,
} from ".";

const service = apiProvider.services.MemoriaisService.getObjsMemorialGet;

type ServiceFunction = typeof service;

export type FormComboBoxMemorialInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormComboBoxApiResourceInputProps<
    T,
    TFieldValues,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;

export function FormComboBoxMemorialTypeInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormComboBoxMemorialInputProps<T, TFieldValues>) {
  return (
    <FormComboBoxApiResourceInput
      {...props}
      label="Memorial"
      queryKey={["memorial"]}
      service={async ({ pageParam: page, inputText: text }, extraFilters) =>
        await service({
          page,
          text,
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({ id: e.id, text: e.nome })}
    />
  );
}
