import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormFileInput,
  FormNbrPickerInput,
  FormProps,
  FormRichTextInput,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { megabyte } from "@/shared/utils";

import { PostCreateSchema } from "../schemas";

interface PostCreateFormProps
  extends Omit<
    FormProps<typeof PostCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function PostCreateForm({
  redirectTo,
  ...formProps
}: PostCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={PostCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-4">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormTextInput control={control} label="Título" name="titulo" />
              <FormTextInput
                control={control}
                label="Categoria"
                name="categoria"
              />
              <FormNbrPickerInput
                className="col-span-2"
                control={control}
                name="nbrId"
              />
              <FormRichTextInput
                className="col-span-2"
                control={control}
                label="Conteúdo"
                name="texto"
              />
              <FormFileInput
                control={control}
                label="Capa"
                name="capa"
                acceptedFileTypes={["image/png", "image/jpeg"]}
                maxFileSize={20 * megabyte}
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
