export function generatePageNumbers(
  currentPage: number,
  totalPages: number,
): number[] {
  // Set an interval around the current page to display in the array
  const interval: number = 2;

  // Ensure the current page is within valid bounds
  currentPage = Math.max(1, Math.min(currentPage, totalPages));

  // Calculate the range of pages to be displayed
  let start: number = Math.max(1, currentPage - interval);
  let end: number = Math.min(totalPages, currentPage + interval);

  // Adjust the range if it falls short of the desired interval
  const missingPagesBefore = interval - (currentPage - start);
  const missingPagesAfter = interval - (end - currentPage);

  start = Math.max(1, start - missingPagesAfter);
  end = Math.min(totalPages, end + missingPagesBefore);

  // Generate the array of page numbers
  const pageNumbers: number[] = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index,
  );

  return pageNumbers;
}
