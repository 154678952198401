import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsConstrutorasGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseCompaniesFilters = GetObjsConstrutorasGetData;

export function useCompanies(initial: UseCompaniesFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseCompaniesFilters>(initial);
  const query = useQuery({
    queryKey: ["companies", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ConstrutorasService.getObjsConstrutorasGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
