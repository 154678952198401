import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormProps,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";
import { cpfMask, phoneMask } from "@/shared/utils/masks";

import { CreateClientSchema } from "../schemas";

interface ClientCreateFormProps
  extends Omit<
    FormProps<typeof CreateClientSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function ClientCreateForm({
  redirectTo,
  ...formProps
}: ClientCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card className="shadow-header">
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={CreateClientSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-4">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormTextInput control={control} label="Nome" name="nome" />
              <FormTextInput control={control} label="E-mail" name="email" />
              <FormTextInput
                control={control}
                mask={phoneMask}
                label="Telefone"
                name="telefone"
              />
              <FormTextInput
                control={control}
                mask={cpfMask}
                label="CPF"
                name="cpf"
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
