import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { ClientCreateForm } from "../components";

export function ClientCreate() {
  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Cliente</PageTitle>
      </div>
      <ClientCreateForm redirectTo="../" />
    </AnimatedContainer>
  );
}
