import type { AriaToastRegionProps } from "@react-aria/toast";
import { useToastRegion } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { useRef } from "react";

import { ToastPayload } from ".";
import { Toast } from "./toast";

export interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<ToastPayload>;
}

export function ToastRegion({ state, ...props }: ToastRegionProps) {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div
      {...regionProps}
      ref={ref}
      className="fixed bottom-4 right-4 z-50 flex flex-col gap-2"
    >
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} toast={toast} state={state} />
      ))}
    </div>
  );
}
