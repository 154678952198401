import {
  AnimatedContainer,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMutationSubsystemUpdate, useSubsystem } from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";

export function SubsystemData() {
  const { subsystemId } = useRequiredParams();
  const subsystemQuery = useSubsystem(subsystemId);
  const mutationSubsystemUpdate = useMutationSubsystemUpdate();

  return (
    <AnimatedContainer>
      <Card className="space-y-4 shadow-header">
        <QueryHandler {...subsystemQuery}>
          {(data) => (
            <>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex items-center gap-2">
                    <PageTitle primary>{data?.nome}</PageTitle>
                    <Can I="update.subsystem">
                      <EditLink />
                    </Can>
                  </div>
                  <p className="text-xs text-primary">
                    Memorial:{" "}
                    <span className="font-semibold">
                      {data?.memorial?.nome}
                    </span>
                  </p>
                </div>
                {data && (
                  <Can I="update.subsystem">
                    <SwitchStatusButton
                      className="self-start"
                      isDisabled={mutationSubsystemUpdate.isPending}
                      isSelected={data?.flagAtivo}
                      onChange={(status) =>
                        mutationSubsystemUpdate.mutateAsync({
                          id: +subsystemId,
                          requestBody: { flagAtivo: status },
                        })
                      }
                    />
                  </Can>
                )}
              </div>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Descrição</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                <div
                  className="tiptap text-black-0 [&>p]:min-h-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.descricao ?? "Vazio..."),
                  }}
                />
              </div>
              <p className="mb-3 text-sm text-black-2">Especificação técnica</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6">
                <div
                  className="tiptap text-black-0 [&>p]:min-h-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.especificacoes ?? "Vazio..."),
                  }}
                />
              </div>
            </>
          )}
        </QueryHandler>
      </Card>
    </AnimatedContainer>
  );
}
