import { Handle, RouteObject } from "react-router-dom";

import { systemEditAction } from "../edit/actions";
import { SystemEdit } from "../edit/containers";
import { subsystemCreateAction } from "./actions";
import { SubsystemCreate, SubsystemList, SystemData } from "./containers";
import { SystemLayout } from "./layout";
import { subsystemDetailRouter } from "./modules/subsystem/modules/detail/router";

export const systemDetailRouter: RouteObject = {
  path: "detalhes/:systemId",
  handle: {
    crumb: "Detalhes do sistema",
    tabBaseUrl: "system",
    action: "get.system",
  } as Handle,
  children: [
    {
      element: <SystemLayout />,
      children: [
        {
          index: true,
          element: <SystemData />,
        },
        {
          path: "subsistema",
          handle: {
            crumb: "Lista de subsistemas",
            action: "list.subsystem",
          } as Handle,
          element: <SubsystemList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar sistema",
        action: "update.system",
      } as Handle,
      action: systemEditAction,
      element: <SystemEdit />,
    },
    {
      path: "subsistema",
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar subsistema",
            action: "create.subsystem",
          } as Handle,
          action: subsystemCreateAction,
          element: <SubsystemCreate />,
        },
        subsystemDetailRouter,
      ],
    },
  ],
};
