import { Outlet } from "react-router-dom";

import { useAuthorization } from "@/shared/contexts";

export function PageLayout() {
  const { authorized } = useAuthorization();

  if (authorized ?? true) {
    return <Outlet />;
  }

  return <p>Não autorizado</p>;
}
