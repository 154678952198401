import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function ApartmentDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("apartment");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do apartamento",
      action: "get.apartment",
    },
    {
      id: `${baseUrl}arquivos/`,
      href: `${baseUrl}arquivos/`,
      label: "Arquivos",
      action: "list.document",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do apartamento</PageTitle>
      </div>
      <Tabs
        aria-label="Módulos do apartamento"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
