import i18next from "i18next";
import { ZodTypeAny, z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/pt/zod.json";

i18next.init({
  lng: "pt",
  resources: {
    pt: { zod: translation },
  },
});
z.setErrorMap(zodI18nMap);

export function falsyToNull<I extends ZodTypeAny>(f: I) {
  return z.preprocess((v) => v || null, f);
}

export { z };
