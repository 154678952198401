import { Building4, GallerySlash, Profile } from "iconsax-react";

import { Skeleton } from ".";
import { useFile } from "../hooks/api";

export type S3ImageClassNameTypes = "spinner" | "img" | "empty";

export type S3ImageClassName =
  | ((type: S3ImageClassNameTypes) => string | undefined)
  | string;

export interface S3ImageProps {
  className?: S3ImageClassName;
  file?: string | null;
  emptyIcon?: "profile" | "default" | "apartment";
}

const computedClassName = (
  type: S3ImageClassNameTypes,
  className?: S3ImageClassName,
) => {
  return typeof className === "string" ? className : className?.(type);
};

const icons = {
  default: GallerySlash,
  profile: Profile,
  apartment: Building4,
} as const;

export function S3Image({ file, className, emptyIcon }: S3ImageProps) {
  const logoQuery = useFile(file);

  if (logoQuery.isFetching) {
    return <Skeleton className={computedClassName("spinner", className)} />;
  }

  if (logoQuery.data?.url) {
    return (
      <img
        className={computedClassName("img", className)}
        src={logoQuery.data.url}
      />
    );
  }

  const Icon = icons[emptyIcon ?? "default"];
  return (
    <Icon className={computedClassName("empty", className)} variant="Bold" />
  );
}
