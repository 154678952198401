import { FieldValues } from "react-hook-form";

import {
  FormComboBoxInput,
  FormComboBoxInputProps,
  InputItem,
} from "@/shared/components";
import { useConstructionStandards } from "@/shared/hooks/api";

export function FormDevelopmentCategoryInput<
  T extends object,
  TFieldValues extends FieldValues,
>({ isDisabled, ...props }: FormComboBoxInputProps<T, TFieldValues>) {
  const { data, isLoading, isError } = useConstructionStandards();

  return (
    <FormComboBoxInput
      {...props}
      isDisabled={isDisabled || isLoading || isError}
    >
      {data?.map((category) => (
        <InputItem key={category.id} id={category.id}>
          {category.nome}
        </InputItem>
      ))}
    </FormComboBoxInput>
  );
}
