import { Handle, Navigate, RouteObject } from "react-router-dom";

import { passwordUpdateAction, profileUpdateAction } from "./actions";
import { ProfilePassword, ProfileUserData } from "./containers";
import { ProfileLayout } from "./layout";

export const profileRouter: RouteObject = {
  path: "perfil",
  handle: {
    tabBaseUrl: "profile",
  } as Handle,
  element: <ProfileLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="dados-do-usuario/" />,
    },
    {
      path: "dados-do-usuario/",
      action: profileUpdateAction,
      handle: {
        action: "get.profile",
      } as Handle,
      element: <ProfileUserData />,
    },
    {
      path: "senha/",
      action: passwordUpdateAction,
      handle: {
        action: "update.profile",
      } as Handle,
      element: <ProfilePassword />,
    },
  ],
};
