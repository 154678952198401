import isPostalCode from "validator/lib/isPostalCode";
import isTaxID from "validator/lib/isTaxID";

import { getDigits, z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const DevelopmentDataSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  cnpj: z
    .string()
    .transform(getDigits)
    .refine((v) => isTaxID(v, "pt-BR"), "CNPJ inválido")
    .nullish(),
  email: z.string().email().nullish(),
  padraoConstrutivoId: z.number().int(),
  dataEntrega: z.date().nullish(),
  cep: z
    .string()
    .refine((v) => !v || isPostalCode(v, "BR"), "Cep inválido")
    .transform(getDigits)
    .nullish(),
  estado: z.string().nullish(),
  cidade: z.string().trim().nullish(),
  bairro: z.string().trim().nullish(),
  rua: z.string().trim().nullish(),
  numero: z.string().trim().nullish(),
  complemento: z.string().trim().nullish(),
});

export const DevelopmentModuleDataSchema = z.object({
  module: z
    .array(z.enum(["manual", "technical-support", "preventive-management"]))
    .min(1),
});

export const DevelopmentStyleSchema = z.object({
  corPrincipal: z.string().length(7).startsWith("#").nullish(),
  corSecundaria: z.string().length(7).startsWith("#").nullish(),
  corDaFonte: z.string().length(7).startsWith("#").nullish(),
  corRodapePrincipal: z.string().length(7).startsWith("#").nullish(),
  corRodapeSecundaria: z.string().length(7).startsWith("#").nullish(),
  corDaFonteRodape: z.string().length(7).startsWith("#").nullish(),
  foto: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
  capa: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
});

export const DevelopmentDocumentsInfoSchema = z.object({
  contrato: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .optional(),
  artCrea: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .optional(),
});

export const DevelopmentCreateSchema = z.object({
  ...DevelopmentDataSchema.shape,
  ...DevelopmentModuleDataSchema.shape,
  ...DevelopmentStyleSchema.shape,
  ...DevelopmentDocumentsInfoSchema.shape,
  construtoraId: z.number().int().nullish(),
});

const DevelopmentDataStepSchema = generateStepSchema(DevelopmentDataSchema, 0);
const DevelopmentModuleDataStepSchema = generateStepSchema(
  DevelopmentModuleDataSchema,
  1,
);
const DevelopmentStyleStepSchema = generateStepSchema(
  DevelopmentStyleSchema,
  2,
);
const DevelopmentDocumentsInfoStepSchema = generateStepSchema(
  DevelopmentDocumentsInfoSchema,
  3,
);

export const DevelopmentCreateFormSchema = generateMultiStepSchema([
  DevelopmentDataStepSchema,
  DevelopmentModuleDataStepSchema,
  DevelopmentStyleStepSchema,
  DevelopmentDocumentsInfoStepSchema,
]).and(
  z.object({
    construtoraId: z.number().int().nullish(),
  }),
);

export type DevelopmentData = z.infer<typeof DevelopmentDataSchema>;
export type DevelopmentModuleData = z.infer<typeof DevelopmentModuleDataSchema>;
export type DevelopmentStyle = z.infer<typeof DevelopmentStyleSchema>;
export type DevelopmentDocumentsInfo = z.infer<
  typeof DevelopmentDocumentsInfoSchema
>;
export type DevelopmentCreate = z.infer<typeof DevelopmentCreateSchema>;
