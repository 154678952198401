import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from "react-aria-components";
import { type VariantProps } from "tailwind-variants";

import { cn } from "@/shared/utils";

import { button } from ".";
import { Spinner } from "..";

export interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof button> {
  children: React.ReactNode;
  showSpinner?: boolean;
}

export function Button({
  children,
  className,
  showSpinner,
  isDisabled,
  ...buttonProps
}: ButtonProps) {
  return (
    <AriaButton
      className={cn(button(buttonProps), className)}
      isDisabled={showSpinner || isDisabled}
      {...buttonProps}
    >
      {showSpinner ? <Spinner /> : children}
    </AriaButton>
  );
}
