import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useSystem } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { SystemCreateForm } from "../../create/components";

export function SystemEdit() {
  const { systemId } = useRequiredParams();

  const systemQuery = useSystem(systemId);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar sistema</PageTitle>
      </div>
      <QueryHandler
        {...systemQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Sistema não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <SystemCreateForm
            redirectTo="../"
            useFormProps={{
              defaultValues: data,
            }}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
