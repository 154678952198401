import { UseQueryResult } from "@tanstack/react-query";

export type QueryHandlerProps<TData = unknown, TError = Error> = UseQueryResult<
  TData,
  TError
> & {
  renderLoading?: () => React.ReactElement;
  renderError?: (error: TError) => React.ReactElement;
  renderEmpty?: () => React.ReactElement;
  children?: (data?: TData) => React.ReactNode | undefined;
};

export function QueryHandler<TData = unknown, TError = Error>({
  renderLoading,
  renderError,
  renderEmpty,
  children,
  ...query
}: QueryHandlerProps<TData, TError>) {
  if (query.isLoading && renderLoading !== undefined) {
    return renderLoading();
  }

  if (query.isError && renderError) {
    return renderError(query.error);
  }

  if (query.data === undefined && renderEmpty) {
    return renderEmpty();
  }

  return children?.(query.data);
}
