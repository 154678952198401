import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
} from "react-hook-form";

import { ColorInput, ColorInputProps } from "..";

interface FormColorInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends ColorInputProps {
  control: Control<TFieldValues>;
  name: TName;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
}

export function FormColorInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  isDisabled,
  defaultValue,
  ...props
}: FormColorInputProps<TFieldValues, TName>) {
  const {
    field: { disabled, value, onBlur, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    disabled: isDisabled,
    defaultValue: defaultValue,
  });

  return (
    <ColorInput
      name={name}
      value={value}
      isDisabled={disabled}
      onBlur={onBlur}
      onChange={(color) => onChange(color?.toString("hex"))}
      isInvalid={invalid}
      errorMessage={error?.message}
      {...props}
    />
  );
}
