import { Link } from "react-aria-components";

import { Can, Card } from "@/shared/components";
import { Post, PostWithAutor } from "@/shared/services";
import { sanitize } from "@/shared/utils";

interface PostCardProps extends Pick<Post, "id" | "titulo" | "nbr" | "texto"> {
  autor: Pick<PostWithAutor["autor"], "nome">;
}

export function PostCard({ id, titulo, nbr, autor, texto }: PostCardProps) {
  return (
    <Can I="get.post">
      {(allowed) => (
        <Card className="space-y-2 p-4 shadow-header">
          <Link href={allowed ? `detalhes/${id}/` : undefined}>
            <p className="text-lg font-bold text-black-2">
              {titulo} · {nbr?.nome}
            </p>
            <p className="text-info underline">{autor.nome}</p>
            <p
              className="line-clamp-3 overflow-hidden text-ellipsis text-xs text-gray-10"
              dangerouslySetInnerHTML={{
                __html: sanitize(texto ?? "", true),
              }}
            />
          </Link>
        </Card>
      )}
    </Can>
  );
}
