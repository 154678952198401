import {
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useNbr } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { NbrCreateForm } from "../../create/components";

export function RecordNbrEdit() {
  const { nbrId } = useRequiredParams();

  const nbrQuery = useNbr(nbrId);

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar NBR</PageTitle>
      </div>
      <QueryHandler
        {...nbrQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Nbr não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <NbrCreateForm
            redirectTo="../"
            useFormProps={{
              defaultValues: { nome: data?.nome, descricao: data?.descricao },
            }}
          />
        )}
      </QueryHandler>
    </div>
  );
}
