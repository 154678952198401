import { matchPath, useLocation } from "react-router-dom";

export function useCurrentPath(url: string) {
  const location = useLocation();
  const match = matchPath(url, location.pathname);

  return {
    isCurrentPath: !!match,
  };
}
