import { SearchNormal1 } from "iconsax-react";
import { useEffect } from "react";
import { Input, Label, SearchField } from "react-aria-components";
import { useDebounceValue } from "usehooks-ts";

import { cn } from "../utils";

export interface SearchInputProps
  extends React.ComponentProps<typeof SearchField> {
  onDebounceChange?: (search: string) => void;
}

export function SearchInput({
  className,
  onDebounceChange,
  ...props
}: SearchInputProps) {
  const [debouncedSearch, setSearch] = useDebounceValue("", 500);

  useEffect(() => {
    onDebounceChange?.(debouncedSearch);
  }, [debouncedSearch, onDebounceChange]);

  return (
    <SearchField className={cn("relative", className)} {...props}>
      <Label hidden={true}>Search</Label>
      <SearchNormal1 className="absolute left-4 top-1/2 my-auto -translate-y-1/2 text-gray-6" />
      <Input
        className="h-full w-full rounded-lg border border-white-3 p-3 pl-12 text-sm"
        placeholder="Buscar"
        onChange={(e) => setSearch(e.target.value)}
      />
    </SearchField>
  );
}
