import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { z } from "@/shared/utils";

import { AddMaintenanceModal } from ".";
import { MaintenanceCommonSchema } from "../schemas";

const MaintenanceContainerSchema = z.object({
  manutencoes: z.array(MaintenanceCommonSchema).default([]),
});

type MaintenanceContainer = z.infer<typeof MaintenanceContainerSchema>;

interface FormMaintenanceListProps<T extends MaintenanceContainer> {
  className?: string;
  control: Control<T>;
}

export function FormMaintenanceList<T extends MaintenanceContainer>({
  control,
  ...props
}: FormMaintenanceListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<MaintenanceContainer>;

  return (
    <FormListInput
      {...props}
      control={_control}
      name="manutencoes"
      onAddPress={turnOn}
      resourceOptions={{
        word: "manutenç",
        pluralRadical: "ões",
        singularRadical: "ão",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          <AddMaintenanceModal
            isOpen={isOn}
            onOpenChange={setOpen}
            onAdd={(value) => {
              update([...values, value]);
              turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
