import {
  Link as AriaLink,
  type LinkProps as AriaLinkProps,
} from "react-aria-components";
import { type VariantProps } from "tailwind-variants";

import { cn } from "@/shared/utils";

import { button } from ".";

interface LinkProps extends AriaLinkProps, VariantProps<typeof button> {
  children: React.ReactNode;
}

export function Link({ children, className, ...buttonProps }: LinkProps) {
  return (
    <AriaLink className={cn(button(buttonProps), className)} {...buttonProps}>
      {children}
    </AriaLink>
  );
}
