import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function useDevelopment(developmentId?: string) {
  return useQuery({
    queryKey: ["development", developmentId],
    queryFn: async () => {
      if (!developmentId) {
        return;
      }
      return apiProvider.services.EmpreendimentosService.getOneObjEmpreendimentosIdGet(
        { id: +developmentId },
      );
    },
    retry: false,
  });
}
