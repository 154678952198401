import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormProps,
  FormRichTextInput,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import { TopicCreateSchema } from "../schemas";

interface TopicCreateFormProps
  extends Omit<FormProps<typeof TopicCreateSchema>, "schema" | "children"> {
  redirectTo?: string;
  manualId: number;
}

export function TopicCreateForm({
  redirectTo,
  manualId,
  ...props
}: TopicCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={TopicCreateSchema}
        validationErrors={formErrors}
        useFormProps={{ defaultValues: { manualId } }}
        {...props}
      >
        {({ control }) => (
          <>
            <div className="space-y-4">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormTextInput
                control={control}
                label="Posição"
                name="posicao"
                inputProps={{ placeholder: "1.1" }}
              />
              <FormTextInput control={control} label="Título" name="titulo" />
              <FormRichTextInput
                className="col-span-2"
                control={control}
                label="Descrição"
                name="texto"
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
