import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { WarrantyCommon } from "../schemas";

export async function warrantyCreateAction({
  request,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<WarrantyCommon & { redirectTo: string }>(
    request,
    async ({ redirectTo, falhas, ...garantia }) => {
      await apiProvider.services.AcopladosService.postGarantiaCompletaAcopladosGarantiaCompletaPost(
        {
          requestBody: {
            garantia,
            falhas,
          },
        },
      );

      toastQueue.add(
        {
          type: "success",
          message: "Garantia adicionada com sucesso",
        },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
