import { z } from "@/shared/utils";

export const TopicCreateSchema = z.object({
  titulo: z.string().trim().min(1, "Obrigatório"),
  texto: z.string().trim().min(1, "Obrigatório"),
  posicao: z
    .string()
    .trim()
    .min(1, "Obrigatório")
    .regex(/^\d+(\.\d+)*$/, "Posição inválida"),
  manualId: z.number().int(),
});

export type TopicCreate = z.infer<typeof TopicCreateSchema>;
