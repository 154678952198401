import { type LoaderFunctionArgs, redirect } from "react-router-dom";

import { apiProvider } from "@/shared/services";

export function protectedLoader({ request }: LoaderFunctionArgs) {
  if (!apiProvider.isAuthenticated) {
    const params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);

    return redirect("/login?" + params.toString());
  }

  return null;
}
