import { Button, ButtonProps } from "@/shared/components";
import { cn } from "@/shared/utils";

export interface SaveDataButtonProps extends Omit<ButtonProps, "children"> {}

export function SaveDataButton({ className, ...props }: SaveDataButtonProps) {
  return (
    <Button className={cn("px-8 py-3.5 font-semibold", className)} {...props}>
      Salvar dados
    </Button>
  );
}
