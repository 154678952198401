import { Cell } from "react-aria-components";

import {
  Can,
  DetailsCell,
  PaginationButtons,
  PaginationButtonsProps,
  Table,
  TableProps,
  TableRow,
} from "@/shared/components";
import { Contato } from "@/shared/services";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

export interface ContactTableProps
  extends Pick<TableProps<Contato>, "rowsData" | "isLoading"> {
  search?: string;
  pagination: PaginationButtonsProps;
}

export function ContactTable({
  pagination,
  search,
  ...props
}: ContactTableProps) {
  return (
    <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
      <div className="overflow-y-auto">
        <Can I="get.contact">
          {(allowed) => (
            <Table
              {...props}
              aria-label="Lista contatos"
              headers={["Contato", "Telefone", "Site"]}
              emptyProps={{ resource: "contato", search }}
              removeDetailsColumn={!allowed}
              renderRow={({ id, nome, telefone, site }) => (
                <TableRow
                  key={id}
                  href={allowed ? `detalhes/${id}/` : undefined}
                >
                  <Cell>{nome}</Cell>
                  <Cell>
                    {nullishStringMask(phoneMask(telefone ?? undefined))}
                  </Cell>
                  <Cell>{nullishStringMask(site)}</Cell>
                  {allowed && <DetailsCell />}
                </TableRow>
              )}
            />
          )}
        </Can>
      </div>
      <PaginationButtons {...pagination} />
    </div>
  );
}
