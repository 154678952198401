import { FieldValues } from "react-hook-form";

import { UnidadeTempo, apiProvider } from "@/shared/services";

import {
  FormSelectApiResourceInput,
  FormSelectApiResourceInputProps,
} from "..";

export type FormSelectTimeUnitInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> = Omit<
  FormSelectApiResourceInputProps<T, TFieldValues, UnidadeTempo>,
  "service" | "queryKey" | "label" | "mapElements"
>;
export function FormSelectTimeUnitInput<
  T extends object,
  TFieldValues extends FieldValues,
>(props: FormSelectTimeUnitInputProps<T, TFieldValues>) {
  return (
    <FormSelectApiResourceInput
      label="Unidade de tempo"
      queryKey={["timeUnit"]}
      service={apiProvider.services.UnidadeTempoService.getObjsUnidadeTempoGet}
      mapElements={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
