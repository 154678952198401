import { ModalOverlayProps } from "react-aria-components";

import ModalImage from "@/assets/access-denied.svg?react";
import { Dialog, Modal } from "@/shared/components";

export function UnauthorizedModal({ ...props }: ModalOverlayProps) {
  return (
    <Modal isDismissable {...props}>
      <Dialog className="w-screen max-w-[60vw]" title="Acesso negado">
        <div className="flex flex-col items-center">
          <div className="mb-8">
            <ModalImage />
          </div>
          <span className="mb-3 text-xl font-bold text-black-2">
            Acesso negado
          </span>
          <span className="text-center text-sm font-normal text-gray-8">
            Você não tem acesso a essa função, caso precise acessar entre em
            contato com a administração Seu Manual Pro{" "}
            <span className="text-info">contato@seumanualpro.com.br</span>
          </span>
        </div>
      </Dialog>
    </Modal>
  );
}
