import { ArrowRight2, DocumentDownload, Edit2, Trash } from "iconsax-react";
import { Button as AriaButton, DialogTrigger } from "react-aria-components";

import { Can, Card } from "@/shared/components";
import { useMutationDocumentDownload } from "@/shared/hooks/api";
import { Arquivo } from "@/shared/services";

import { DocumentDeleteModal, DocumentIcon } from ".";
import { DocumentEditModal } from "../../edit/components";

interface DocumentCardProps {
  onClick?: (isFile: boolean) => void;
  file: Arquivo;
}

export function DocumentCard({ file, onClick }: DocumentCardProps) {
  const isFile = file.link !== undefined && file.link !== null;

  const documentDownload = useMutationDocumentDownload();

  return (
    <Card
      className="group cursor-pointer p-4 shadow-header"
      onClick={() => {
        onClick?.(isFile);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <DocumentIcon isFile={isFile} />
          <p className="text-sm font-semibold text-black-2">{file.nome}</p>
          {!isFile && (
            <ArrowRight2 className="transition-all group-hover:translate-x-1" />
          )}
        </div>
        <div
          className="flex items-center gap-3"
          onClick={(e) => e.stopPropagation()}
        >
          <Can I="update.document">
            <DialogTrigger>
              <AriaButton>
                <Edit2
                  className="text-primary-light-4 opacity-0 transition-all group-hover:opacity-100"
                  variant="Bold"
                />
              </AriaButton>
              <DocumentEditModal file={file} />
            </DialogTrigger>
          </Can>
          {isFile && (
            <DocumentDownload
              className="text-info-80 opacity-0 transition-all group-hover:opacity-100"
              variant="Bold"
              // cast needed since typescript can't infer that file.link is defined when isFile is true
              onClick={() => documentDownload.mutate(file.link as string)}
            />
          )}
          <Can I="delete.document">
            <DialogTrigger>
              <AriaButton>
                <Trash
                  className="text-error-80 opacity-0 transition-all group-hover:opacity-100"
                  variant="Bold"
                />
              </AriaButton>
              <DocumentDeleteModal
                id={file.id}
                isFile={isFile}
                name={file.nome}
              />
            </DialogTrigger>
          </Can>
        </div>
      </div>
    </Card>
  );
}
