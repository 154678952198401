import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsSistemaGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseSystemsFilters = GetObjsSistemaGetData;

export function useSystems(initial: UseSystemsFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseSystemsFilters>(initial);

  const query = useQuery({
    queryKey: ["systems", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.SistemasService.getObjsSistemaGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
