import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsArquivoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseDocumentsFilters = GetObjsArquivoGetData;

export function useDocuments(initial: UseDocumentsFilters) {
  const [filters, updateFilters] = useObjReducer<UseDocumentsFilters>(initial);

  const query = useQuery({
    queryKey: ["documents", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ArquivoService.getObjsArquivoGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
