import { Breadcrumbs, Skeleton } from "@/shared/components";

export function RecordNbrDetailLoading() {
  return (
    <>
      <div className="space-y-4">
        <Breadcrumbs />
        <Skeleton className="h-9 w-48" />
      </div>
      <div className="space-y-4">
        <Skeleton className="h-60" />
      </div>
    </>
  );
}
