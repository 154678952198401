import { forwardRef } from "react";
import { ListBoxItem, ListBoxItemProps } from "react-aria-components";

import { cn } from "@/shared/utils";

export interface InputItemProps extends ListBoxItemProps {}

function InputItem(
  { className, children, ...props }: InputItemProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <ListBoxItem
      {...props}
      className={cn("hover:bg-black-0/10", className)}
      ref={ref}
    >
      {children}
    </ListBoxItem>
  );
}

const ForwardedRefInputItem = forwardRef(InputItem);

export { ForwardedRefInputItem as InputItem };
