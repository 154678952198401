import { Switch, SwitchProps } from "react-aria-components";

import { cn } from "../utils";

interface SwitchStatusButtonProps extends SwitchProps {
  className?: string;
}

export function SwitchStatusButton({
  className,
  ...props
}: SwitchStatusButtonProps) {
  return (
    <Switch
      className={cn(
        "group relative flex cursor-pointer gap-1 rounded-2xl bg-white-3 text-xs transition delay-200",
        "rac-disabled:brightness-75",
        className,
      )}
      {...props}
    >
      {(p) => (
        <>
          <div
            className={cn(
              "absolute top-1/2 -translate-y-1/2 translate-x-[calc(100%)]",
              "rounded-2xl bg-error px-5 py-2 text-transparent transition-all",
              "group-rac-selected:translate-x-0.5 group-rac-selected:bg-success",
            )}
          >
            {p.isSelected ? "Ativo" : "Inativo"}
          </div>
          <div className="z-10 rounded-2xl border-2 border-transparent px-5 py-2 text-gray-5 transition-all group-rac-selected:text-white-1">
            Ativo
          </div>
          <div className="z-10 rounded-2xl border-2 border-transparent px-5 py-2 text-white-1 transition-all group-rac-selected:text-gray-5">
            Inativo
          </div>
        </>
      )}
    </Switch>
  );
}
