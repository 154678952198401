import { ArrowRight } from "iconsax-react";
import { Link } from "react-aria-components";

import { Can, Card, QueryHandler, S3Image, Spinner } from "@/shared/components";
import { useDevelopment } from "@/shared/hooks/api";
import { Manual } from "@/shared/services";
import { cn } from "@/shared/utils";

export interface ManualCardProps {
  manual: Manual;
  from: "list" | "details";
}

export function ManualCard({ manual, from }: ManualCardProps) {
  const developmentQuery = useDevelopment(manual.empreendimentoId.toString());

  const getManualDetailLink = () => {
    if (from === "list") {
      return `detalhes/${manual.empreendimentoId}/manuais/${manual.id}/`;
    }
    return `manuais/${manual.id}/`;
  };

  return (
    <Card key={manual.id} className="w-64 p-0">
      <Can I="get.manual">
        {(allowed) => (
          <Link
            className="group"
            href={allowed ? getManualDetailLink() : undefined}
          >
            <div className="flex h-28 items-center justify-center rounded-t-lg bg-gray-3">
              <QueryHandler
                {...developmentQuery}
                renderLoading={() => <Spinner className="border-y-gray-8" />}
              >
                {(data) => (
                  <S3Image
                    className={(type) =>
                      cn("rounded-t-lg bg-gray-3", {
                        "h-full w-full object-cover": type === "img",
                        "border-y-gray-8": type === "spinner",
                      })
                    }
                    file={data?.capa}
                    emptyIcon="default"
                  />
                )}
              </QueryHandler>
            </div>
            <div className="flex flex-1 flex-col justify-between gap-5 p-2">
              <div>
                <p className="font-semibold text-black-2">{manual.nome}</p>
                <p className="overflow-hidden text-ellipsis text-xs text-gray-5">
                  {manual.descricao}
                </p>
              </div>
              {allowed && (
                <p className="flex items-center gap-2 text-xs font-bold text-info">
                  Ver mais{" "}
                  <ArrowRight
                    size="1rem"
                    className="transition-all group-hover:translate-x-1"
                  />
                </p>
              )}
            </div>
          </Link>
        )}
      </Can>
    </Card>
  );
}
