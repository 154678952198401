import { cn } from "../utils";

export function FieldSetTitle({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "mb-6 border-b border-b-gray-1 text-sm font-bold text-black-2",
        className,
      )}
    >
      <span className="inline-block border-b-2 border-b-primary px-2 py-1">
        {children}
      </span>
    </div>
  );
}
