import { z } from "@/shared/utils";

import { FaqCreateSchema } from "../../../../../create/schemas";

export const SubsystemFaqCreateSchema = z.object({
  ...FaqCreateSchema.shape,
  subsistemaId: z.number().int(),
});

export type SubsystemFaqCreate = z.infer<typeof SubsystemFaqCreateSchema>;
