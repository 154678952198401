import { Edit } from "iconsax-react";
import { Link } from "react-aria-components";

export function EditLink() {
  return (
    <Link href="editar/">
      <Edit className="text-primary" />
    </Link>
  );
}
