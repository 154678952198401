import { ZodDiscriminatedUnionOption, z } from "zod";

export function generateMultiStepSchema<
  Types extends [
    ZodDiscriminatedUnionOption<"_formStep">,
    ...ZodDiscriminatedUnionOption<"_formStep">[],
  ],
>(schemas: Types) {
  return z.discriminatedUnion("_formStep", schemas);
}
