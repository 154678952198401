import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFaq, useMutationFaqUpdate } from "@/shared/hooks/api";

import {
  SubsystemFaqDetailError,
  SubsystemFaqDetailLoading,
} from "../components";

export function SubsystemFaqDetail() {
  const { faqId } = useRequiredParams();

  const subsystemFaqQuery = useFaq(faqId);
  const mutationFaqUpdate = useMutationFaqUpdate();

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
      </div>
      <QueryHandler
        {...subsystemFaqQuery}
        renderError={(error) => <SubsystemFaqDetailError error={error} />}
        renderLoading={SubsystemFaqDetailLoading}
      >
        {(data) => (
          <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.nome}</PageTitle>
                <Can I="update.faq">
                  <EditLink />
                </Can>
              </div>
              <Can I="update.faq">
                <SwitchStatusButton
                  isDisabled={mutationFaqUpdate.isPending}
                  isSelected={data?.flagAtivo}
                  onChange={(status) =>
                    mutationFaqUpdate.mutateAsync({
                      id: +faqId,
                      requestBody: { flagAtivo: status },
                    })
                  }
                />
              </Can>
            </div>
          </Card>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
