import { useFormContext } from "react-hook-form";

import { Card, FieldSetTitle, FormFileInput } from "@/shared/components";
import { megabyte } from "@/shared/utils";

import { DevelopmentDocumentsInfo } from "../schemas";

export function DevelopmentDocumentsInfoForm() {
  const { control } = useFormContext<DevelopmentDocumentsInfo>();

  return (
    <Card className="p-6">
      <FieldSetTitle>Documentos</FieldSetTitle>
      <div className="flex gap-4">
        <FormFileInput
          control={control}
          label="Contrato"
          name="contrato"
          acceptedFileTypes={["application/pdf"]}
          maxFileSize={100 * megabyte}
        />
        <FormFileInput
          control={control}
          label="ART"
          name="artCrea"
          acceptedFileTypes={["application/pdf"]}
          maxFileSize={100 * megabyte}
        />
      </div>
    </Card>
  );
}
