import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

import { ProductEdit } from "../schema";

export async function productEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<ProductEdit & { redirectTo: string }>(
    request,
    async ({ redirectTo, notaFiscal, ...requestBody }) => {
      const productId = +params.productId!;

      await apiProvider.services.ProdutosService.updateObjProdutoIdPut({
        requestBody: {
          ...requestBody,
          notaFiscal: notaFiscal[0]?.key,
        },
        id: productId,
      });

      toastQueue.add(
        { type: "success", message: "Produto atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
