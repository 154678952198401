import { cn } from "@/shared/utils";

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Spinner({ className, ...props }: SpinnerProps) {
  return (
    <div
      className={cn(
        "aspect-square w-6 animate-spin rounded-full border-4 border-x-transparent border-y-primary-light-4",
        className,
      )}
      {...props}
    />
  );
}
