import { Card, Separator, Skeleton } from "@/shared/components";

export function MaintenanceDetailLoading() {
  return (
    <Card className="shadow-header">
      <div className="space-y-4">
        <Skeleton className="h-4 w-24" />
      </div>
      <Separator />
      <Skeleton className="mb-3 h-5 w-24" />
      <Skeleton className="h-20 w-full rounded-2xl" />
    </Card>
  );
}
