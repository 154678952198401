import { cn } from "@/shared/utils";

export interface FormFooterButtonsProps extends React.PropsWithChildren {}

export function FormFooterButtons({ children }: FormFooterButtonsProps) {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 flex w-full flex-row-reverse border-t-gray-1 px-12 py-3",
        "bg-white-0 shadow-form-footer-buttons animate-in slide-in-from-bottom",
      )}
    >
      {children}
    </div>
  );
}
