import { Eye, EyeSlash } from "iconsax-react";
import { ToggleButton } from "react-aria-components";

import { cn } from "../utils";

interface SecretToggleProps {
  className?: string;
  showSecret?: boolean;
  onToggle?: (show: boolean) => void;
}

export function SecretToggle({
  className,
  showSecret,
  onToggle,
}: SecretToggleProps) {
  return (
    <ToggleButton
      className={cn("text-gray-6", className)}
      isSelected={showSecret}
      onPress={() => onToggle?.(!showSecret)}
    >
      {({ isSelected }) => (
        <>{isSelected ? <Eye variant="Bold" /> : <EyeSlash variant="Bold" />}</>
      )}
    </ToggleButton>
  );
}
