import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios from "axios";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { saveBlob } from "@/shared/utils";

export interface UseMutationDocumentDownloadOptions
  extends Omit<UseMutationOptions<void, Error, string>, "mutationFn"> {
  zipName?: string;
}

export function useMutationDocumentDownload(
  options?: UseMutationDocumentDownloadOptions,
) {
  return useMutation({
    mutationFn: async (filename: string) => {
      const response =
        await apiProvider.services.FilesService.getPresignedDownloadUrlFilesDownloadGet(
          { filename },
        );

      const blob = (await axios.get(response.url, { responseType: "blob" }))
        .data as Blob;

      saveBlob(blob, filename);
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao baixar documento do servidor: ${e.message}`,
      }),
    ...options,
  });
}
