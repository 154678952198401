import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFaq } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { SubsystemFaqCreateForm } from "../../create/components";

export function SubsystemFaqEdit() {
  const { subsystemId, faqId } = useRequiredParams();
  const subsystemFaqQuery = useFaq(faqId);
  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar FAQ</PageTitle>

        <QueryHandler
          {...subsystemFaqQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "FAQ não encontrado..."
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <SubsystemFaqCreateForm
              redirectTo="../"
              useFormProps={{
                defaultValues: {
                  subsistemaId: +subsystemId,
                  nome: data?.nome,
                  descricao: data?.descricao,
                },
              }}
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
