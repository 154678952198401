import {
  Calendar2,
  Category2,
  InfoCircle,
  ShieldTick,
  Tag,
} from "iconsax-react";
import {
  FormProps as AriaFormProps,
  TabList,
  TabPanel,
  Tabs,
} from "react-aria-components";
import { useActionData } from "react-router-dom";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormComboBoxDesignerInput,
  FormComboBoxMemorialTypeInput,
  FormComboBoxSupplierInput,
  FormProps,
  FormRichTextInput,
  FormRootError,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import {
  FieldSetTab,
  FormFaqList,
  FormMaintenanceList,
  FormProductList,
  FormWarrantyList,
} from ".";
import { SubsystemCreateSchema } from "../schemas";

interface SystemCreateFormProps
  extends Omit<FormProps<typeof SubsystemCreateSchema>, "schema" | "children"> {
  redirectTo?: string;
}

export function SubsystemCreateForm({
  redirectTo,
  ...formProps
}: SystemCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={SubsystemCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <input type="hidden" name="redirectTo" value={redirectTo} />
              <FormTextInput
                control={control}
                className="col-span-2"
                label="Nome"
                name="nome"
              />
              <FormComboBoxMemorialTypeInput
                control={control}
                name="memorialId"
                className="col-span-2"
              />
              <FormComboBoxSupplierInput
                name="fornecedorFk"
                control={control}
              />
              <FormComboBoxDesignerInput
                name="projetistaFk"
                control={control}
              />
              <div className="col-span-2">
                <Tabs className="flex">
                  <TabList
                    className="border-r border-gray-1 pr-4"
                    aria-label="atributos de subsistema"
                  >
                    <FieldSetTab
                      id="description"
                      icon={(isSelected) => (
                        <InfoCircle variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="Descrição"
                    />
                    <FieldSetTab
                      id="technical"
                      icon={(isSelected) => (
                        <Category2 variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="Especificações técnicas"
                      data-cy="technical-tab"
                    />
                    <FieldSetTab
                      id="products"
                      icon={(isSelected) => (
                        <Tag variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="Produtos"
                      data-cy="product-tab"
                    />
                    <FieldSetTab
                      id="warranty"
                      icon={(isSelected) => (
                        <ShieldTick variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="Garantias"
                      data-cy="warranty-tab"
                    />
                    <FieldSetTab
                      id="preventive"
                      icon={(isSelected) => (
                        <Calendar2 variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="Manutenções Preventivas"
                      data-cy="maintenance-tab"
                    />
                    <FieldSetTab
                      id="faq"
                      icon={(isSelected) => (
                        <InfoCircle variant={isSelected ? "Bold" : "Linear"} />
                      )}
                      label="FAQ"
                      data-cy="faq-tab"
                    />
                  </TabList>
                  <TabPanel className="flex-1" id="description">
                    <FormRichTextInput
                      className="h-full w-full rounded-none [&>div>div]:rounded-none [&>div]:h-full [&>div]:w-full [&>div]:shadow-none"
                      control={control}
                      name="descricao"
                    />
                  </TabPanel>
                  <TabPanel className="flex-1" id="technical">
                    <FormRichTextInput
                      className="h-full w-full rounded-none [&>div>div]:rounded-none [&>div]:h-full [&>div]:w-full [&>div]:shadow-none"
                      control={control}
                      name="especificacoes"
                    />
                  </TabPanel>
                  <TabPanel className="flex-1" id="products">
                    <FormProductList
                      className="h-full shadow-none [&>div]:rounded-none"
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel className="flex-1" id="warranty">
                    <FormWarrantyList
                      className="h-full shadow-none [&>div]:rounded-none"
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel className="flex-1" id="preventive">
                    <FormMaintenanceList
                      className="h-full shadow-none [&>div]:rounded-none"
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel className="flex-1" id="faq">
                    <FormFaqList
                      className="h-full shadow-none [&>div]:rounded-none"
                      control={control}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
