import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMemorial } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { MemorialCreateForm } from "../../create/components";

export function MemorialEdit() {
  const { memorialId } = useRequiredParams();
  const memorialQuery = useMemorial(memorialId);
  return (
    <AnimatedContainer className="m-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Editar memorial</PageTitle>
      </div>
      <QueryHandler
        {...memorialQuery}
        renderLoading={() => <Spinner className="mx-auto" />}
        renderError={(error) => (
          <ErrorContainer
            message={
              error instanceof ApiError && error.status === 404
                ? "Memorial não encontrado..."
                : undefined
            }
          />
        )}
      >
        {(data) => (
          <MemorialCreateForm
            redirectTo="../"
            useFormProps={{
              defaultValues: {
                nome: data?.nome,
                categoria: data?.categoria,
                manualId: data?.manualId,
              },
            }}
          />
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
