import DOMPurify from "dompurify";

export function sanitize(html: string, onlyText: boolean = false): string {
  return DOMPurify.sanitize(
    html,
    onlyText
      ? {
          ALLOWED_TAGS: ["p", "a"],
        }
      : {},
  );
}
