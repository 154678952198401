import { tv } from "tailwind-variants";

export const fieldBorderStyles = tv({
  base: "border",
  variants: {
    isFocusWithin: {
      false: "border-transparent forced-colors:border-[ButtonBorder]",
      true: "border-primary outline outline-[3px] outline-primary-light-2 forced-colors:border-[Highlight]",
    },
    isInvalid: {
      true: "border-error-80 forced-colors:border-[Mark]",
    },
    isDisabled: {
      true: "border-gray-1 forced-colors:border-[GrayText]",
    },
  },
});

export const fieldGroupStyles = tv({
  base: "group flex items-center overflow-hidden rounded-xl border bg-white-2 px-4 py-3 forced-colors:bg-[Field]",
  variants: fieldBorderStyles.variants,
});
